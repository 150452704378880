import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Avatar, Box, Button, Grid, LinearProgress, Skeleton, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import SimpleSelect from '../components/select/SimpleSelect'
import StudentReportCollapse from '../components/Books/collapse/StudentReportCollapse'
import { classroomsPerformanceRequest } from '../store/books/actions'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { scrollToTop, stringAvatar } from '../utils/functions'
import { ReactComponent as ExportIcon } from '../assets/components/Books/export-icon.svg'
import BookIcon from '../assets/components/Books/book-icon.svg'
import { students } from './data.mockup'

const StudentsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',

  '& .MuiGrid-item:first-of-type > .card': {
    background: theme.palette.primary.main,

    '& .student_description-school > p': {
      color: '#ffffff'
    },

    '& .student_stats-performance, & .student_stats-progress': {
      '& .label, & .value': {
        color: '#ffffff'
      },

      '& .linearProgress_performance, & .linearProgress_progress': {
        background: '#ffffff',
      }
    }
  }
}))

const StudentCard = styled(Box)(({ theme }) => ({
  maxWidth: '350px',
  borderRadius: '16px',
  background: '#fff',
  width: '100%',
  padding: '16px 24px',
  position: 'relative',

  '& .student_position': {
    width: '28px',
    height: '28px',
    background: theme.palette.secondary.main,
    borderRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: '0.2px',
    position: 'absolute',
    left: '-14px',
    top: '2px'
  },

  '& .student_description': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    marginBottom: '8px',

    '&-school': {
      '& p:first-of-type': {
        color: theme.palette.text.primary,
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.2px',
        marginBottom: '4px',
        maxWidth: '242px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },

      '& p:last-of-type': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',
        maxWidth: '242px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    }
  },

  '& .student_stats': {
    '&-performance': {
      marginBottom: '8px'
    },

    '&-performance, &-progress': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& .label, & .value': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%'
      },

      '& .label': {
        maxWidth: '113px',
        width: '100%'
      },

      '& .value': {
        maxWidth: '36px',
        textAlign: 'center',
        width: '100%'
      },

      '& .linearProgress_performance': {
        background: theme.palette.text.secondary,
        width: '100%',

        '.MuiLinearProgress-bar': {
          backgroundColor: theme.palette.secondary.main
        }
      },

      '& .linearProgress_progress': {
        background: theme.palette.text.secondary,
        width: '100%',

        '.MuiLinearProgress-bar': {
          backgroundColor: theme.palette.primary.light
        }
      }
    }
  }
}))

const FiltersContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '56px',
  marginBottom: '40px',

  '& .filter': {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
    flex: '0 1 500px',

    '& label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500
    },

    '& .select > .select__control > .select__value-container > .select__placeholder': {
      color: theme.palette.text.secondary
    }
  }
}))

const Loading = () => {
  return (
    <Box sx={{ padding: '15px' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          justifyContent: 'flex-end'
        }}
      >
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{
            borderRadius: '8px',
            height: '47px',
            width: '206px'
          }}
        />

        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{
            borderRadius: '8px',
            height: '47px',
            width: '128px'
          }}
        />
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          marginBottom: '32px'
        }}
      >
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{ height: '17px', width: '14px' }}
        />

        <Skeleton
          animation='wave'
          variant='text'
          sx={{ fontSize: '27px', width: '360px' }}
        />
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          marginBottom: '16px'
        }}
      >
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{ height: '13px', width: '11px' }}
        />

        <Skeleton
          animation='wave'
          variant='text'
          sx={{ fontSize: '27px', width: '190px' }}
        />
      </Box>

      <Box
        sx={{
          border: `1px dashed #d9d9d9`,
          borderRadius: '16px',
          padding: '32px 22px'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Grid container rowSpacing={2} columnSpacing={2} maxWidth='1240px'>
            {Array(6).fill(1).map((_, index) => {
              return (
                <Grid item xs={6} xl={4} key={index}>
                  <Skeleton
                    animation='wave'
                    variant='rounded'
                    sx={{ borderRadius: '16px', maxWidth: '350px', width: '100%', height: '133px' }}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Box>

      <Box sx={{ marginTop: '40px' }}>
        <FiltersContainer>
          <Box className='filter'>
            <Skeleton
              animation='wave'
              variant='text'
              sx={{ fontSize: '21px', width: '45px' }}
            />

            <Skeleton
              animation='wave'
              variant='rounded'
              sx={{ height: '38px', maxWidth: '440px', width: '100%' }}
            />
          </Box>

          <Box className='filter'>
            <Skeleton
              animation='wave'
              variant='text'
              sx={{ fontSize: '21px', width: '45px' }}
            />

            <Skeleton
              animation='wave'
              variant='rounded'
              sx={{ height: '38px', maxWidth: '217px', width: '100%' }}
            />
          </Box>
        </FiltersContainer>

        <Box>
          <Grid container mb={2}>
            {Array(6).fill(0).map((_, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={index === 0 ? 4 : index > 3 ? 1 : 2}
                >
                  {index !== 5 && (
                    <Skeleton
                      animation='wave'
                      variant='text'
                      sx={{
                        fontSize: '1rem',
                        marginLeft: index === 0 ? '20%' : '0px',
                        textAlign: index !== 0 ? 'center' : 'left',
                        maxWidth: '100px',
                        width: '100%'
                      }}
                    />
                  )}
                </Grid>
              )
            })}
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {Array(6).fill(0).map((_, index) => {
              return (
                <Skeleton
                  key={index}
                  animation='wave'
                  variant='rounded'
                  sx={{
                    borderRadius: '16px',
                    height: '64px',
                    width: '100%'
                  }}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const BooksReportStudentsPerformanceContainer = () => {
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { classrooms_performance: { data, isFetching } } = useAppSelector(state => state.books)

  const handleBackPage = () => {
    navigate(`/books/${id}/report`)
  }

  useEffect(() => {
    scrollToTop()
    id && dispatch(classroomsPerformanceRequest({ id: Number(id) }))
  }, [])

  if (isFetching) {
    return (
      <Loading />
    )
  }

  return (
    <Box sx={{ padding: '15px' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          type='button'
          variant='contained'
          startIcon={<ExportIcon />}
          sx={{
            background: theme.palette.primary.light,
            borderRadius: '8px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '120%',
            padding: '14px 19px',
            textTransform: 'capitalize',
            '&:hover': {
              background: '#ac83fa',
            }
          }}
        >
          Exportar Relatório
        </Button>

        <Button
          type='button'
          variant='contained'
          onClick={handleBackPage}
          sx={{
            borderRadius: '8px',
            color: '#fff',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '120%',
            padding: '14px 40px',
            textTransform: 'capitalize'
          }}
        >
          Voltar
        </Button>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          marginBottom: '32px'
        }}
      >
        <img src={BookIcon} />
        <Typography
          component='h2'
          sx={{
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 600
          }}
        >
          {data?.name} / Turma Fractal
        </Typography>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          marginBottom: '16px'
        }}
      >
        <img src={BookIcon} width='11px' />
        <Typography
          component='h3'
          sx={{
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 600
          }}
        >
          Alunos em destaque
        </Typography>
      </Box>

      <Box
        sx={{
          border: `1px dashed ${theme.palette.text.secondary}`,
          borderRadius: '16px',
          padding: '32px 22px'
        }}
      >
        <StudentsContainer>
          <Grid container rowSpacing={2} columnSpacing={2} maxWidth='1240px'>
            {students.map(student => {
              return (
                <Grid
                  key={student.id}
                  item
                  xs={6}
                  xl={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <StudentCard className='card'>
                    <Box
                      component='span'
                      className='student_position'
                    >
                      {student.position}
                    </Box>

                    <Box className='student_description'>
                      <Avatar
                        alt={student.name}
                        variant='rounded'
                        {...stringAvatar(student?.name, 52, 52, 16)}
                      />

                      <Box className='student_description-school'>
                        <Typography
                          title={student.name}
                        >
                          {student.name}
                        </Typography>
                        <Typography
                          title={`${student.school.grade.name} / ${student.school.name}`}
                        >
                          {student.school.grade.name} / {student.school.name}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className='student_stats'>
                      <Box className='student_stats-performance'>
                        <Typography
                          component='span'
                          className='label'
                        >
                          Desempenho:
                        </Typography>

                        <LinearProgress
                          variant='determinate'
                          value={student.performance}
                          className='linearProgress_performance'
                        />

                        <Typography
                          component='span'
                          className='value'
                        >
                          {student.performance}%
                        </Typography>
                      </Box>

                      <Box className='student_stats-progress'>
                        <Typography
                          component='span'
                          className='label'
                        >
                          Progresso:
                        </Typography>

                        <LinearProgress
                          variant='determinate'
                          value={student.progress}
                          className='linearProgress_progress'
                        />

                        <Typography
                          component='span'
                          className='value'
                        >
                          {student.progress}%
                        </Typography>
                      </Box>
                    </Box>
                  </StudentCard>
                </Grid>
              )
            })}
          </Grid>
        </StudentsContainer>
      </Box>

      <Box sx={{ marginTop: '40px' }}>
        <FiltersContainer>
          <Box className='filter'>
            <Typography component='label'>Aluno:</Typography>
            <SimpleSelect
              className='select'
              placeholder='Pesquise pelo nome aluno...'
              hasBorder
              hasBackground
              maxWidth='440px'
            />
          </Box>

          <Box className='filter'>
            <Typography component='label'>Capítulo:</Typography>
            <SimpleSelect
              className='select'
              placeholder='Selecione o capítulo...'
              hasBorder
              hasBackground
              maxWidth='217px'
            />
          </Box>
        </FiltersContainer>

        <Box>
          <Grid container marginBottom={2}>
            <Grid item xs={4}>
              <Typography sx={{ marginLeft: '20%' }}>Aluno</Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography sx={{ textAlign: 'center' }}>Início</Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography sx={{ textAlign: 'center' }}>Fim</Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography sx={{ textAlign: 'center' }}>Ativ. Finalizadas</Typography>
            </Grid>

            <Grid item xs={1}>
              <Typography sx={{ textAlign: 'center' }}>Tempo</Typography>
            </Grid>

            <Grid item xs={1}></Grid>
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {students.map((student) => {
              return (
                <StudentReportCollapse
                  key={student.id}
                  studentData={student}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BooksReportStudentsPerformanceContainer
