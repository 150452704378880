import React, { useState, useEffect, memo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// Assets
import { ReactComponent as ActivitiesIcon } from '../assets/components/activity/activities-icon.svg'
import { ReactComponent as ActivitiesListIcon } from '../assets/components/activity/activitiesList-icon.svg'
import { ReactComponent as LineViewIcon } from '../assets/components/activity/lineView-icon.svg'
import { ReactComponent as GradeViewIcon } from '../assets/components/activity/gradeView-icon.svg'
import { ReactComponent as OrderByIcon } from '../assets/components/activity/orderby-icon.svg'
import { ReactComponent as InfoIcon } from '../assets/components/activity/info-icon.svg'
import { ReactComponent as ReloadIcon } from '../assets/components/activity/reload-icon.svg'

// Icons
import CloseIcon from '@mui/icons-material/Close'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import QuestionIcon from '../assets/components/activity/icon-title.svg'
import RankingIcon from '../assets/components/activity/ranking-icon.svg'
import CloneIcon from '../assets/components/activity/clone-icon.svg'
import { ReactComponent as DownloadIcon } from '../assets/components/activity/download-icon.svg'
import ViewIcon from '../assets/components/activity/view-icon.svg'
import EditIcon from '../assets/components/activity/edit-icon.svg'
import CalendarIcon from '../assets/components/activity/calendar-icon.svg'
import TypeIcon from '../assets/components/activity/type-icon.svg'
import PeopleIcon from '../assets/components/activity/people-icon.svg'
import TitleIcon from '../assets/components/activity/title-icon.svg'
import ApplicationIcon from '../assets/components/activity/application-icon.svg'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'

// Components
import {
  Box,
  Fade,
  Grid,
  LinearProgress,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material'
import MaterialButton from '@mui/material/Button'
import Label from '../components/label/Label'
import SearchInput from '../components/input/SearchInput'
import SelectInput from '../components/select/SelectInput'
import Table, { Columns } from '../components/table/Table'
import ActivitiesListGrid from '../components/Activities/list/ActivitiesListGrid'
import { DatePickerField } from '../components/input/DatePickerInput'
import Button from '../components/button/Button'
import DialogModal from '../components/Modal/DialogModal'
import ModalPermissions from '../components/Modal/ModalPermissions'

// Redux
import {
  activitiesItemsFetchRequest, activitiesResetFilters, archiveActivityRequest,
  activityDownloadFileRequest
} from '../store/activities/actions'
import { useAppDispatch, useAppSelector } from '../store/hooks'

// Styles
import { styled } from '@mui/material/styles'
import styles from './styles.module.scss'

// Types
import { activitiesStatus, IActivities } from '../models/IActivities'
import { IUser } from '../models/IUser'
import { IRole } from '../models/IRole'

// Utils
import { checkCurrentRole, scrollToTop } from '../utils/functions'
import { canEditActivity } from '../utils/permissions'
import {
  parseISO,
  formatDistance,
  format
} from 'date-fns'


const StatusDivStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  gap: '24px',
  '& span': {
    marginRight: 8,
  },
  [theme.breakpoints.down('md')]: {
    gap: '16px',
    '& span': {
      marginRight: 4,
    }
  }
}))

const HeaderFilters = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  columnGap: '32px',
  gridTemplateAreas: `
    'search create'
    'date status'
  `,
  '& > div': {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 25
  },
  '& .searchActivities': {
    alignItems: 'flex-start',
    gridArea: 'search',
    flexFlow: 'column',
    '& h2': {
      marginBottom: 18
    }
  },
  '& .createActivities': {
    gridArea: 'create',
    justifySelf: 'end',
  },
  '& .dateActivities': {
    gridArea: 'date',
    gap: 8,
    width: '445px',
    justifySelf: 'start',
    '& button': {
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '2px',
      cursor: 'pointer',
      display: 'flex',
      height: '22px',
      justifyContent: 'center',
      padding: 0,
      width: '22px',
      '&:hover': {
        '& svg': {
          '& path': {
            stroke: theme.palette.primary.light
          }
        }
      }
    }
  },
  '& .statusActivities': {
    gridArea: 'status',
    justifySelf: 'end'
  },
  [theme.breakpoints.up(1028)]: {
    gridTemplateAreas: `
      'search create create'
      'search date status'
    `,
    gridTemplateColumns: '1fr 1.5fr 1fr',
    '& > div': {
      marginBottom: 0
    },
    '& .searchActivities': {
      alignSelf: 'self-end'
    },
    '& .createActivities': {
      marginBottom: 40
    },
    '& .dateActivities': {
      justifySelf: 'center'
    }
  }
}))

const NoActivitiesDiv = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  margin: '80px auto',
  maxWidth: 516,
  textAlign: 'center',
  '& p': {
    color: '#9F9F9F',
  }
})

const ActivitiesDiv = styled('div')({
  margin: '32px auto',
})

const OrderByBtn = styled(MaterialButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 8,
  color: theme.palette.text.primary,
  fontSize: 12,
  textTransform: 'none',
  '& svg': {
    marginRight: 8,
    '& path': {
      fill: theme.palette.secondary.main
    }
  },
  [theme.breakpoints.down(906)]: {
    border: 'none',
    minWidth: '35px',
    padding: 0,
    '& svg': {
      marginRight: 0,
      '& path': {
        fill: '#9F9F9F'
      }
    },
  }
}))

const StatusCircle = styled('span')({
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: 12,
  height: 10,
  width: 10,
})

const IconBtn = styled('button')({
  background: 'none',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background 0.3s ease-in-out',
  '& path': {
    fill: '#9F9F9F'
  },
  '&:hover': {
    background: '#0000000a'
  }
})

const MenuStyle = styled(Menu)({
  '& .MuiMenu-list': {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    width: 132,
    '& .MuiMenuItem-root': {
      fontSize: 12,
      '&:not(li:last-of-type)': {
        borderBottom: '1px solid #D9D9D9',
      }
    }
  }
})

const MenuItemStyle = styled(MenuItem)({
  color: '#9F9F9F',
  fontSize: 14,
  width: 180,
  '& img': {
    marginRight: 10
  }
})

const ArchivedModalContainer = styled(Box)(({ theme }) => ({
  '& .label': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '120%',
    marginBottom: '24px',
    textAlign: 'center'
  },

  '& .btns_container': {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',

    '& .btn_close, & .btn_confirm': {
      color: '#ffffff',
      fontWeight: 500,
      width: '107px',
    }
  }
}))

const ActivitiesListContainer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const matches = useMediaQuery('(max-width:1025px)')
  const { activities, persistable: { selectedRole, selectedUnit }, auth: { user } } = useAppSelector(state => state)
  const [activitiesView, setActivitiesView] = useState<string | null>('line')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const isFractalOperator = checkCurrentRole(selectedRole, 'fractal_operator')
  const [anchorOrderByEl, setAnchorOrderByEl] = useState<HTMLElement | null>(null)
  const [anchorMoreEl, setAnchorMoreEl] = useState<HTMLElement | null>(null)
  const [anchorInfoEl, setAnchorInfoEl] = useState<HTMLButtonElement | null>(null)
  const [searchTitle, setSearchTitle] = useState<string>('')
  const [page, setPage] = useState(1)

  const sortActivityById = (a: IActivities, b: IActivities): number => {
    if (typeof a.id !== 'undefined'
      && typeof b.id !== 'undefined'
      && a.start_date !== null
      && b.start_date !== null
      && typeof a.start_date !== 'undefined'
      && typeof b.start_date !== 'undefined') {
      if (a.id > b.id && a?.start_date >= b?.start_date && activities.filters.order === 'desc') {
        return -1
      } else {
        return 1
      }
    }
    return -1
  }

  const items = Array.isArray(activities.items) && activities.items.map(item => item.activities)
  const activitiesData = items ? ([] as IActivities[]).concat(...items).sort(sortActivityById) : []
  const openOrderBy = Boolean(anchorOrderByEl)
  const openMore = Boolean(anchorMoreEl)
  const [permissionModal, setOpenPermissionModal] = useState(false)
  const [openArchiveModal, setOpenArchiveModal] = useState(false)
  const [activityId, setActivityId] = useState<any>(null)
  const dispatch = useAppDispatch()

  // const [openDownloadActivityModal, setOpenDownloadActivityModal] = useState(false)
  // const [activityIdDownload, setActivityIdDownload] = useState<any>()

  const handleGetActivitiesItems = (params: object) => {
    dispatch(activitiesItemsFetchRequest(params))
  }

  const handleRequestFile = (activityId: string | number, activityTitle: string) => {
    dispatch(activityDownloadFileRequest({
      id: activityId,
      title: activityTitle
    }))
  }

  useEffect(() => {
    scrollToTop()
    handleGetActivitiesItems({ order: 'desc', page: location.state?.currentPage ?? 1 })
    setPage(1)

    return () => {
      dispatch(activitiesResetFilters())
    }
  }, [selectedUnit])

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    navigate('/activities', { state: { currentPage: null } })
    handleGetActivitiesItems({ page: value })
  }

  const handleOpenOrderBy = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorOrderByEl(event.currentTarget)
  }

  const handleCloseOrderBy = (event: any) => {
    const value = event.currentTarget.id
    setAnchorOrderByEl(null)
    value !== '' && value !== null && handleGetActivitiesItems({ order: value })
  }

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMoreEl(event.currentTarget)
  }

  const handleCloseMore = () => {
    setAnchorMoreEl(null)
  }

  const handleOpenInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorInfoEl(event.currentTarget)
  }

  const handleCloseInfo = () => {
    setAnchorInfoEl(null)
  }

  const LineViewButton = styled(LineViewIcon)(({ theme }) => ({
    '& path': {
      fill: activitiesView === 'line' ? theme.palette.secondary.main : theme.palette.text.primary
    }
  }))

  const GradeViewButton = styled(GradeViewIcon)(({ theme }) => ({
    '& path': {
      fill: activitiesView === 'grade' ? theme.palette.secondary.main : theme.palette.text.primary
    }
  }))

  const ActionsButtons = styled('div')({
    display: 'flex',
    justifyContent: activitiesView === 'line' ? 'flex-end' : 'space-between',
    '& a img': {
      marginRight: 16
    }
  })

  const handleActivitiesView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null,
  ) => {
    setActivitiesView(newView)
  }

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }

    navigate(newLocation)
  }


  const handleStartChanges = (startValue: any) => {
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)
      handleGetActivitiesItems({
        start_from: startDateFormatted,
        start_until: null,
        page: 1
      })
    } else {
      handleGetActivitiesItems({
        start_from: startDateFormatted,
        page: 1
      })
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    handleGetActivitiesItems({ start_until: endDateFormatted, page: 1 })
    resetLocation()
  }

  const handleClearDates = () => {
    setStartDate(null)
    setEndDate(null)
    setPage(1)
    dispatch(activitiesItemsFetchRequest({ start_from: null, start_until: null, page: 1 }))
    resetLocation()
  }

  const handleSearchByTitle: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSearchTitle(event.target.value)
    setPage(1)
    handleGetActivitiesItems({ title: event.target.value, page: 1 })
    resetLocation()
  }

  const handleSelectStatus: React.ChangeEventHandler<HTMLInputElement> = event => {
    handleGetActivitiesItems({
      statuses: event.target.value === 'geral' ? undefined : event.target.value,
      show_drafts: !(event.target.value === 'waiting'),
      per: 10,
      page: 1
    })
    setPage(1)
    resetLocation()
  }

  const handleArchiveActivity = (activity: IActivities, user: IUser, currentRole: IRole | null) => {
    handleCloseMore()
    const { created_by_user: owner } = activity
    if (
      owner?.id === user.id ||
      currentRole?.name === 'coordinator' ||
      currentRole?.name === 'operator' ||
      currentRole?.name === 'director'
    ) {
      setOpenArchiveModal(true)
      setActivityId(activity?.id)
    } else {
      setOpenPermissionModal(true)
    }
  }

  const archiveActivity = () => {
    dispatch(archiveActivityRequest(activityId))
    setOpenArchiveModal(false)
  }

  const columns: Columns[] = [
    {
      key: 'title',
      name: 'Atividade',
      width: '35%',
      render: (row: IActivities) => {
        return (
          <div id='title'>
            <StatusCircle
              style={{
                backgroundColor: row.status && row.status === 'waiting' && row.draft
                  ? activitiesStatus.drafted?.color
                  : activitiesStatus[`${row.status}`]?.color
              }}
            />
            <img src={TitleIcon} alt='icone do título' width='12' />
            {row.title}
          </div>
        )
      }
    },
    {
      key: 'date',
      name: 'Data de criação',
      width: '15%',
      render: (row: IActivities) => {
        const date = row?.start_date && parseISO(String(row.start_date))
        return (
          <div id='data'>
            {activitiesView === 'grade' && <div>Data</div>}
            <div>{date && format(date, 'dd/MM/yyyy')}</div>
          </div>
        )
      }
    },
    {
      key: 'end_date',
      name: 'Data de encerramento',
      width: '15%',
      render: (row: IActivities) => {
        const date = row?.end_date && parseISO(String(row.end_date))
        return (
          <div id='end_date'>
            <div>{date && format(date, 'dd/MM/yyyy')}</div>
          </div>
        )
      }
    },
    {
      key: 'application',
      name: 'Aplicação',
      width: '10%',
      render: (row: IActivities) => {
        return (
          <div id='application'>
            {activitiesView === 'grade' && <div>Aplicação</div>}
            <div>
              {row?.exam_type === 'virtual'
                ? 'Virtual'
                : row?.exam_type === 'presential'
                  ? 'Presencial'
                  : ''
              }
            </div>
          </div>
        )
      }
    },
    {
      key: 'type',
      name: 'Tipo',
      width: '10%',
      render: (row: IActivities) => {
        return (
          <div id='type'>
            {activitiesView === 'grade' && <div>Tipo</div>}
            <div>
              {row?.activity_type === 'exam'
                ? 'Prova'
                : row?.activity_type === 'commom'
                  ? 'Exercício'
                  : ''
              }
            </div>
          </div>
        )
      }
    },
    {
      key: 'user',
      name: 'Professor',
      width: '20%',
      render: (row: IActivities) => {
        return (
          <div
            id='user'
          >
            {activitiesView === 'grade' && <div>Professor</div>}
            <div style={{ width: '170px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {row.created_by_user?.name}
            </div>
          </div>
        )
      }
    },
    {
      key: 'actions',
      name: '',
      width: '10%',
      align: 'right',
      render: (row: any) => {
        return (
          <div id='actions'>
            <IconBtn
              id={`moreActionsButton[${row.id}]`}
              aria-controls={openMore ? `moreActionsmenu[${row.id}]` : undefined}
              aria-haspopup='true'
              aria-expanded={openMore ? 'true' : undefined}
              onClick={handleOpenMore}
            >
              <MoreVertRoundedIcon />
            </IconBtn>
            <Menu
              id={`moreActionsmenu[${row.id}]`}
              keepMounted
              anchorEl={anchorMoreEl}
              open={anchorMoreEl?.id === `moreActionsButton[${row.id}]`}
              onClose={handleCloseMore}
              TransitionComponent={Fade}
              sx={{
                '& .MuiPaper-root': {
                  border: '1px solid #D9D9D9',
                  borderRadius: '8px',
                  width: '164px'
                }
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconBtn onClick={handleCloseMore}>
                  <CloseIcon />
                </IconBtn>
              </Box>

              {row.end_date !== null && row.status === 'closed' && !isFractalOperator && (
                <MenuItemStyle
                  onClick={() => navigate(`/activities/${row.id}/ranking`, { state: { title: row?.title, type: row?.activity_type } })}
                >
                  <img src={RankingIcon} alt='ícone do ranking' width='18' /> Ranking
                </MenuItemStyle>
              )}

              <MenuItemStyle onClick={() => navigate(`/activities/${row.id}/view`)}>
                <img src={ViewIcon} alt='icone de visualizar' width='16' /> Relatório
              </MenuItemStyle>

              <MenuItemStyle onClick={() => navigate(`/activities/${row.id}/view-activity-details`, { state: true })}>
                <img src={QuestionIcon} alt='icone de visualizar' width='13' /> Questões
              </MenuItemStyle>

              <MenuItemStyle onClick={() => navigate(`/activities/new`, { state: row.id })}>
                <img src={CloneIcon} alt='ícone de clonar' width='12' /> Clonar
              </MenuItemStyle>

              <MenuItemStyle className={styles.menuDownloadIcon} onClick={() => handleRequestFile(row.id, row.title)}>
                <DownloadIcon /> Download
              </MenuItemStyle>

              {canEditActivity(row, user, selectedRole) && (
                <MenuItemStyle onClick={() => navigate(`/activities/${row.id}/edit`)}>
                  <img src={EditIcon} alt='ícone de editar' width='16' /> Editar
                </MenuItemStyle>
              )}

              {row.status !== 'archived' && (
                <MenuItemStyle onClick={() => handleArchiveActivity(row, user, selectedRole)}>
                  <ArchiveOutlinedIcon fontSize='small' sx={{ color: '#9F9F9FCC', marginRight: '10px' }} /> Arquivar
                </MenuItemStyle>
              )}
            </Menu>
          </div>
        )
      }
    },
    {
      key: 'moreActions',
      name: '',
      width: '20%',
      align: 'right',
      render: (row: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '8px' }}>
            <IconBtn
              id={`infoActionsButton[${row.id}]`}
              aria-describedby={`infoPopover[${row.id}]`}
              aria-haspopup='true'
              onClick={handleOpenInfo}
            >
              <InfoIcon />
            </IconBtn>
            <IconBtn
              id={`moreActionsButton[${row.id}]`}
              aria-controls={openMore ? `moreActionsmenu[${row.id}]` : undefined}
              aria-haspopup='true'
              aria-expanded={openMore ? 'true' : undefined}
              onClick={handleOpenMore}
            >
              <MoreVertRoundedIcon />
            </IconBtn>
            <ActionsButtons id='moreActions'>
              <Popover
                id={`infoPopover[${row.id}]`}
                open={anchorInfoEl?.getAttribute('aria-describedby') === `infoPopover[${row.id}]`}
                anchorEl={anchorInfoEl}
                onClose={handleCloseInfo}
                sx={{
                  '& .MuiPopover-paper': {
                    border: '1px solid #D9D9D9',
                    borderRadius: '8px',
                    width: 244
                  }
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className={styles.taskInfoBox}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconBtn onClick={handleCloseInfo}>
                      <CloseIcon />
                    </IconBtn>
                  </div>
                  <div id='data' className={styles.data}>
                    <div>Data de criação</div>
                    <div>
                      <img src={CalendarIcon} alt='icone do calendário' width='14' />
                      {row.start_date && format(parseISO(String(row.start_date)), 'dd/MM/yyyy')}
                    </div>
                  </div>
                  <div id='end_data' className={styles.data}>
                    <div>Data de encerramento</div>
                    <div>
                      <img src={CalendarIcon} alt='icone do calendário' width='14' />
                      {row.end_date && format(parseISO(String(row.end_date)), 'dd/MM/yyyy')}
                    </div>
                  </div>
                  <div id='application' className={styles.application}>
                    <div>Aplicação</div>
                    <div>
                      <img src={ApplicationIcon} alt='ícone do tipo de aplicação' width='14' />
                      {row?.exam_type === 'virtual'
                        ? 'Virtual'
                        : row?.exam_type === 'presential'
                          ? 'Presencial'
                          : ''
                      }
                    </div>
                  </div>
                  <div id='user' className={styles.user}>
                    <div>Professor</div>
                    <div>
                      <img src={PeopleIcon} alt='icone do usuário' width='14' />
                      <span style={{ display: 'block', width: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row.created_by_user?.name}
                      </span>
                    </div>
                  </div>
                  <div id='type' className={styles.type}>
                    <div>Tipo de atividade</div>
                    <div>
                      <img src={TypeIcon} alt='icone de tipo' width='17' />
                      {row?.activity_type === 'exam'
                        ? 'Prova'
                        : row?.activity_type === 'commom'
                          ? 'Exercício'
                          : ''
                      }
                    </div>
                  </div>
                </div>
              </Popover>
              <Menu
                id={`moreActionsmenu[${row.id}]`}
                keepMounted
                anchorEl={anchorMoreEl}
                open={anchorMoreEl?.id === `moreActionsButton[${row.id}]`}
                onClose={handleCloseMore}
                TransitionComponent={Fade}
                sx={{
                  '& .MuiPaper-root': {
                    border: '1px solid #D9D9D9',
                    borderRadius: '8px',
                    width: '164px'
                  }
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconBtn onClick={handleCloseMore}>
                    <CloseIcon />
                  </IconBtn>
                </Box>
                {row.end_date !== null && row.status === 'closed' && !isFractalOperator && (
                  <MenuItemStyle
                    onClick={() => navigate(`/activities/${row.id}/ranking`, { state: { title: row?.title, type: row?.activity_type } })}
                  >
                    <img src={RankingIcon} alt='ícone do ranking' width='18' /> Ranking
                  </MenuItemStyle>
                )}

                <MenuItemStyle onClick={() => navigate(`/activities/${row.id}/view`)}>
                  <img src={ViewIcon} alt='icone de visualizar' width='16' /> Relatório
                </MenuItemStyle>

                <MenuItemStyle onClick={() => navigate(`/activities/new`, { state: row.id })}>
                  <img src={CloneIcon} alt='ícone de clonar' width='12' /> Clonar
                </MenuItemStyle>

                <MenuItemStyle className={styles.menuDownloadIcon} onClick={() => handleRequestFile(row.id, row.title)}>
                  <DownloadIcon /> Download
                </MenuItemStyle>

                {canEditActivity(row, user, selectedRole) && (
                  <MenuItemStyle onClick={() => navigate('/')}>
                    <img src={EditIcon} alt='ícone de editar' width='16' /> Editar
                  </MenuItemStyle>
                )}

                {row.status !== 'archived' && (
                  <>
                    <MenuItemStyle onClick={() => handleArchiveActivity(row, user, selectedRole)}>
                      <ArchiveOutlinedIcon fontSize='small' sx={{ color: '#9F9F9FCC', marginRight: '10px' }} /> Arquivar
                    </MenuItemStyle>

                  </>
                )}
              </Menu>
            </ActionsButtons>
          </div>
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = matches && activitiesView === 'line'
      ? ['title', 'moreActions']
      : activitiesView === 'grade'
        ? ['title', 'date', 'application', 'user', 'type', 'actions']
        : ['title', 'date', 'end_date', 'application', 'user', 'type', 'actions']
    return columns.filter((column: { key: string }) => columnKeys.includes(column.key))
  }

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <DialogModal
        open={openArchiveModal}
        handleClose={() => setOpenArchiveModal(false)}
        width='400px'
      >
        <ArchivedModalContainer>
          <Typography
            component='h3'
            className='label'
          >
            Deseja realmente arquivar essa atividade?
          </Typography>
          <Box className='btns_container'>
            <Button
              type='button'
              className='btn_close'
              color='error'
              onClick={() => setOpenArchiveModal(false)}
            >
              Não
            </Button>
            <Button
              type='button'
              className='btn_confirm'
              color='secondary'
              onClick={archiveActivity}
            >
              Sim
            </Button>
          </Box>
        </ArchivedModalContainer>
      </DialogModal>

      <ModalPermissions
        open={permissionModal}
        closeModal={() => setOpenPermissionModal(false)}
        actionType='archive'
      />

      <Grid item xs={12}>
        <HeaderFilters>
          <div className='searchActivities'>
            <Label>
              <ActivitiesIcon />
              Todas as atividades
            </Label>
            <SearchInput
              name='searchActivities'
              placeholder='Pesquisar pelo título da atividade...'
              width='350px'
              onChange={handleSearchByTitle}
              value={searchTitle}
            />
          </div>
          <div className='createActivities'>
            <Button variant='contained' onClick={() => navigate('/activities/new')}>
              Criar atividade
            </Button>
          </div>
          <div className='dateActivities'>
            <Box sx={{ width: '255px' }}>
              <Typography variant='subtitle2' component='p' sx={{ width: '98px', textAlign: 'right' }}>Iniciam entre:</Typography>
            </Box>
            <DatePickerField
              name='start_from'
              onChange={handleStartChanges}
              startDate={startDate}
              endDate={endDate}
              placeholder='dd/mm/aaaa'
              selectsStart
            />
            <DatePickerField
              name='start_until'
              onChange={handleEndChanges}
              startDate={endDate}
              endDate={endDate}
              minDate={startDate}
              placeholder='dd/mm/aaaa'
            />
            <button type='button' onClick={handleClearDates}>
              <ReloadIcon />
            </button>
          </div>
          <div className='statusActivities'>
            <Typography variant='subtitle2' component='p'>
              Status:
            </Typography>
            <SelectInput
              border='none'
              defaultValue='geral'
              name='selectStatus'
              sx={{ width: '155px', textAlign: 'center' }}
              onChange={handleSelectStatus}
              options={[
                { label: 'Geral', value: 'geral' },
                ...Object.values(activitiesStatus)
              ]}
            />
          </div>
        </HeaderFilters>
      </Grid>
      <Grid item xs={12} sx={{ mt: matches ? 0 : 2 }}>
        <Paper sx={{ padding: matches ? '24px 16px' : '32px 16px 32px 32px', borderRadius: 4 }}>
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Label>Suas atividades</Label>
            <StatusDivStyled>
              {Object.values(activitiesStatus).map(status => (
                <span key={status.color}><StatusCircle style={{ backgroundColor: status.color }} /><span>{status.label}</span></span>
              ))}
            </StatusDivStyled>
            <Box sx={{ display: 'flex', gap: matches ? '8px' : '16px' }}>
              <OrderByBtn
                id='orderBy'
                aria-controls={openOrderBy ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openOrderBy ? 'true' : undefined}
                onClick={handleOpenOrderBy}
                variant='outlined'
              >
                <OrderByIcon />{!matches && ' Ordenar por'}
              </OrderByBtn>
              <MenuStyle
                id='orderByMenu'
                anchorEl={anchorOrderByEl}
                open={openOrderBy}
                onClose={handleCloseOrderBy}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleCloseOrderBy} id='asc'>Crescente</MenuItem>
                <MenuItem onClick={handleCloseOrderBy} id='desc'>Decrescente</MenuItem>
              </MenuStyle>
              <ToggleButtonGroup
                value={activitiesView}
                exclusive
                onChange={handleActivitiesView}
                aria-label='activities view'
              >
                <ToggleButton value='line' aria-label='line'>
                  <LineViewButton />
                </ToggleButton>
                <ToggleButton value='grade' aria-label='grade'>
                  <GradeViewButton />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box>
            <ActivitiesDiv>
              {activities.isFetching && <LinearProgress color='secondary' />}
              {!activities.isFetching && activities.items.length !== 0 && (
                activitiesView === 'line'
                  ? <Table
                    columns={getColumns()}
                    data={activitiesData}
                  />
                  : <ActivitiesListGrid
                    columns={getColumns()}
                    data={activitiesData}
                  />
              )}
              {!activities.isFetching && activities.pagination &&
                activities.pagination.totalOfPages > 1 && (
                  <Pagination
                    count={activities.pagination.totalOfPages}
                    size='small'
                    color='primary'
                    page={location.state?.currentPage ?? page}
                    onChange={handleChangePagination}
                    sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
                  />
                )
              }
              {!activities.isFetching && activities.items.length === 0 && Object.keys(activities.filters).length > 2 && (
                <NoActivitiesDiv>
                  <ActivitiesListIcon />
                  <Label>
                    Nenhuma atividade encontrada.
                  </Label>
                </NoActivitiesDiv>
              )}
              {!activities.isFetching && activities.items?.length === 0 && Object.keys(activities.filters).length === 2 && (
                <NoActivitiesDiv>
                  <ActivitiesListIcon />
                  <Label>
                    Você ainda não possui atividades criadas
                  </Label>
                  <Typography variant='body2' component='p'>
                    Para criar atividade clique no botão do lado direito <strong>&quot;Criar atividade&quot;</strong> no topo da seção
                  </Typography>
                </NoActivitiesDiv>
              )}
            </ActivitiesDiv>
          </Box>

        </Paper>
      </Grid>
    </Grid>
  )
}

export default memo(ActivitiesListContainer)
