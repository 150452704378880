// React
import React, { useState } from 'react'

// Components
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import ModuleStudentCollapse from './ModuleStudentCollapse'

// Utils
import { styled } from '@mui/material/styles'
import { IModule } from '../../../containers/data.mockup'

// Icons
import ChapteIcon from '../../../assets/components/Books/chapter-purple-icon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ChapterContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: '16px',

  '& .chapter': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    gap: '4px',
    padding: '16px',

    '&_title': {
      color: '#9f9f9f',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '150%',

      '& span': {
        color: theme.palette.primary.light,
        fontWeight: 700,
      }
    },

    '&_button': {
      height: '18px',
      marginLeft: 'auto',
      position: 'relative',
      width: '18px',
      zIndex: 99
    }
  },

  '& .modules': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '16px'
  }
}))

interface IChapterStudentCollpaseProps {
  chapterOrder: number
  chapterName: string
  modules: IModule[]
}

const ChapterStudentCollapse: React.FC<IChapterStudentCollpaseProps> = ({
  chapterOrder,
  chapterName,
  modules
}) => {
  const [open, setOpen] = useState(false)

  return (
    <ChapterContainer>
      <Box className='chapter' onClick={() => setOpen(prevState => !prevState)}>
        <img src={ChapteIcon} />
        <Typography className='chapter_title'>
          <span>Capítulo {chapterOrder} - </span> {chapterName}
        </Typography>
        <IconButton className='chapter_button'>
          <KeyboardArrowDownIcon
            fontSize='small'
            sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        </IconButton>
      </Box>

      <Collapse in={open}>
        <Box className='modules'>
          {modules?.map((module) => {
            return (
              <ModuleStudentCollapse
                key={module.id}
                module={module}
              />
            )
          })}
        </Box>
      </Collapse>
    </ChapterContainer>
  )
}

export default ChapterStudentCollapse
