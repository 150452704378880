import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { textExtract } from '../../utils/functions'
import { ReactComponent as MostUsedIcon } from '../../assets/components/Books/most-used-icon.svg'
import { ReactComponent as LeastUsedIcon } from '../../assets/components/Books/least-used-icon.svg'

const Labels = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& .icon': {
    height: '16px',
    width: '16px',
    '& img': {
      height: '100%',
      width: '100%',
    }
  },

  '& span': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '120%',
  }
}))

const PublishedBadge = styled('span')(() => ({
    border: '1px solid #04AEC9',
    borderRadius: '20px',
    color: '#04AEC9',
    padding: '4px 6px'
  }))
  const NewBadge = styled('span')(() => ({
    backgroundColor: '#F69E9E',
    color: '#ffffff !important',
    borderRadius: '20px',
    padding: '4px 6px'
  }))

const InfoBook = styled(Box)(({theme}) => ({
  display: 'flex',
  gap: '4px',

  '& span': {
    fontSize: '14px',
    lineHeight: '120%',
    color: '#9f9f9f',
  },

  '& span:last-of-type': {
    color: theme.palette.text.primary,
  },

  '& span:first-of-type': {
    fontWeight: 500
  },

  '& span.highlight':{
    fontWeight: 700,
    color: theme.palette.primary.light
  },

  '& span.blue-highlight':{
    fontWeight: 700,
    color: theme.palette.secondary.main
  },

}))

interface IBookInformationsProps {
  bookId?: number
  bookCover: string
  bookTitle: string
  bookDescription: string
  bookType?: string
}

const BooksUsed: React.FC<IBookInformationsProps> = ({
  bookId,
  bookCover,
  bookTitle,
  bookType,
  bookDescription,
}) => {

  return (
    <>
      <Box sx={{ display: 'flex', gap: '8px', backgroundColor: '#f9f9f9', padding: '16px', borderRadius: '16px', flexDirection: 'column' }}>
        <Labels sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <PublishedBadge>Publicado</PublishedBadge>
            <NewBadge>Novo</NewBadge>
            {bookType === 'least' ? <LeastUsedIcon /> : <MostUsedIcon />}
            
        </Labels>
        <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
        <Box sx={{ borderRadius: '8px', height: '180px', overflow: 'hidden', width: '114px' }}>
          {bookCover
            ? (
              <img
                src={bookCover}
                alt='Capa do livro'
                width='100%'
                height='100%'
              />
            )
            : (
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  fontSize: '20px',
                  fontWeight: 600,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#f1f1f1',
                  color: '#c9c9c9'
                }}
              >
                <span>Livro</span>
                <span>sem</span>
                <span>capa</span>
              </Box>
            )}
        </Box>

        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              component='h3'
              sx={{
                color: '#666666',
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '120%',
              }}
            >
              {bookTitle}
            </Typography>
          </Box>

          <Box>
            <Box>
              <Typography sx={{
                color: '#9f9f9f',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '150%',
                marginBottom: '16px'
              }}>
                {textExtract(bookDescription, 500)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column'}}>
                <InfoBook>
                    <span>Cód:</span>
                    <span className='highlight'>{bookId}</span>
                </InfoBook>
                <InfoBook>
                    <span>Escola:</span>
                    <span>Bertoni / Atrio</span>
                </InfoBook>
                <InfoBook>
                    <span>NPS:</span>
                    <span>-100</span>
                </InfoBook>
              </Box>
              <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column', alignItems: 'flex-end' }}>
                <InfoBook>
                    <span>Disciplina:</span>
                    <span>Matemática</span>
                </InfoBook>
                <InfoBook>
                    <span>Performance:</span>
                    <span className='blue-highlight'>100%</span>
                </InfoBook>
                <InfoBook>
                    <span>Acessos:</span>
                    <span className='highlight'>1000</span>
                </InfoBook>
              </Box>
          </Box>  
          </Box>          
        </Box>
      </Box>
    </>
  )
}

export default BooksUsed
