import React from 'react'
import BooksReportsStudentsPerformanceContainer from '../containers/BooksReportsStudentsPerformanceContainer'

const BooksReportsStudentPerformancePage = () => {
  return (
    <div>
      <BooksReportsStudentsPerformanceContainer />
    </div>
  )
}

export default BooksReportsStudentPerformancePage
