// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material'
import BackButton from '../components/button/Button'
import RenderWithoutStudent from '../components/Books/render/RenderWithoutStudent'
import RenderStudent from '../components/Books/render/RenderStudent'
import SimpleSelect from '../components/select/SimpleSelect'

// Utils
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { scrollToTop, stringAvatar } from '../utils/functions'
import { generalStudents, IGeneralStudents, IStudentDetails, studentDetails } from './data.mockup'

// Icons
import BookIcon from '../assets/components/Books/book-icon.svg'
import { ReactComponent as ExportIcon } from '../assets/components/Books/export-icon.svg'
import GeneralVisionIcon from '../assets/components/Books/general-vision-icon.svg'

const Header = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  gap: '16px',
  justifyContent: 'space-between',
  marginBottom: '32px',

  '& .title': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '& h2': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
    }
  },

  '& .export_button': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    minWidth: 'auto',
    padding: '14.5px 27.2px',
    textTransform: 'capitalize'
  }
}))

const BoxFilter = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.14px'
  }
}))

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  marginTop: '40px',

  '& .students_container': {
    flex: 1,

    '& .general_vision': {
      alignItems: 'center',
      display: 'flex',
      gap: '16px',

      '& h3': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 600,
        letterSpacing: '0.18px'
      }
    },

    '& .students': {
      marginTop: '32px',

      '&_table-header': {
        marginBottom: '16px',

        '& .cell': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 600,
          letterSpacing: '0.14px'
        }
      },

      '&_table-body': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }
    }
  },

  '& .student_container': {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '16px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'fit-content',
    justifyContent: 'flex-start',
    padding: '32px 16px'
  }
}))

const StudentContainer = styled(Box)<any>(({ theme, selected }) => ({
  background: selected ? theme.palette.primary.main : '#fff',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '8px 16px',

  '& .cell': {
    alignItems: 'center',
    display: 'flex'
  },

  '& .datasheet': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    maxWidth: '340px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&_description': {
      maxWidth: '340px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '& p': {
        lineHeight: '120%',
        maxWidth: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '&:first-of-type': {
          color: selected ? '#fff' : theme.palette.text.primary,
          fontSize: '16px',
          fontWeight: 600,
          marginBottom: '4px'
        },

        '&:last-of-type': {
          color: selected ? '#fff' : '#9f9f9f',
          fontSize: '14px',
          fontWeight: 400
        }
      },
    }
  },

  '& .performance': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '&_linear-progress': {
      backgroundColor: '#e1e1e1',
      width: '54px'
    },

    '&_value': {
      color: selected ? '#fff' : '#9f9f9f',
      fontSize: '14px',
      lineHeight: '120%'
    }
  },

  '& .number_of_books': {
    color: selected ? '#fff' : '#9f9f9f',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '120%',
    textAlign: 'center',
    width: '100%'
  }
}))

const BtnContainer = styled(Box)(({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '32px'
}))

interface IStudentProps {
  student: IGeneralStudents
  handleSelectStudent: (id: number) => void
  selected: boolean
}

const Student: React.FC<IStudentProps> = ({ student, handleSelectStudent, selected }) => {
  return (
    <StudentContainer
      selected={selected}
      onClick={() => handleSelectStudent(student.id)}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={6} className='cell'>
          <Box className='datasheet'>
            <Avatar
              alt={student.name}
              variant='rounded'
              {...stringAvatar(student.name, 40, 40, 14)}
            />

            <Box className='datasheet_description'>
              <Typography>{student.name}</Typography>
              <Typography>{student.grade.name} / {student.school_unit.name}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3} className='cell'>
          <Box className='performance'>
            <LinearProgress
              className='performance_linear-progress'
              color={student.performance >= 50 ? 'secondary' : 'error'}
              value={student.performance}
              variant='determinate'
            />
            <Box
              className='performance_value'
              component='span'
            >
              {student.performance}%
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3} className='cell'>
          <Box className='number_of_books'>
            {student.number_of_books}
          </Box>
        </Grid>
      </Grid>
    </StudentContainer>
  )
}

const BooksReportsStudentsPerformanceContainer = () => {
  const [studentSelected, setStudentSelected] = useState<number | null>(null)
  const [student, setStudent] = useState({} as IStudentDetails)
  const navigate = useNavigate()

  const handleBackPage = () => {
    navigate('/books/reports')
  }

  const handleSelectStudent = (id: number) => {
    setStudentSelected(id)
    const result = studentDetails.find(student => student.id === id)
    result && setStudent(result)
  }

  useEffect(() => {
    studentSelected && scrollToTop()
  }, [studentSelected])

  return (
    <>
      <Box>
        <Header>
          <Box className='title'>
            <img src={BookIcon} />
            <Typography
              component='h2'
            >
              Livros / Desempenho dos alunos
            </Typography>
          </Box>

          <Button
            type='button'
            variant='contained'
            className='export_button'
            startIcon={<ExportIcon />}
          >
            Exportar Relatório
          </Button>
        </Header>

        <Box>
          <Grid container columnSpacing={4}>
            <Grid item xs={4}>
              <BoxFilter>
                <label htmlFor='school'>Escola</label>
                <SimpleSelect
                  className='school'
                  placeholder='Escola...'
                  hasBackground
                  hasBorder
                  maxWidth='100%'
                />
              </BoxFilter>
            </Grid>

            <Grid item xs={4}>
              <BoxFilter>
                <label htmlFor='classroom'>Turma</label>
                <SimpleSelect
                  className='classroom'
                  placeholder='Turma...'
                  hasBackground
                  hasBorder
                  maxWidth='100%'
                />
              </BoxFilter>
            </Grid>

            <Grid item xs={4}>
              <BoxFilter>
                <label htmlFor='student'>Aluno</label>
                <SimpleSelect
                  className='student'
                  placeholder='Aluno...'
                  hasBackground
                  hasBorder
                  maxWidth='100%'
                />
              </BoxFilter>
            </Grid>
          </Grid>
        </Box>

        <Container>
          <Box className='students_container'>
            <Box className='general_vision'>
              <img src={GeneralVisionIcon} />
              <Typography component='h3'>
                Visão Geral
              </Typography>
            </Box>

            <Box className='students'>
              <Grid
                container
                className='students_table-header'
                columnSpacing={3}
              >
                <Grid item xs={6} className='cell'>
                  Aluno
                </Grid>

                <Grid item xs={3} className='cell'>
                  Desempenho
                </Grid>

                <Grid item xs={3} className='cell'>
                  Quant. livros
                </Grid>
              </Grid>

              <Box className='students_table-body'>
                {generalStudents.map((student) => {
                  return (
                    <Student
                      key={student.id}
                      student={student}
                      handleSelectStudent={handleSelectStudent}
                      selected={student.id === studentSelected}
                    />
                  )
                })}
              </Box>
            </Box>
          </Box>

          <Box className='student_container'>
            {!studentSelected && <RenderWithoutStudent />}
            {studentSelected && Object.keys(student).length > 0 && (
              <RenderStudent studentDetail={student} />
            )}
          </Box>
        </Container>
      </Box>

      <BtnContainer>
        <BackButton
          type='button'
          variant='contained'
          onClick={handleBackPage}
        >
          Voltar
        </BackButton>
      </BtnContainer>
    </>
  )
}

export default BooksReportsStudentsPerformanceContainer
