import React, { useEffect } from 'react'
import { Grid, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as CalendarIcon } from '../../../assets/components/ManagersPage/calendar-icon.svg'
import { DataField } from '../title/StyledTitle'
import ParentContainer from '../containers/ParentContainer'
import Label from '../../label/Label'
import { convertDate, cpfFormatter, zipCodeFormatter } from '../../../utils/functions'
import { useDispatch } from 'react-redux'
import { getStudentByIdRequest } from '../../../store/students/actions'
import { useAppSelector } from '../../../store/hooks'

export interface Info {
  id: number
  name: string
  photo_url: any
  email: any
  phone: string
  genre: string
  birthday: string
  classroom_name: string
  first_access: boolean
  registration: string
  grade_name: string
  taxpayer_number: string
  parents: Parent[]
  additional_data: {
    address: {
      street: string,
      zipcode: string,
      complement?: string,
      neighborhood: string,
      number: string
    }
  }
}

export interface Parent {
  id: number
  index: number
  name: string
  email: string
  active: boolean
  password_digest: string
  genre: any
  birthday: any
  photo_url: any
  facebook_token: any
  facebook_uuid: any
  fractal_id: number
  created_at: string
  updated_at: string
  user_user_phrase_id: number
  phrase_answer: string
  access_type: number
  phone: any
  last_access_at: string
  taxpayer_number: any
  first_access: boolean
  ip: any
  provider: any
  additional_data: {
    address: {
      number: string
      street: string
      zipcode: string
      complement: string
      neighborhood: string
    }
  }
  deleted_at: any
  filters: any
}

const StudentInfoTable = ({ data }: any) => {
  const info: Info = data?.singleStudent
  const Genre = info?.genre === 'female' ? 'Feminino' : 'Masculino'
  const FormattedBirthDay = info?.birthday && convertDate(info?.birthday)
  const theme = useTheme()
  const dispatch = useDispatch()
  const { currentItem } = useAppSelector((state) => state.students)

  const studentId: any = info?.id

  useEffect(() => {
    dispatch(getStudentByIdRequest(studentId))
  }, [])

  return (
    <Box width={'100%'}>
      <Typography
        sx={{
          color: theme.palette.text.primary,
          fontSize: '14px',
          fontWeight: 600,
          pb: '16px'
        }}
      >
        Dados pessoais
      </Typography>
      <>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Box>
              <DataField>
                <div>CPF</div>
                <div>{cpfFormatter(info?.taxpayer_number) ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Nome do aluno</div>
                <div>{info?.name ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Matrícula</div>
                <div>{info?.registration ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Primeiro Acesso</div>
                <div>{info?.first_access ? 'Pendente' : 'Concluído'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Fractal ID</div>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  {
                    currentItem.isFetching ?
                      (
                        <Skeleton
                          variant='rectangular'
                          animation='wave'
                          sx={{
                            borderRadius: '4px',
                            width: '20%'
                          }}
                        />
                      ) : (
                        <div>{currentItem.item.user.fractal_id ?? '-'}</div>
                      )
                  }
                </div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Data de nascimento</div>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  <CalendarIcon />
                  <div>{FormattedBirthDay ?? '-'}</div>
                </div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Email</div>
                <div>{info?.email ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Contato</div>
                <div>{info?.phone ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Sexo biológico</div>
                <div>{Genre ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>{currentItem?.item?.classrooms?.length > 1 ? 'Turmas' : 'Turma'}</div>
                {
                  currentItem.isFetching ?
                    (
                      <Skeleton
                        variant='rectangular'
                        animation='wave'
                        sx={{
                          borderRadius: '4px',
                          width: '95%'
                        }}
                      />
                    ) : (
                      <div>
                        {currentItem?.item?.classrooms?.map((item: any) => item?.name).join(', ')}
                      </div>
                    )
                }
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Box>
              <DataField>
                <div>Série</div>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  {info?.grade_name ?? '-'}
                </div>
              </DataField>
            </Box>
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 600,
            pb: '16px',
            pt: '66px',
          }}
        >
          Endereço
        </Typography>
        <Grid container rowSpacing={3}>
          <Grid item xs={3}>
            <Box>
              <DataField>
                <div>CEP</div>
                <div>{zipCodeFormatter(info?.additional_data?.address?.zipcode) ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <DataField>
                <div>Rua</div>
                <div>{info?.additional_data?.address?.street ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Box>
              <DataField>
                <div>Bairro</div>
                <div>
                  {info?.additional_data?.address?.neighborhood ?? '-'}
                </div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <DataField>
                <div>Número</div>
                <div>{info?.additional_data?.address?.number ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <DataField>
                <div>Complemento</div>
                <div>{info?.additional_data?.address?.complement ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
        </Grid>
      </>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          pt: '56px',
          pb: '8px',
        }}
      >
        <Label>Responsável</Label>
        {
          info?.parents?.length === 0 ? (
            <Typography>Não possui responsável</Typography>
          ) : info?.parents?.map((parent: Parent, index: number) => {
            return (
              <Box
                key={parent.id}
                sx={{
                  background: '#F9F9F9',
                  borderRadius: '8px',
                  padding: '25px 28px 30px 28px'
                }}
              >
                <ParentContainer data={parent} index={index} />
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}

export default StudentInfoTable
