import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import { isEmpty } from 'lodash'

import { styled } from '@mui/material/styles'
import { Box, Grid, Paper, useMediaQuery } from '@mui/material'

import Input from '../../input/Input'
import Button from '../../button/Button'
import TextAreaInput from '../../input/TextAreaInput'
import AsyncSelectInput from '../../select/AsyncSelectInput'
import SelectField from '../../select/SelectField'
import { IRole } from '../../../models/IRole'
import { checkCurrentRole } from '../../../utils/functions'
import Label from '../../label/Label'
import QuestionsFilter from '../../Questions/QuestionsFilter'
import QuestionsList from '../../Questions/list/QuestionsList'
import Tabs from '../../tabs/Tabs'
import Tab from '../../tabs/Tab'
import { IQuestion } from '../../../models/IQuestion'
import TabPanel from '../../tabs/TabPanel'
import ContentsFilter from '../../Contents/ContentsFilter'
import ContentsList from '../../Contents/list/ContentsList'
import ModulesFilter from '../../Modules/ModulesFilter'
import ModulesList from '../../Modules/ModulesList'
import ExamsFilter from '../../Exams/ExamsFilter'
import ExamsList from '../../Exams/ExamsList'
import { IUnit } from '../../../models/IUnit'
import { Participants } from '../../../models/IActivities'
import { required, composeValidators, maxLength, minLength } from '../../../utils/formUtils'
import SelectSubjectCombo from '../../select/SelectSubjectCombo'
import { highSchoolSegmentId } from '../../../utils/constants'
import { IContent } from '../../../models/IContent'
import FiltersSelect from '../../select/FiltersSelect'
import SelectInputField from '../../select/SelectInputField'
import SelectItem from '../../select/SelectItem'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { segmentsFetchRequest } from '../../../store/education'

export interface FormValues {
  id?: number | string
  activity_type?: { name: string, value: string }
  exam_type?: { name: string, value: string }
  shuffle_options?: { name: string, value: string }
  number_of_versions?: { name: number, value: number }
  content_module_type?: { id: number, name: string, value: string }
  content_items?: { id: string | number, order: number, value_item: string }[]
  theme_ids?: any
  contents?: { id: number, name: string }
  description?: string
  duration?: number | string
  grades?: { id: number, name: string, value: number }
  knowledgeArea?: { id: number, name: string }[]
  subjects?: { id: number, name: string, knowledge_area?: { id: number, name: string } }[]
  title?: string
  subject_ids?: { id: number | string, name: string }[]
  concourse_ids?: { id: number | string, name: string }
  years_of_application?: Date
  end_date?: Date | null
  start_date?: Date | null
  ids?: any
  education_grade_ids?: number[] | { id: number, name: string } | null
  education_segments_id?: { id: number, value: string, name: string }
  units?: IUnit[]
  value?: string
  questionsValue?: { [key: number]: number }[]
  segment_ids?: any
  questionsFilters?: { [key: string]: any }
  contentsFilters?: { [key: string]: any }
  modulesFilters?: { [key: string]: any }
  examsFilters?: { [key: string]: any }
  customQuestionsFilters?: { [key: string]: any }
  customContentsFilters?: { [key: string]: any }
  filterName?: string
  competence_ids?: any
  participants?: Array<Participants>
}

interface ActivitiesSettingsProps {
  activeStep: number
  setActiveStep: any
  handleSubmit: any
  previous: any
  selectedRole?: IRole | null
  userId?: number | string | null
  values?: FormValues
  initialValues?: FormValues
  isSchoolQuestions?: boolean
  questions?: any
  contents?: any,
  modules?: any,
  exams?: any,
  selectQuestion: (event: React.ChangeEvent<HTMLInputElement>, question: IQuestion) => void
  selectContent: (event: React.ChangeEvent<HTMLInputElement>, content: IContent) => void
  selectAllItems: (type: string) => void
  isAllItemsSelected: (type: string) => boolean | undefined
  isItemSelected: boolean
  invalid: boolean
  selectedQuestions: any
  change?: any
  errors?: any
  filters?: any
  unitId?: number
}

const DivBtns = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: '3rem'
})

interface ForwardButtonProps {
  invalid?: boolean
}

const TabsStyled = styled(Tabs)(({ theme }) => ({
  alignItems: 'flex-end',
  borderBottomColor: theme.palette.text.secondary,
  '& button[aria-selected=false]': {
    color: '#9F9F9F'
  },
  '& button[aria-selected=true]': {
    color: theme.palette.primary.light,
    fontWeight: 700
  }
}))

export const ForwardButton = styled('button')<ForwardButtonProps>(({ theme, invalid }) => ({
  backgroundColor: invalid ? '#0000001f' : theme.palette.primary.main,
  borderRadius: 8,
  border: 'none',
  color: invalid ? '#00000042' : '#FFF',
  cursor: 'pointer',
  fontFamily: theme.typography.fontFamily,
  fontSize: 16,
  fontWeight: 400,
  height: 52,
  padding: '16px 48px',
  '&:hover': {
    backgroundColor: invalid ? 'none' : theme.palette.primary.dark
  }
}))

const ActivitiesSettingsForm = ({
  activeStep,
  setActiveStep,
  isSchoolQuestions,
  handleSubmit,
  previous,
  selectedRole,
  userId,
  values,
  initialValues,
  questions,
  selectContent,
  selectQuestion,
  selectAllItems,
  isAllItemsSelected,
  isItemSelected,
  invalid,
  selectedQuestions,
  contents,
  modules,
  exams,
  errors,
  change,
  filters,
  unitId
}: ActivitiesSettingsProps) => {
  const match = useMediaQuery('(max-width:1241px)')
  const isTeacher: boolean = userId && selectedRole ? checkCurrentRole(selectedRole, 'teacher') : false
  const [repositoryFilters, setRepositoryFilters] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [chosenCustomQuestionFilter, setChosenCustomQuestionFilter] = useState('')
  const [chosenQuestionFilter, setChosenQuestionFilter] = useState('')
  const [chosenCustomContentFilter, setChosenCustomContentFilter] = useState('')
  const [chosenContentFilter, setChosenContentFilter] = useState('')
  const [chosenExamFilter, setChosenExamFilter] = useState('')
  const [chosenModuleFilter, setChosenModuleFilter] = useState('')
  const dispatch = useAppDispatch()
  const { segments: { items } } = useAppSelector(state => state.education)

  useEffect(() => {
    dispatch(segmentsFetchRequest())
  }, [])

  const segmentsFiltered = items?.filter((item) => item.id !== 1)
  const segmentOptions = segmentsFiltered?.map((item) => ({
    id: item.id,
    name: item.name
  }))

  useEffect(() => {
    setPerPage(5)
    setPage(1)
  }, [repositoryFilters])

  useEffect(() => {
    if (values && values.subjects && values.subjects.length === 0) {
      change('knowledgeArea', undefined)
    }
  }, [values?.subjects])

  useEffect(() => {
    change('exam_type', { name: 'Virtual', value: 'virtual' })
  }, [])

  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length === 0 && values && values.education_grade_ids && Object.keys(values?.education_grade_ids).length !== 0) {
      change('education_grade_ids', undefined)
    } else if (initialValues?.education_segments_id?.id !== values?.education_segments_id?.id) {
      change('education_grade_ids', undefined)
    } else {
      change('education_grade_ids', initialValues?.education_grade_ids)
    }

  }, [initialValues, values?.education_segments_id])

  const filtersCustomQuestions = { ...filters?.items?.custom_questions }
  const filtersCustomContents = { ...filters?.items?.custom_contents }
  const filtersQuestions = { ...filters?.items?.questions }
  const filtersContents = { ...filters?.items?.contents }
  const filtersModules = { ...filters?.items?.modules }
  const filtersExams = { ...filters?.items?.exams }

  useEffect(() => {
    if (filtersCustomQuestions && Object.keys(filtersCustomQuestions).length > 0 && chosenCustomQuestionFilter.length > 0) {
      change('customQuestionsFilters', {
        ...filtersCustomQuestions[chosenCustomQuestionFilter],
      })
    } else if (filtersQuestions && Object.keys(filtersQuestions).length > 0 && chosenQuestionFilter.length > 0) {
      change('questionsFilters', {
        ...filtersQuestions[chosenQuestionFilter],
        years_of_application: filtersQuestions[chosenQuestionFilter].years_of_application
          ? new Date(String(filtersQuestions[chosenQuestionFilter].years_of_application))
          : null
      })
    } else if (filtersCustomContents && Object.keys(filtersCustomContents).length > 0 && chosenCustomContentFilter.length > 0) {
      change('customContentsFilters', {
        ...filtersCustomContents[chosenCustomContentFilter],
      })
    } else if (filtersContents && Object.keys(filtersContents).length > 0 && chosenContentFilter.length > 0) {
      change('contentsFilters', {
        ...filtersContents[chosenContentFilter]
      })
    } else if (filtersModules && Object.keys(filtersModules.length > 0) && chosenModuleFilter.length > 0) {
      change('modulesFilters', {
        ...filtersModules[chosenModuleFilter]
      })
    } else if (filtersExams && Object.keys(filtersExams).length > 0 && chosenExamFilter.length > 0) {
      change('examsFilters', {
        ...filtersExams[chosenExamFilter],
        years_of_admission: filtersExams[chosenExamFilter].years_of_admission
          ? new Date(filtersExams[chosenExamFilter].years_of_admission)
          : null
      })
    } else {
      change('customQuestionsFilters', undefined)
      change('questionsFilters', undefined)
      change('customContentsFilters', undefined)
      change('contentsFilters', undefined)
      change('modulesFilters', undefined)
      change('examsFilters', undefined)
    }
  }, [chosenCustomQuestionFilter, chosenQuestionFilter, chosenCustomContentFilter, chosenContentFilter, chosenExamFilter, chosenModuleFilter])

  const handleChangeRepositoryFilters = (event: React.SyntheticEvent, newValue: number) => {
    setRepositoryFilters(newValue)
  }

  const handleNextPage = () => {
    isEmpty(errors) && setActiveStep(2)
  }

  useEffect(() => {
    if (values && values.activity_type && values.activity_type.value === 'exam') {
      change('content_module_type', { id: 1, name: 'O gabarito aparece somente na data de término da prova', value: 'exam' })
    } else if (values && values.activity_type && values.activity_type.value === 'commom') {
      change('content_module_type', { id: 2, name: 'O gabarito aparece durante a execução da prova', value: 'regular' })
    }
  }, [values?.activity_type?.value])

  return (
    <form onSubmit={handleSubmit}>
      <Paper elevation={0} sx={{ borderRadius: '16px', padding: '32px 16px', mt: 2 }}>
        {activeStep === 1 && <Grid container spacing={2}>
          <Grid item sm={12}>
            <Field
              name='title'
              component={Input}
              placeholder='Insira o título da atividade'
              label='Título'
              validate={composeValidators(required, maxLength(105), minLength(5))}
              inputProps={{
                minLength: 5,
                maxLength: 106
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <Field
              name='description'
              component={TextAreaInput}
              minRows={1}
              placeholder='Insira a descrição da atividade...'
              label='Descrição'
              validate={composeValidators(required, maxLength(255))}
              maxCharacters={255}
            />
          </Grid>
          <Grid item sm={6}>
            <Field
              name='education_segments_id'
              component={AsyncSelectInput}
              placeholder='Segmento...'
              label='Segmento'
              options={segmentOptions ?? []}
            />
          </Grid>
          <Grid item sm={6}>
            <Field
              name='education_grade_ids'
              component={SelectInputField}
              placeholder='Selecione...'
              label='Série'
              values={values?.education_segments_id}
              request={{
                path: '/grades',
                params: {
                  segment_ids: values?.education_segments_id?.id
                }
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <SelectSubjectCombo
              name='subjects'
              subjects={values?.subjects}
              isTeacher={isTeacher}
              userId={userId}
              segment={values?.segment_ids}
              highSchoolSegmentId={highSchoolSegmentId}
              validate
              hasBackground
            />
          </Grid>
          <Grid item sm={12}>
            <Field
              name='knowledgeArea'
              component={SelectItem}
              placeholder='Selecione...'
              label='Área de conhecimento'
              isMulti
              hasBorder
              options={values && values.subjects && values.subjects.length > 0
                ? values.subjects.filter((item: any) => item.knowledge_area !== undefined)
                  .map(item => ({ value: item.knowledge_area?.id, label: item.knowledge_area?.name }))
                : []
              }
            />
          </Grid>
          <Grid item sm={6}>
            <Field
              component={FiltersSelect}
              placeholder='Selecione..'
              label='Tipo de prova'
              name='activity_type'
              validate={required}
              hasBorder
              options={[
                { name: 'Exercício', value: 'commom' },
                { name: 'Prova', value: 'exam' }
              ]}
            />
          </Grid>
          <Grid item sm={6}>
            <Field
              name='exam_type'
              label='Aplicação da prova'
              placeholder='Selecione...'
              component={SelectField}
              width='100%'
              hasBorder
              defaultFieldValue={{ name: 'Virtual', value: 'virtual' }}
              options={[
                // { name: 'Presencial', value: 'presential' },
                { name: 'Virtual', value: 'virtual' }
              ]}
            />
          </Grid>
        </Grid>}
        {activeStep === 2 && (
          <Grid container>
            <Grid item sm={12}>
              <Label>Repositório:</Label>
              <Box sx={{ width: '100%', bgcolor: 'background.paper', mb: initialValues?.description ? 1 : 5 }}>
                <TabsStyled
                  value={repositoryFilters}
                  onChange={handleChangeRepositoryFilters}
                >
                  <Tab label='Questões' />
                  <Tab label='Conteúdos' />
                  <Tab label='Módulos' />
                  <Tab label='Prova' />
                  <Tab label='Questões Autorais' />
                  <Tab label='Conteúdos Autorais' />
                </TabsStyled>
              </Box>
            </Grid>
            {repositoryFilters === 0 && <TabPanel value={repositoryFilters} index={0}>
              <Grid item xs={12} container sx={{ paddingInline: match ? '0' : '16px' }} columnSpacing={1}>
                <Grid item xs={12} md={3}>
                  <QuestionsFilter
                    isTeacher={isTeacher}
                    userId={userId}
                    values={values}
                    showAllFilters
                    change={change}
                    filters={filters}
                    unitId={unitId}
                    perPage={perPage}
                    setPage={setPage}
                    chosenFilter={chosenQuestionFilter}
                    setChosenFilter={setChosenQuestionFilter}
                    invalid={invalid}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <QuestionsList
                    questions={questions}
                    selectQuestion={selectQuestion}
                    selectAllItems={selectAllItems}
                    isAllItemsSelected={isAllItemsSelected}
                    selectedQuestions={selectedQuestions}
                    isSelectable
                    perPage={perPage}
                    setPerPage={setPerPage}
                    page={page}
                    setPage={setPage}
                  />
                </Grid>
              </Grid>
            </TabPanel>}
            {
              repositoryFilters === 1 && <TabPanel value={repositoryFilters} index={1}>
                <Grid item xs={12} container sx={{ paddingInline: match ? '0' : '16px' }} columnSpacing={1}>
                  <Grid item xs={12} md={3}>
                    <ContentsFilter
                      isTeacher={isTeacher}
                      userId={userId}
                      values={values}
                      showAllFilters
                      change={change}
                      filters={filters}
                      perPage={perPage}
                      setPage={setPage}
                      chosenFilter={chosenContentFilter}
                      setChosenFilter={setChosenContentFilter}
                      invalid={invalid}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <ContentsList
                      contents={contents}
                      selectQuestion={selectQuestion}
                      selectContent={selectContent}
                      selectAllItems={selectAllItems}
                      isAllItemsSelected={isAllItemsSelected}
                      selectedQuestions={selectedQuestions}
                      isSelectable
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            }
            {
              repositoryFilters === 2 && <TabPanel value={repositoryFilters} index={2}>
                <Grid item xs={12} container sx={{ paddingInline: match ? '0' : '16px' }} columnSpacing={1}>
                  <Grid item xs={12} md={3}>
                    <ModulesFilter
                      isTeacher={isTeacher}
                      userId={userId}
                      values={values}
                      change={change}
                      filters={filters}
                      perPage={perPage}
                      setPage={setPage}
                      showYourFilters
                      chosenFilter={chosenModuleFilter}
                      setChosenFilter={setChosenModuleFilter}
                      invalid={invalid}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <ModulesList
                      modules={modules}
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            }
            {
              repositoryFilters === 3 && <TabPanel value={repositoryFilters} index={3}>
                <Grid item xs={12} container sx={{ paddingInline: match ? '0' : '16px' }} columnSpacing={1}>
                  <Grid item xs={12} md={3}>
                    <ExamsFilter
                      isTeacher={isTeacher}
                      userId={userId}
                      values={values}
                      change={change}
                      filters={filters}
                      perPage={perPage}
                      setPage={setPage}
                      showYourFilters
                      chosenFilter={chosenExamFilter}
                      setChosenFilter={setChosenExamFilter}
                      invalid={invalid}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <ExamsList
                      exams={exams}
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            }
            {repositoryFilters === 4 && <TabPanel value={repositoryFilters} index={4}>
              <Grid item xs={12} container sx={{ paddingInline: match ? '0' : '16px' }} columnSpacing={1}>
                <Grid item xs={12} md={3}>
                  <QuestionsFilter
                    isTeacher={isTeacher}
                    userId={userId}
                    values={values}
                    change={change}
                    filters={filters}
                    unitId={unitId}
                    showYourFilters
                    isSchoolQuestions={isSchoolQuestions || true}
                    isCustomQuestion
                    perPage={perPage}
                    setPage={setPage}
                    chosenFilter={chosenCustomQuestionFilter}
                    setChosenFilter={setChosenCustomQuestionFilter}
                    invalid={invalid}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <QuestionsList
                    questions={questions}
                    // removeQuestion={removeQuestion}
                    selectQuestion={selectQuestion}
                    selectAllItems={selectAllItems}
                    isAllItemsSelected={isAllItemsSelected}
                    isSelectable
                    isToActivity
                    selectedQuestions={selectedQuestions}
                    isSchoolQuestions={isSchoolQuestions || true}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    page={page}
                    setPage={setPage}
                  />
                </Grid>
              </Grid>
            </TabPanel>}
            {
              repositoryFilters === 5 && <TabPanel value={repositoryFilters} index={5}>
                <Grid item xs={12} container sx={{ paddingInline: match ? '0' : '16px' }} columnSpacing={1}>
                  <Grid item xs={12} md={3}>
                    <ContentsFilter
                      isSchoolQuestions={isSchoolQuestions || true}
                      isTeacher={isTeacher}
                      userId={userId}
                      values={values}
                      change={change}
                      showYourFilters
                      isCustomContent
                      filters={filters}
                      perPage={perPage}
                      setPage={setPage}
                      chosenFilter={chosenCustomContentFilter}
                      setChosenFilter={setChosenCustomContentFilter}
                      invalid={invalid}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <ContentsList
                      contents={contents}
                      selectQuestion={selectQuestion}
                      selectContent={selectContent}
                      selectAllItems={selectAllItems}
                      isAllItemsSelected={isAllItemsSelected}
                      selectedQuestions={selectedQuestions}
                      isSchoolQuestions={isSchoolQuestions || true}
                      isSelectable
                      isToActivity
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            }
          </Grid>
        )}
      </Paper>
      <DivBtns>
        <Button
          variant='outlined'
          onClick={
            activeStep === 2 ?
              () => setActiveStep(1) : previous
          }
        >
          Voltar
        </Button>
        {
          activeStep === 1
            ? (
              <ForwardButton
                onClick={handleNextPage}
                invalid={invalid}
                disabled={invalid}
              >
                Avançar
              </ForwardButton>
            )
            : (
              <Button
                type='submit'
                disabled={invalid || !isItemSelected}
              >
                Avançar
              </Button>
            )
        }
      </DivBtns>
    </form>
  )
}

export default ActivitiesSettingsForm
