import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ReactComponent as PencilIcon } from '../../assets/components/HomePage/pencil.svg'
import ChampionIcon from '../../assets/components/HomePage/champion.svg'
import styles from './styles.module.scss'

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,

  [theme.breakpoints.down(1120)]: {
    display: 'none',
  }
}))

const StatusCard = () => {
  const match = useMediaQuery('(max-width:1240px')

  return (
    <Box className={styles.statusCard}>
      <Box sx={{ margin: match ? '32px 20px' : '32px 40px' }}>
        <Box className={styles.statusCardTitle}>
          <PencilIcon className='icon' width={20} />
          <Typography component='h3' className='title'>Você sabia que...</Typography>
        </Box>

        <Box sx={{ marginLeft: match ? '0px' : '30px', maxWidth: '456px' }}>
          <Typography className={styles.text}>
            o Prof. é completo para o acompanhamento e desenvolvimento em tempo real dos seus alunos nas atividades. Além disso, oferece uma gama de recursos avançados para tornar sua experiência de ensino ainda mais eficaz.
          </Typography>
        </Box>
      </Box>

      <ImageContainer>
        <img src={ChampionIcon} width={175} />
      </ImageContainer>
    </Box>
  )
}

export default StatusCard
