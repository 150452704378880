import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Avatar, Box, Button, Checkbox, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { stringAvatar } from '../../utils/functions'
import ChartIcon from '../../assets/components/Books/chart-icon.svg'
import SchoolIcon from '../../assets/components/Books/school-icon.svg'
import { ReactComponent as ViewIcon } from '../../assets/components/Books/view-outlined-icon.svg'

const data = [
  {
    id: 25300,
    name: 'Escola Fractal',
    classrooms: [
      { id: 1000, name: 'Turma Tec.' },
      { id: 1001, name: 'Turma Com.' }
    ],
    more_accurate: [
      {
        id: 1,
        name: 'João Silva',
        performance: 100,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 2,
        name: 'Flávia Oliveira',
        performance: 90,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 3,
        name: 'Mariana Dias',
        performance: 85,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 4,
        name: 'Juan Pablo',
        performance: 82,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 5,
        name: 'Robert Silveira',
        performance: 82,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 6,
        name: 'Linda Portinari',
        performance: 80,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 7,
        name: 'Júnior Cândido',
        performance: 80,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 8,
        name: 'Maya Ribeiro',
        performance: 79,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 9,
        name: 'Paulo Biro',
        performance: 77,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 10,
        name: 'Luís Soares',
        performance: 75,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      }
    ],
    less_accurate: [
      {
        id: 1,
        name: 'Zeca Teixeira',
        performance: 0,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 2,
        name: 'Renan Morelli',
        performance: 5,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 3,
        name: 'Sônia Dias de Oliveira',
        performance: 20,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 4,
        name: 'Jeferson Chianca',
        performance: 25,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 5,
        name: 'Eduarda Aragão',
        performance: 27,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 6,
        name: 'Luigi Figueiredo',
        performance: 29,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 7,
        name: 'Bárbara Soares Aragão',
        performance: 30,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 8,
        name: 'Jonata Costa',
        performance: 32,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      },
      {
        id: 9,
        name: 'Erika Silva',
        performance: 40,
        classroom: { id: 1001, name: 'Turma Com.', grade: '3º Ano B' }
      },
      {
        id: 10,
        name: 'Aline Bauerman',
        performance: 50,
        classroom: { id: 1000, name: 'Turma Tec.', grade: '3º Ano A' }
      }
    ]
  },
  {
    id: 25301,
    name: 'Escola Bertoni',
    classrooms: [
      { id: 1002, name: 'Turma Terceirão A' },
      { id: 1003, name: 'Turma Terceirão B' }
    ],
    more_accurate: [
      {
        id: 980,
        name: 'Flávia Oliveira',
        performance: 90,
        classroom: { id: 1002, name: 'Turma Terceirão A', grade: '3º Ano A' }
      },
      {
        id: 981,
        name: 'Mariana Dias',
        performance: 85,
        classroom: { id: 1003, name: 'Turma Terceirão B', grade: '3º Ano B' }
      }
    ],
    less_accurate: [
      {
        id: 984,
        name: 'Erika Silva',
        performance: 40,
        classroom: { id: 1002, name: 'Turma Terceirão A', grade: '3º Ano A' }
      },
      {
        id: 985,
        name: 'Aline Bauerman',
        performance: 50,
        classroom: { id: 1003, name: 'Turma Terceirão B', grade: '3º Ano B' }
      }
    ]
  }
]

const BoxTitle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& img': {
    width: '12px'
  },

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%'
  }
}))

const BoxFilter = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',

}))

const SelectBox = styled(Select)(({ theme }) => ({
  fontSize: 14,
  width: '200px',
  '.select__control': {
    background: 'inherit',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    '&:hover': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    },

    '.select__value-container': {
      padding: '2px 6px',

      '.select__placeholder': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%'
      }
    }
  },

  '& .select__control--is-focused': {
    border: 'none',
    borderColor: 'transparent'
  },

  '.select__menu': {
    width: '200px',
    zIndex: 88
  }
}))

const ButtonStyled = styled(Button)(() => ({
  color: '#B7B2C8',
  border: '1px solid #B7B2C8',
  borderRadius: '8px',
  fontSize: '16px',
  fontWeight: 500,
  height: '48px',
  lineHeight: '120%',
  minWidth: 'fit-content',
  paddingInline: '24px',
  textTransform: 'none'
}))

const Container = styled(Box)(() => ({
  background: '#f9f9f9',
  borderRadius: '8px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '15px',
  marginBottom: '16px',
  padding: '7px 22px'
}))

const StudentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#f9f9f9',
  borderRadius: '8px',
  display: 'flex',
  gap: '13px',
  padding: '8px 24px',

  '& .student_data': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    '& span:first-of-type': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '120%'
    },

    '& span:last-of-type': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '120%'
    }
  },

  '& .student_performance': {
    color: theme.palette.secondary.main,
    flex: 1,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.14px',
    textAlign: 'right'
  },

  '& .less_accurate': {
    color: theme.palette.error.main
  }
}))

interface IPerformanceStudentsViewProps {
  bookId: number
}

const PerformanceStudentsView: React.FC<IPerformanceStudentsViewProps> = ({ bookId }) => {
  const [schoolSelected, setSchoolSelected] = useState<any>({ value: 'placeholder', label: 'Selecione...' })
  const [classroomsId, setClassroomsId] = useState<any[]>(['all'])
  const [moreAccurate, setMoreAccurate] = useState<any>([])
  const [lessAccurate, setLessAccurate] = useState<any>([])
  const navigate = useNavigate()

  const schools = data.map((item) => ({
    value: item.id,
    label: item.name
  }))

  const school = data.find((item) => item.id === schoolSelected?.value)
  const allIds = school?.classrooms.map(item => item.id)

  const allClassroomsSelected = allIds?.length === classroomsId.length

  useEffect(() => {
    if (allIds && allIds.length > 0) {
      setClassroomsId(['all', ...allIds])
      setMoreAccurate(school?.more_accurate)
      setLessAccurate(school?.less_accurate)
    }
    if (allClassroomsSelected) {
      setClassroomsId(['all', ...classroomsId])
      setMoreAccurate(school?.more_accurate)
      setLessAccurate(school?.less_accurate)
    }
  }, [schoolSelected.value, allClassroomsSelected])

  useEffect(() => {
    schools?.length > 0 && setSchoolSelected(schools[0])
  }, [])

  const handleSelectSchool = (value: any) => {
    setSchoolSelected(value)
  }

  const handleSelectClassroom = (event: React.ChangeEvent<HTMLInputElement>, value: string | number) => {
    const school = data.find((item) => item.id === schoolSelected?.value)
    setClassroomsId((prevState: any) => {
      if (value === 'all') {
        if (event.target.checked) {
          const allIds = school?.classrooms.map(item => item.id)
          const studentsMoreAccurate = school?.more_accurate.filter((item) => {
            return school.classrooms.some((i) => i.id === item.classroom.id)
          })

          const studentsLessAccurate = school?.less_accurate.filter((item) => {
            return school.classrooms.some((i) => i.id === item.classroom.id)
          })

          if (allIds && allIds.length > 0) {
            setMoreAccurate(studentsMoreAccurate)
            setLessAccurate(studentsLessAccurate)
            return [value, ...allIds]
          }
        } else {
          setMoreAccurate([])
          setLessAccurate([])
          return []
        }
      } else if (prevState.includes(value)) {
        setMoreAccurate((prevState: any) => prevState.filter((item: any) => item.classroom.id !== value))
        setLessAccurate((prevState: any) => prevState.filter((item: any) => item.classroom.id !== value))
        return prevState.filter((item: any) => (item !== value && item !== 'all'))
      } else {
        const ids = prevState.filter((item: any) => item !== 'all')
        const studentsMoreAccurate = school?.more_accurate.filter((item) => item.classroom.id === value)
        const studentsLessAccurate = school?.less_accurate.filter((item) => item.classroom.id === value)
        setMoreAccurate(studentsMoreAccurate)
        setLessAccurate(studentsLessAccurate)
        return [...ids, value]
      }
    })
  }

  return (
    <>
      <Box sx={{ alignItems: 'center', display: 'flex', marginBottom: '16px' }}>
        <BoxTitle sx={{ marginRight: '120px' }}>
          <img src={ChartIcon} />
          <Typography className='title'>Desempenho dos alunos</Typography>
        </BoxTitle>

        <BoxFilter>
          <img src={SchoolIcon} />
          <SelectBox
            className='school-select'
            classNamePrefix='select'
            name='school'
            placeholder='Selecione...'
            defaultValue={schools[0]}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Não encontrado'}
            isSearchable={false}
            options={schools}
            value={schoolSelected}
            onChange={handleSelectSchool}
          />
        </BoxFilter>

        <Box sx={{ flex: 1, textAlign: 'right' }}>
          <ButtonStyled
            type='button'
            variant='outlined'
            onClick={() => navigate(`/books/${bookId}/students-performance`)}
            startIcon={<ViewIcon />}
          >
            Visualizar lista completa
          </ButtonStyled>
        </Box>
      </Box>

      <Container>
        <Box className='checkbox_container'>
          <Checkbox
            id='all_classrooms'
            checked={classroomsId.includes('all')}
            onChange={(e) => handleSelectClassroom(e, 'all')}
          />
          <label htmlFor='all_classrooms'>
            Todas as turmas
          </label>
        </Box>
        {data.filter((item) => item.id === schoolSelected?.value)?.map((i) => {
          return i.classrooms.map((x) => {
            return (
              <Box key={x.id} className='checkbox_container'>
                <Checkbox
                  id={String(x.id)}
                  checked={classroomsId.includes(x.id)}
                  onChange={(e) => handleSelectClassroom(e, x.id)}
                />
                <label
                  htmlFor={String(x.id)}
                  title={x.name}
                >
                  {x.name}
                </label>
              </Box>
            )
          })
        })}
      </Container>

      <Grid container>
        <Grid item xs={6}>
          <Typography
            sx={{
              color: '#9f9f9f',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '120%',
              marginBottom: '16px'
            }}
          >
            Alunos com melhor desempenho
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}
          >
            {moreAccurate?.slice(0, 5)?.map((item: any) => {
              return (
                <StudentContainer key={item.id}>
                  <Avatar
                    alt={item.name}
                    variant='rounded'
                    {...stringAvatar(item.name, 40, 40, 14)}
                  />
                  <Box className=' student_data'>
                    <Typography component='span'>{item.name}</Typography>
                    <Typography component='span'>{item.classroom.grade}</Typography>
                  </Box>

                  <Box className='student_performance'>
                    {item.performance}%
                  </Box>
                </StudentContainer>
              )
            })}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{
              color: '#9f9f9f',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '120%',
              marginBottom: '16px'
            }}
          >
            Alunos com menor desempenho
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}
          >
            {lessAccurate?.slice(0, 5)?.map((item: any) => {
              return (
                <StudentContainer key={item.id}>
                  <Avatar
                    alt={item.name}
                    variant='rounded'
                    {...stringAvatar(item.name, 40, 40, 14)}
                  />
                  <Box className=' student_data'>
                    <Typography component='span'>{item.name}</Typography>
                    <Typography component='span'>{item.classroom.grade}</Typography>
                  </Box>

                  <Box className='student_performance less_accurate'>
                    {item.performance}%
                  </Box>
                </StudentContainer>
              )
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default PerformanceStudentsView
