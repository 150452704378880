import React from 'react'

// Assets
import { ReactComponent as CheckIcon } from '../assets/components/HomePage/check-icon.svg'

// Components
import StatusCard from '../components/Home/StatusCard'
// import Chat from '../components/Home/Chat'
import ClassroomsList from '../components/Home/ClassroomsList'
import { Box, Typography } from '@mui/material'
import { Carousel } from '../components/Home/carousel'
import Graphic from '../components/Home/Graphic'
import CalendarWithEvents from '../components/Calendar/CalendarWithEvents'

// Redux
import { useAppSelector } from '../store/hooks'

// Styles
import { styled } from '@mui/material/styles'

const CalendarContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const HomeContainer = () => {
  const { data, isFetching } = useAppSelector(state => state.teachersClassrooms)
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '16px'
        }}
      >
        <Box
          sx={{
            maxWidth: '950px',
            flex: 1.3
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '16px',
              marginBottom: '16px'
            }}
          >
            <CheckIcon />
            <Typography
              sx={{
                color: '#666666',
                fontSize: 18,
                fontWeight: 600
              }}
            >
              Pedagógico
            </Typography>
          </Box>
          <Box>
            <Carousel />
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Graphic />
          </Box>
          <Box>
            <ClassroomsList
              isFetching={isFetching}
              data={data}
            />
          </Box>
          <Box>
            <StatusCard />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 0.7,
            display: 'flex',
            flexDirection: 'column',
            gap: '32px'
          }}
        >
          <CalendarContainer>
            <CalendarWithEvents />
          </CalendarContainer>
          {/* <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center'
            }}>
            <Chat />
          </Box> */}
        </Box>
      </Box>
    </>
  )
}

export default HomeContainer
