
import React from 'react'
import 'react-multi-carousel/lib/styles.css'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'
import { Navigation } from 'swiper'
import { styled } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { ReactComponent as LightIcon } from '../../assets/components/HomePage/light-icon.svg'
import { ReactComponent as PencilIcon } from '../../assets/components/HomePage/pencil-icon.svg'
import { ReactComponent as ReportCardIcon } from '../../assets/components/HomePage/reportcard-icon.svg'
// import { ReactComponent as BookIcon } from '../../assets/components/HomePage/book-icon.svg'
import { ReactComponent as ReviewIcon } from '../../assets/components/HomePage/review-icon.svg'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'


const Card = styled('div')(({ theme }) => ({
  background: '#FFF',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px 0',
  borderRadius: '16px',
  transition: 'background 0.3s ease-in-out',

  '& span': {
    color: '#666666',
    fontSize: 14,
    fontWeight: 500,
  },

  '&:hover': {
    background: theme.palette.primary.light,
    '& span': {
      color: '#FFF'
    },
    '& svg': {
      filter: 'brightness(0) invert(1)',
    }
  },

  [theme.breakpoints.down('lg')]: {
    padding: '27px 0'
  },

  [theme.breakpoints.down('md')]: {
    '& span': {
      fontSize: 12,
      fontWeight: 600,
    }
  }
}))

const BoxStyled = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',

  '& .slider': {
    marginInline: 0,
    maxWidth: 810,

    [theme.breakpoints.down('lg')]: {
      maxWidth: '468px',
    },

    [theme.breakpoints.down(970)]: {
      maxWidth: '377px',
    }
  },

  '& .swiper-slide': {
    // width: '146px !important',
    width: '180px !important',
    marginRight: '16px',

    [theme.breakpoints.down('lg')]: {
      width: '148px !important',
      marginRight: '8px',
    },

    [theme.breakpoints.down(1020)]: {
      width: '122px !important',
      marginRight: '4px',
    }
  },

  '& .main-slider-prev, .main-slider-next': {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#BC9BFA',
    borderRadius: '50%',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    }
  },

  '& .swiper-button-disabled': {
    opacity: 0.4,
  }
}))

export const Carousel = () => {
  const matches = useMediaQuery('(max-width:970px)')

  return (
    <BoxStyled>
      <button className='main-slider-prev'>
        <ArrowBackIosNewRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
      </button>

      <Swiper
        slidesPerView={'auto'}
        className='slider'
        modules={[Navigation]}
        navigation={{
          prevEl: '.main-slider-prev',
          nextEl: '.main-slider-next'
        }}
      >
        <SwiperSlide>
          <Link to='/activities/new'>
            <Card>
              <LightIcon width={matches ? 38 : 65} height={matches ? 30 : 45} />
              <span>Criar Atividade</span>
            </Card>
          </Link>
        </SwiperSlide>

        <SwiperSlide>
          <Link to='/questions/form'>
            <Card>
              <PencilIcon width={matches ? 38 : 65} height={matches ? 30 : 45} />
              <span>Criar Questão</span>
            </Card>
          </Link>
        </SwiperSlide>

        <SwiperSlide>
          <Link to='/contents/form'>
            <Card>
              <ReportCardIcon width={matches ? 38 : 65} height={matches ? 30 : 45} />
              <span>Criar Conteúdo</span>
            </Card>
          </Link>
        </SwiperSlide>

        {/* <SwiperSlide>
          <Link to='/books/form'>
            <Card>
              <BookIcon width={matches ? 38 : 65} height={matches ? 30 : 45} />
              <span>Criar Livros</span>
            </Card>
          </Link>
        </SwiperSlide> */}

        <SwiperSlide>
          <Link to='/activities'>
            <Card>
              <ReviewIcon width={matches ? 38 : 65} height={matches ? 30 : 45} />
              <span>Listar Atividades</span>
            </Card>
          </Link>
        </SwiperSlide>
      </Swiper>

      <button className='main-slider-next'>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
      </button>
    </BoxStyled>
  )
}
