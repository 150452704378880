import React from 'react'
import { Avatar, Box, Grid, Paper, Skeleton, Typography, LinearProgress } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ActivityParticipants, Participants } from '../../models/IActivities'
import { ButtonLightStyled } from '../button/Button'
import PerformanceTable, { Columns } from '../table/PerformanceTable'
import { stringAvatar } from '../../utils/functions'
import { ReactComponent as ViewSVG } from '../../assets/icons/view.svg'
import { ReactComponent as PerformanceSVG } from '../../assets/components/activity/icon-performance.svg'

const paperStyled = {
  borderRadius: 4,
  mt: 4,
  p: 3,
  '& .MuiTable-root': {
    minWidth: '300px'
  },
  '& td': {
    p: 1,
    '&:first-of-type': {
      pl: 2
    },
    '&:last-of-type': {
      pr: 2
    }
  },
  '& span': {
    color: '#04AEC9',
    fontWeight: '500'
  },
  '& .red span': {
    color: '#F69E9E'
  }

}

interface IStudentsPerformanceViewProps {
  participants?: Array<Participants>
  classroomId: number | string | null,
  activityValue?: number | string | null
  overview?: Array<ActivityParticipants>
  activityType?: string
  isFetching?: boolean
}

const BookStudentsPerformanceView: React.FC<IStudentsPerformanceViewProps> = ({
//   participants,
  overview,
  activityValue,
  activityType,
  isFetching
}) => {
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [seachParam, setSearchParams] = useSearchParams()

  const getScoreByUserId = (userId: number) => {
    const userSelected = overview?.find(user => user.user.id === userId)
    const userScore = userSelected?.participant_score
    return userScore
  }

  const getPerformanceById = (userId: number) => {
    const userSelected = overview?.find(user => user.user.id === userId)
    return userSelected?.performance
  }

  const getColumns = (isLower: boolean) => {
    const columns: Columns[] = [
      {
        key: 'avatar',
        name: 'Nome',
        width: '45px',
        render: (row: any) => {
          return <Avatar {...stringAvatar(row.user.name)} variant='rounded' />
        }
      },
      {
        key: 'students',
        name: '',
        render: (row: any) => {
          return <Box display='flex' flexDirection='column' >
            <Typography variant='subtitle1' color='#666' fontWeight={600}>{row.user.name}</Typography>
          </Box>
        }
      },
      {
        key: 'school',
        name: 'Escola(s)',
        render: (row: any) => {
          return <Box display='flex' flexDirection='column' >
            <p style={{ margin: '0' }}>{row.school_classroom.unit.name}</p>
          </Box>
        }
      },
      {
        key: 'scores',
        name: 'Livros',
        width: '150px',
        render: (row: any) => {
          const score = getScoreByUserId(row.user.id)
          const performance = getPerformanceById(row.user.id)
          return activityType === 'exam'
            ? (
              <Box
                alignItems='center'
                display='flex'
                justifyContent='flex-end'
                gap='2px'
              >
                <Typography
                  color={isLower ? 'error' : 'secondary'}
                  fontSize={16}
                  fontWeight={500}
                >
                  {score ? Number(score).toFixed(1) : 0}
                </Typography>
                <span style={{ color: 'currentcolor', fontWeight: 500 }}>/</span>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  {activityValue}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', width: '100%' }}>
                <Typography>{performance?.toFixed(1) ?? '0.0'}</Typography>
              </Box>
            )
        }
      },
      {
        key: 'scores',
        name: 'Desempenho',
        width: '150px',
        render: (row: any) => {
            const score = getScoreByUserId(row.user.id)
            const performance = getPerformanceById(row.user.id)
            return activityType === 'exam'
              ? (
                <Box
                  alignItems='center'
                  display='flex'
                  justifyContent='flex-end'
                  gap='2px'
                >
                  <Typography
                    color={isLower ? 'error' : 'secondary'}
                    fontSize={16}
                    fontWeight={500}
                  >
                    {score ? Number(score).toFixed(1) : 0}
                  </Typography>
                  <span style={{ color: 'currentcolor', fontWeight: 500 }}>/</span>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500
                    }}
                  >
                    {activityValue}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', width: '100%', justifyContent: 'flex-end' }}>
                  <LinearProgress
                    sx={{ width: '100%', backgroundColor: '#e0e6e7' }}
                    color={isLower ? 'error' : 'secondary'}
                    value={performance ?? 0}
                    variant='determinate'
                  />
                  <Typography sx={{ width: '100%' }}>{performance?.toFixed(1) ?? '0.0'} %</Typography>
                </Box>
              )
          }
      }
    ]

    return columns
  }

 const sortedParticipants =  [
    {
        'id': 144630,
        'user': {
            'id': 32040,
            'name': 'Thiago Pereira'
        },
        'school_classroom': {
            'id': 759,
            'name': 'Turma C 3 EM',
            'grade': {
                'id': 23,
                'name': '3º E.M.'
            },
            'unit': {
                'id': 251651,
                'name': 'Bertoni'
            }
        },
        'participant_score': 0
    },
    {
        'id': 144619,
        'user': {
            'id': 32031,
            'name': 'Luiza Gomes'
        },
        'school_classroom': {
            'id': 758,
            'name': 'Turma B 2º EM',
            'grade': {
                'id': 22,
                'name': '2º E.M.'
            },
            'unit': {
                'id': 251651,
                'name': 'Bertoni'
            }
        },
        'participant_score': 100
    },
    {
        'id': 144612,
        'user': {
            'id': 32024,
            'name': 'João Silva'
        },
        'school_classroom': {
            'id': 757,
            'name': 'Turma A 1º EM',
            'grade': {
                'id': 28,
                'name': '1º E.M.'
            },
            'unit': {
                'id': 251651,
                'name': 'Bertoni'
            }
        },
        'participant_score': 95
    },
    {
        'id': 144624,
        'user': {
            'id': 32039,
            'name': 'Fernanda Almeida'
        },
        'school_classroom': {
            'id': 759,
            'name': 'Turma C 3 EM',
            'grade': {
                'id': 23,
                'name': '3º E.M.'
            },
            'unit': {
                'id': 251651,
                'name': 'Bertoni'
            }
        },
        'participant_score': 80
    },
    {
        'id': 144629,
        'user': {
            'id': 32037,
            'name': 'Mariana Ramos'
        },
        'school_classroom': {
            'id': 759,
            'name': 'Turma C 3 EM',
            'grade': {
                'id': 23,
                'name': '3º E.M.'
            },
            'unit': {
                'id': 251651,
                'name': 'Bertoni'
            }
        },
        'participant_score': 80
    }
]



  return (
    <Paper sx={paperStyled}>
      {
        isFetching ? (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Skeleton animation='wave' height={50} width={100} />
              <Skeleton animation='wave' height={50} width={100} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '32px'
              }}
            >
              <Box width={'100%'}>
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />

              </Box>
              <Box width={'100%'}>
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
              </Box>

            </Box>
          </Box>
        ) : (
          <>
            <Box display='flex' justifyContent='space-between' mb={2}>
              <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
                <PerformanceSVG style={{ marginRight: 16 }} />
                Alunos com melhores desempenhos
              </Typography>
              <ButtonLightStyled
                onClick={() => navigate('/books/reports/students-performance')}
                startIcon={<ViewSVG />}
                variant='outlined'>
                Visualizar lista completa
              </ButtonLightStyled>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ width: 520 }}>
                <PerformanceTable
                  columns={getColumns(false)}
                  data={sortedParticipants?.slice(0, 5) || []}
                  withoutHead
                />
              </Grid>
            </Grid>
          </>
        )
      }
    </Paper>
  )
}

export default BookStudentsPerformanceView
