import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { Books, Book, Chapter, BookPublished, ITeacher, IClassroomsPerformance, ICover } from '../../models/IBooks'
import {
  addClassroomsFailure,
  addClassroomsRequest,
  addClassroomsSuccess,
  addCoauthorFailure,
  addCoauthorRequest,
  addCoauthorSuccess,
  booksChangePagination,
  changeChapterOrderFailure,
  changeChapterOrderRequest,
  changeChapterOrderSuccess,
  clearBookPublished,
  clearBooksFilters,
  clearClassrooms,
  clearCurrentBook,
  clearModules,
  cloneBookFailure,
  cloneBookRequest,
  cloneBookSuccess,
  createBookFailure,
  createBookRequest,
  createBookSuccess,
  createChapterFailure,
  createChapterRequest,
  createChapterSuccess,
  deleteBookFailure,
  deleteBookRequest,
  deleteBookSuccess,
  deleteChapterFailure,
  deleteChapterRequest,
  deleteChapterSuccess,
  fetchBookByIdFailure,
  fetchBookByIdRequest,
  fetchBookByIdSuccess,
  fetchBookPublishedFailure,
  fetchBookPublishedRequest,
  fetchBookPublishedSuccess,
  fetchBooksFailure,
  fetchBooksRequest,
  fetchBooksSuccess,
  fetchChaptersFailure,
  fetchChaptersRequest,
  fetchChaptersSuccess,
  fetchClassroomsFailure,
  fetchClassroomsRequest,
  fetchClassroomsSuccess,
  getTeachersWithSubjectsFailure,
  getTeachersWithSubjectsRequest,
  getTeachersWithSubjectsSuccess,
  publishBookFailure,
  publishBookRequest,
  publishBookSuccess,
  setModules,
  changeClassroomsFailure,
  changeClassroomsRequest,
  changeClassroomsSuccess,
  updateBookFailure,
  updateBookRequest,
  updateBookSuccess,
  updateChapterFailure,
  updateChapterRequest,
  updateChapterSuccess,
  classroomsPerformanceRequest,
  classroomsPerformanceSuccess,
  classroomsPerformanceFailure,
  updateBookStatusRequest,
  updateBookStatusSuccess,
  updateBookStatusFailure,
  getCoverBooksRequest,
  getCoverBooksSuccess,
  getCoverBooksFailure
} from './actions'

export interface FiltersType {
  query: {
    per: number
    page: number
  }
}

interface StateType {
  items: Books[]
  currentItem: {
    item: Book
    isFetching: boolean
    isSaving: boolean
    isSuccess: boolean
    isError: boolean
    messageError: string | null
  }
  chapters: {
    items: Chapter[],
    isFetching: boolean,
    isSaving: boolean,
    isChangingOrder: boolean,
    isError: boolean
    messageError: string | null
  },
  modulesSelected: { id: number, name: string, order: number }[]
  isFetching: boolean
  isProcessing: boolean
  isError: boolean
  isSuccess: boolean
  isSaving: boolean
  messageError: string | null
  pagination: {
    per: number
    totalOfPages: number,
    current: number
    total: number
  }
  filters: FiltersType
  covers: {
    items: ICover[],
    isFetching: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null
  }
  classrooms: {
    isFetching: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null
    items: any
  },
  bookToPublish: {
    isFetching: boolean,
    isProcessing: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null,
    book: BookPublished
  },
  bookCloned: {
    id: number | null,
    isFetching: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null
  },
  teachers: {
    items: ITeacher[],
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
  },
  classrooms_performance: {
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
    data: IClassroomsPerformance | null
  }
}

const initialState: StateType = {
  items: [],
  currentItem: {
    item: {} as Book,
    isFetching: false,
    isSaving: false,
    isSuccess: false,
    isError: false,
    messageError: null
  },
  chapters: {
    items: [] as Chapter[],
    isFetching: false,
    isSaving: false,
    isChangingOrder: false,
    isError: false,
    messageError: null
  },
  modulesSelected: [],
  isFetching: false,
  isProcessing: false,
  isError: false,
  isSuccess: false,
  isSaving: false,
  messageError: null,
  pagination: {
    per: 5,
    current: 1,
    totalOfPages: 0,
    total: 0
  },
  covers: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    messageError: null
  },
  filters: {
    query: {
      per: 5,
      page: 1
    }
  },
  classrooms: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    messageError: null,
    items: {}
  },
  bookToPublish: {
    isFetching: false,
    isProcessing: false,
    isSuccess: false,
    isError: false,
    messageError: null,
    book: {} as BookPublished
  },
  bookCloned: {
    id: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    messageError: null
  },
  teachers: {
    items: [],
    isFetching: false,
    isError: false,
    messageError: null
  },
  classrooms_performance: {
    isFetching: false,
    isError: false,
    messageError: null,
    data: null
  }
}

const booksReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchBooksRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: true,
      isError: false,
      isSuccess: false,
      messageError: null,
      filters: {
        ...state.filters,
        query: {
          ...state.filters.query,
          ...action.payload
        }
      }
    }))
    .addCase(fetchBooksSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: null,
      items: action.payload
    }))
    .addCase(fetchBooksFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(booksChangePagination, (state: StateType, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        ...action.payload
      }
    }))
    .addCase(createBookRequest, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: true,
      isError: false,
      messageError: ''
    }))
    .addCase(createBookSuccess, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: false,
      messageError: ''
    }))
    .addCase(createBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: false,
      messageError: action.payload
    }))
    .addCase(updateBookRequest, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: true,
      isSuccess: false,
      isError: false,
      messageError: null
    }))
    .addCase(updateBookSuccess, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isSuccess: true,
      isError: false,
      messageError: null
    }))
    .addCase(updateBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isSuccess: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(fetchBookByIdRequest, (state: StateType) => ({
      ...state,
      isSuccess: false,
      currentItem: {
        ...state.currentItem,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(fetchBookByIdSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      isSuccess: false,
      currentItem: {
        ...state.currentItem,
        item: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(fetchBookByIdFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isSuccess: false,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(setModules, (state: StateType, action: AnyAction) => ({
      ...state,
      modulesSelected: action.payload
    }))
    .addCase(clearModules, (state: StateType) => ({
      ...state,
      modulesSelected: initialState.modulesSelected
    }))
    .addCase(createChapterRequest, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(createChapterSuccess, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(createChapterFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(fetchChaptersRequest, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: true,
        isSaving: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(fetchChaptersSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: false,
        messageError: null,
        items: action.payload
      }
    }))
    .addCase(fetchChaptersFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(updateChapterRequest, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(updateChapterSuccess, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(updateChapterFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(clearCurrentBook, (state: StateType) => ({
      ...state,
      currentItem: initialState.currentItem,
      chapters: initialState.chapters
    }))
    .addCase(deleteChapterRequest, (state: StateType) => ({
      ...state,
      isProcessing: true,
      isError: false,
      messageError: null
    }))
    .addCase(deleteChapterSuccess, (state: StateType) => ({
      ...state,
      isProcessing: false,
      isError: false,
      messageError: null
    }))
    .addCase(deleteChapterFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isProcessing: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(changeChapterOrderRequest, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isChangingOrder: true
      }
    }))
    .addCase(changeChapterOrderSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isChangingOrder: false,
        items: action.payload
      }
    }))
    .addCase(changeChapterOrderFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isChangingOrder: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(clearBooksFilters, (state: StateType) => ({
      ...state,
      filters: initialState.filters
    }))
    .addCase(deleteBookRequest, (state: StateType) => ({
      ...state,
      isProcessing: true,
      isError: false,
      messageError: null
    }))
    .addCase(deleteBookSuccess, (state: StateType) => ({
      ...state,
      isProcessing: false,
      isError: false,
      messageError: null
    }))
    .addCase(deleteBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isProcessing: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(fetchClassroomsRequest, (state: StateType) => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        isFetching: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(fetchClassroomsSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        isFetching: false,
        isSuccess: false,
        isError: false,
        messageError: null,
        items: action.payload
      }
    }))
    .addCase(fetchClassroomsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(clearClassrooms, (state: StateType) => ({
      ...state,
      classrooms: initialState.classrooms
    }))
    .addCase(addClassroomsRequest, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(addClassroomsSuccess, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(addClassroomsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(changeClassroomsRequest, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(changeClassroomsSuccess, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(changeClassroomsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(fetchBookPublishedRequest, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: true,
        isProcessing: false,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(fetchBookPublishedSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: false,
        isError: false,
        messageError: null,
        book: action.payload
      }
    }))
    .addCase(fetchBookPublishedFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload ?? 'Erro ao trazer os professores.'
      }
    }))
    .addCase(clearBookPublished, (state: StateType) => ({
      ...state,
      bookToPublish: initialState.bookToPublish
    }))
    .addCase(cloneBookRequest, (state: StateType) => ({
      ...state,
      bookCloned: {
        ...state.bookCloned,
        isFetching: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(cloneBookSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      bookCloned: {
        ...state.bookCloned,
        isFetching: false,
        isSuccess: true,
        isError: false,
        messageError: null,
        id: action.payload.bookId
      }
    }))
    .addCase(cloneBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookCloned: {
        ...state.bookCloned,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(addCoauthorRequest, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isSaving: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(addCoauthorSuccess, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isSaving: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(addCoauthorFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isSaving: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(getTeachersWithSubjectsRequest, (state: StateType) => ({
      ...state,
      teachers: {
        ...state.teachers,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(getTeachersWithSubjectsSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      teachers: {
        ...state.teachers,
        items: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(getTeachersWithSubjectsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      teachers: {
        ...state.teachers,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(publishBookRequest, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isProcessing: true,
        isFetching: false,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(publishBookSuccess, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isProcessing: false,
        isFetching: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(publishBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isProcessing: false,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(updateBookStatusRequest, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isSaving: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(updateBookStatusSuccess, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isSaving: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(updateBookStatusFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isSaving: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(classroomsPerformanceRequest, (state: StateType) => ({
      ...state,
      classrooms_performance: {
        ...state.classrooms_performance,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(classroomsPerformanceSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      classrooms_performance: {
        ...state.classrooms_performance,
        isFetching: false,
        isError: false,
        messageError: null,
        data: action.payload
      }
    }))
    .addCase(classroomsPerformanceFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      classrooms_performance: {
        ...state.classrooms_performance,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(getCoverBooksRequest, (state: StateType) => ({
      ...state,
      covers: {
        ...state.covers,
        isFetching: true,
        isSuccess:  false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(getCoverBooksSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      covers: {
        ...state.covers,
        items: action.payload,
        isFetching: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(getCoverBooksFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      covers: {
        ...state.covers,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
})

export default booksReducer
