import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import Table, { Columns } from '../table/Table'
import BagIcon from '../../assets/components/Books/bag-light-icon.svg'
import ChartIcon from '../../assets/components/Books/chart-light-icon.svg'
import SchoolIcon from '../../assets/components/Books/school-icon.svg'
import ProgressIcon from '../../assets/components/Books/progress-icon.svg'
import PeopleLightIcon from '../../assets/components/Books/people-light-icon.svg'
import { ReactComponent as ViewIcon } from '../../assets/components/Books/view-outlined-icon.svg'

const data = [
  {
    id: 1,
    name: 'Escola Fractal',
    classrooms: [
      {
        id: 2000,
        name: 'Turma Fractal Tec.',
        grade: '3º E.M. - Turma Fractal Tec. A',
        performance: 100,
        progress: 100,
        access: 10,
        number_of_students: 10
      },
      {
        id: 2001,
        name: 'Turma Fractal Com.',
        grade: '3º E.M. - Turma Fractal Com. B',
        performance: 75,
        progress: 100,
        access: 12,
        number_of_students: 15
      }
    ]
  },
  {
    id: 2,
    name: 'Escola Bertoni',
    classrooms: [
      {
        id: 2003,
        name: 'Turma Pacificadores',
        grade: '3º E.M. A - Turma Pacificadores',
        performance: 90,
        progress: 60,
        access: 10,
        number_of_students: 40
      },
      {
        id: 2004,
        name: 'Turma Bertoni',
        grade: '3º E.M. B - Turma Bertoni',
        performance: 75,
        progress: 100,
        access: 12,
        number_of_students: 15
      },
      {
        id: 2005,
        name: 'Turma Bertoni Med',
        grade: '3º E.M. C - Turma BM',
        performance: 60,
        progress: 100,
        access: 40,
        number_of_students: 40
      },
      {
        id: 2006,
        name: 'Turma Bert',
        grade: '3º E.M. D - Turma Bert',
        performance: 42,
        progress: 100,
        access: 40,
        number_of_students: 40
      }
    ]
  }
]

const BoxTitle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& img': {
    width: '12px'
  },

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%'
  }
}))

const BoxFilter = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',

}))

const SelectBox = styled(Select)(({ theme }) => ({
  fontSize: 14,
  width: '200px',
  '.select__control': {
    background: 'inherit',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    '&:hover': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    },

    '.select__value-container': {
      padding: '2px 6px',

      '.select__placeholder': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%'
      }
    }
  },

  '& .select__control--is-focused': {
    border: 'none',
    borderColor: 'transparent'
  },

  '.select__menu': {
    width: '200px',
    zIndex: 88
  }
}))

const ButtonStyled = styled(Button)(() => ({
  color: '#B7B2C8',
  border: '1px solid #B7B2C8',
  borderRadius: '8px',
  fontSize: '16px',
  fontWeight: 500,
  height: '48px',
  lineHeight: '120%',
  minWidth: 'fit-content',
  paddingInline: '24px',
  textTransform: 'none'
}))

interface IClassroom {
  id: number
  name: string
  grade: string
  performance: number,
  progress: number,
  access: number,
  number_of_students: number
}

const PerformanceClassroomView = () => {
  const [schoolSelected, setSchoolSelected] = useState<any>({ value: 'placeholder', label: 'Selecione...' })
  const [classrooms, setClassrooms] = useState<IClassroom[]>([])
  const theme= useTheme()

  const schools = data.map((item) => {
    return {
      value: item.id,
      label: item.name
    }
  })

  const handleSelectSchool = (school: any) => {
    setSchoolSelected(school)
    const index = data.findIndex((item) => item.id === school.value)
    if (index !== -1) {
      setClassrooms(data?.[index]?.classrooms)
    }
  }

  useEffect(() => {
    if (schools?.length > 0) {
      setSchoolSelected(schools[0])
      setClassrooms(data[0].classrooms)
    }
  }, [])

  const columns: Columns[] = [
    {
      key: 'classroom',
      name: (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
          <img src={BagIcon} />
          <Typography>Turma</Typography>
        </Box>
      ),
      width: '40%',
      align: 'left',
      render: (row: IClassroom) => {
        return (
          <Box
            sx={{
              background: theme.palette.primary.light,
              borderRadius: '8px',
              color: '#f0f3f4',
              fontSize: '14px',
              fontWeight: 400,
              letterSpacing: '0.14px',
              maxWidth: '260px',
              overflow: 'hidden',
              padding: '5.5px 18px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: 'fit-content'
            }}
            title={row.grade}
          >
            {row.grade}
          </Box>
        )
      }
    },
    {
      key: 'performance',
      name: (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
          <img src={ChartIcon} width='16px' />
          <Typography>Desempenho</Typography>
        </Box>
      ),
      width: '25%',
      align: 'left',
      render: (row: IClassroom) => {
        return (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
            <LinearProgress
              sx={{ maxWidth: '80px', width: '100%', backgroundColor: '#e1e1e1' }}
              color={row.performance >= 50 ? 'secondary' : 'error'}
              value={row.performance}
              variant='determinate'
            />
            <Box
              component='span'
              sx={{
                color: '#666666',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%'
              }}
            >
              {row.performance}%
            </Box>
          </Box>
        )
      }
    },
    {
      key: 'progress',
      name: (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
          <img src={ProgressIcon} />
          <Typography>Progresso</Typography>
        </Box>
      ),
      width: '25%',
      align: 'left',
      render: (row: any) => {
        return (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
            <LinearProgress
              sx={{ maxWidth: '80px', width: '100%', backgroundColor: '#e1e1e1' }}
              color='secondary'
              value={row.progress}
              variant='determinate'
            />
            <Box
              component='span'
              sx={{
                color: '#666666',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%'
              }}
            >
              {row.progress}%
            </Box>
          </Box>
        )
      }
    },
    {
      key: 'access',
      name: (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
          <img src={PeopleLightIcon} />
          <Typography>Acessos</Typography>
        </Box>
      ),
      width: '10%',
      align: 'left',
      render: (row: any) => {
        return (
          <Box
            sx={{
              color: '#666666',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '120%',
              textAlign: 'right'
            }}
          >
            {row.access}/{row.number_of_students}
          </Box>
        )
      }
    }
  ]

  return (
    <div>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between'
        }}
      >
        <BoxTitle>
          <img src={ChartIcon} />
          <Typography className='title'>Desempenho das turmas</Typography>
        </BoxTitle>

        <BoxFilter>
          <img src={SchoolIcon} />
          <SelectBox
            className='school-select'
            classNamePrefix='select'
            name='school'
            placeholder='Selecione...'
            defaultValue={schools[0]}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Não encontrado'}
            isSearchable={false}
            options={schools}
            value={schoolSelected}
            onChange={handleSelectSchool}
          />
        </BoxFilter>

        <Box>
          <ButtonStyled
            type='button'
            variant='outlined'
            startIcon={<ViewIcon />}
          >
            Visualizar lista completa
          </ButtonStyled>
        </Box>
      </Box>

      <Box sx={{ marginTop: '16px' }}>
        <Table
          columns={columns}
          data={classrooms}
        />
      </Box>
    </div>
  )
}

export default PerformanceClassroomView
