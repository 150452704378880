import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import { persistor } from '../configureStore'
import { DefaultAction } from '../../utils/types'
import { getHighestRole } from '../../utils/functions'

import {
  updateCredentialsRequest,
  updateCredentialsSuccess,
  updateCredentialsFailure,
  getUserDataRequest,
  getUserDataSuccess,
  getUserDataRejected,
  getRoleRequest,
  getRoleSuccess,
  getRoleRejected,
  signOutRequest,
  signOutSuccess,
  signOutFailure,
  AuthCredentials,
  forceUserTempPasswordSuccess,
  forceUserTempPasswordRejected,
  forceUserTempPasswordRequest,
} from './actions'

import { selectedRoleFetch } from '../persistable'
import * as services from './services'
import { TakeableChannel } from 'redux-saga'
import { toast } from 'react-toastify'

export function * getUserData () {
  try {
    const response:AxiosResponse<any> = yield call(services.getUserData)
    yield put(getUserDataSuccess(response?.data))
  } catch (error) {
    yield put(getUserDataRejected())
  }
}

export function * getRoles () {
  try {
    const { persistable } = yield select(state => state)
    const params = {
      user_application_id: process.env.REACT_APP_PROJECT_ID || 18
    }
    const { data: { data } } = yield call(services.getRoles, params)
    if (!data.length) {
      yield put(signOutRequest())
    }
    yield put(getUserDataRequest())
    if (data.length && !persistable.selectedRole) {
      yield put(selectedRoleFetch(getHighestRole(data)))
    }
    yield put(getRoleSuccess(data))
  } catch (error) {
    yield put(signOutRequest())
    yield put(getRoleRejected())
  }
}

export function * signOut () {
  try {
    yield call(services.signOut)
    yield put({ type: 'app:RESET_STORE' })
    localStorage.clear()
    yield put(signOutSuccess())
  } catch (error) {
    yield put({ type: 'app:RESET_STORE' })
    yield put(signOutFailure())
  } finally {
    persistor.pause()
    persistor.flush().then(() => {
      return persistor.purge()
    })
  }
}

export function * updateCredentials (
  { payload }: DefaultAction<AuthCredentials>
) {
  try {
    const token = payload
    yield put(updateCredentialsSuccess({ credentials: token }))
  } catch (e) {
    yield put(updateCredentialsFailure())
  }
}

export function * updateUserTempPassword ({payload}: any) {
  const requestBody = {
    email: payload.email,
    password: payload.password
  }

  const notifyError = () => toast.error('Erro ao atualizar senha temporária.')
  const notifySuccess = () => toast.success('Senha temporária atualizada!')

  try {
    const response: AxiosResponse = yield call(services.updateUserTempPasswordService, requestBody)
    if (response.status === 200) {
      notifySuccess()
      yield put(forceUserTempPasswordSuccess())
    }
  } catch (error) {
    notifyError()
    yield put(forceUserTempPasswordRejected())
  }
}

// Watchers
export function * watchAuth () {
  yield takeLatest(getUserDataRequest.type, getUserData)
  yield takeLatest(getRoleRequest.type, getRoles)
  yield takeLatest(updateCredentialsRequest.type as unknown as TakeableChannel<unknown>, updateCredentials)
  yield takeLatest(signOutRequest, signOut)
  yield takeLatest(forceUserTempPasswordRequest, updateUserTempPassword)
}

export default function * authSagas () {
  yield all([
    watchAuth()
  ])
}
