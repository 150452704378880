
import { createReducer } from '@reduxjs/toolkit'

import {
  createTeacherRejected,
  createTeacherRequest,
  createTeacherSuccess,
  getAllTeachersFailure,
  getAllTeachersRequest,
  getAllTeachersSuccess,
  getTeacherByIdFailure,
  getTeacherByIdRequest,
  getTeacherByIdSuccess,
  teachersChangePagination,
  updateTeacherRequest,
  updateTeacherSuccess,
  updateTeacherFailure,
  resetTeacher
} from './actions'
import { ITeacherState, IItem } from './types'
import { AnyAction } from 'redux-saga'

const initialState: ITeacherState = {
  items: [],
  currentItem: {
    item: {} as IItem,
    isFetching: false,
    isError: false,
    messageError: '',
  },
  isFetching: false,
  isError: false,
  isSaving: false,
  messageError: '',
  pagination: {
    current: 1,
    totalOfPages: 0,
    total: 0
  }
}

const teachersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createTeacherRequest, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(createTeacherSuccess, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
    }))
    .addCase(createTeacherRejected, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(updateTeacherRequest, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isSaving: true,
      isError: false,
      messageError: '',
    }))
    .addCase(updateTeacherSuccess, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: false,
      messageError: '',
    }))
    .addCase(updateTeacherFailure, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: true,
      messageError: ''
    }))
    .addCase(getAllTeachersRequest, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(getAllTeachersSuccess, (state: ITeacherState, action: AnyAction): ITeacherState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      items: action.payload
    }))
    .addCase(getAllTeachersFailure, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: 'Erro ao solicitar professores.'
    }))
    .addCase(getTeacherByIdRequest, (state: ITeacherState): ITeacherState => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: true,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(getTeacherByIdSuccess, (state: ITeacherState, action: AnyAction): ITeacherState => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        item: action.payload,
        isFetching: false,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(getTeacherByIdFailure, (state: ITeacherState): ITeacherState => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: true,
        messageError: 'Erro ao solicitar professor.'
      }
    }))
    .addCase(teachersChangePagination, (state: ITeacherState, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: action.payload.total,
        totalOfPages: action.payload.totalOfPages,
        current: action.payload.current
      }
    }))
    .addCase(resetTeacher, (state: ITeacherState): ITeacherState => ({
      ...state,
      currentItem: initialState.currentItem
    }))
})

export default teachersReducer
