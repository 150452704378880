import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Assets
import { ReactComponent as DataIcon } from '../../../assets/components/ManagersPage/data-icon.svg'
import { ReactComponent as RoundedPlusIcon } from '../../../assets/components/ManagersPage/rounded-plus-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/components/ManagersPage/close-icon.svg'

// Components
import { Box } from '@mui/system'
import {
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Field, Form } from 'react-final-form'
import Input from '../../input/Input'
import {
  PrimaryTitle,
  SecondaryTitle
} from '../../title/Title'
import DateInput from '../input/DataPickerInput'
import { DataField, SelectContainer, SelectTitle } from '../title/StyledTitle'
import PhoneInput from '../../input/PhoneInput'
import {
  AddSubjectWithClassroom,
  BackButton,
  ButtonOnlyIcon,
  SaveButton
} from '../buttons/StyledButtons'
import FiltersSelect from '../../select/FiltersSelect'
import AddressForm from './AddressForm'
import AsyncSelectInput from '../../select/AsyncSelectInput'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import AutoCompleteSelect from '../../select/AutoCompleteInputSelect'

// Redux
import { useAppSelector } from '../../../store/hooks'
import { useDispatch } from 'react-redux'
import {
  createTeacherRequest,
  getAllTeachersRequest,
  getTeacherByIdRequest,
  resetTeacher,
  updateTeacherRequest
} from '../../../store/teachers/actions'
import { ISingleTeacher, InitialValues } from './types/teachersForm'
import { getSchoolAppIdRequest } from '../../../store/school_app_id/actions'

// syles
import { useTheme } from '@mui/material/styles'

// Utils
import {
  required,
  validateEmail,
  composeValidators
} from '../../../utils/formUtils'
import { convertDate, cpfFormatter, removeNonNumericCharacters } from '../../../utils/functions'
import SelectField from '../../select/SelectField'

const TeacherForm = ({ initialValues }: InitialValues) => {

  const navigate = useNavigate()
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const { items, isSaving, isFetching, currentItem } = useAppSelector(state => state.teachers)
  const dispatch = useDispatch()
  const { state } = useLocation()
  const teacher = state?.teacher
  const [taxpayerNumber, setTaxpayerNumber] = useState('')
  const [customInitialValues, setCustomInitialValues] = useState<any>({})
  const theme = useTheme()

  useEffect(() => {
    !initialValues && dispatch(resetTeacher())
    dispatch(getAllTeachersRequest({ school_unit_id: selectedUnit?.id, per: 999 }))
  }, [selectedUnit])

  useEffect(() => {
    dispatch(getSchoolAppIdRequest())
  }, [selectedUnit?.id])

  useEffect(() => {
    const findTeacherWithCPF = (items: any, taxpayerNumber: any) => {
      return items
        .map((item: any) => item.teachers)
        .flat()
        .find((singleTeacher: any) => removeNonNumericCharacters(singleTeacher?.user?.taxpayer_number) === removeNonNumericCharacters(taxpayerNumber)
        )
    }

    const singleTeacher: ISingleTeacher = findTeacherWithCPF(items, taxpayerNumber)

    if (taxpayerNumber) {
      dispatch(getTeacherByIdRequest({ teacherId: singleTeacher?.user?.id, schoolUnitId: selectedUnit?.id }))
    }

    const item = {
      id: singleTeacher?.user?.id,
      taxpayer_number: singleTeacher?.user?.taxpayer_number && cpfFormatter(singleTeacher?.user?.taxpayer_number),
      name: singleTeacher?.user?.name,
      email: singleTeacher?.user?.email,
      birthday: singleTeacher?.user?.birthday && convertDate(singleTeacher?.user?.birthday),
      phone: singleTeacher?.user?.phone,
      zipcode: singleTeacher?.user?.additional_data?.address?.zipcode,
      neighborhood: singleTeacher?.user?.additional_data?.address?.neighborhood,
      street: singleTeacher?.user?.additional_data?.address?.street,
      number: singleTeacher?.user?.additional_data?.address?.number,
      complement: singleTeacher?.user?.additional_data?.address?.complement,
      genre: singleTeacher?.user?.genre === 'female'
        ? { name: 'Feminino', value: 'female' }
        : singleTeacher?.user?.genre === 'male'
          ? { name: 'Masculino', value: 'male' }
          : undefined,
    }

    singleTeacher && !initialValues?.id && setCustomInitialValues(item ?? {})
  }, [taxpayerNumber])

  const onSubmitForm = (values: any) => {
    const cpfWithoutFormatting = values?.taxpayer_number?.replace(/\D/g, '')

    const teacherDataToSend = {
      ...values,
      taxpayer_number: cpfWithoutFormatting,
      school_unit_id: selectedUnit?.id,
      id: initialValues?.id ?? currentItem?.item?.user?.id
    }

    if (initialValues?.id || customInitialValues?.id) {
      dispatch(updateTeacherRequest(teacherDataToSend))
    } else {
      dispatch(createTeacherRequest(teacherDataToSend))
    }
  }

  const LargerThenMedium = useMediaQuery(`(min-width:1240px)`)
  const SmallerThenExtraLarge = useMediaQuery(`(max-width:1440px)`)
  const IsExtraLarge = useMediaQuery(`(min-width:1790px)`)

  const classroomPlaceholder = () => {
    if (LargerThenMedium && SmallerThenExtraLarge) {
      return 'Selecione a turma'
    } else if (IsExtraLarge) {
      return 'Selecione a turma do professor'
    } else {
      return 'Selecione...'
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      mutators={{ ...arrayMutators }}
      initialValues={
        initialValues && Object?.keys(initialValues)?.length > 0
          ? initialValues
          : {
            ...customInitialValues,
            classrooms: currentItem?.item?.user?.classrooms
          }
      }
      render={({ handleSubmit, form, values }) => {
        const NoClassroom = values?.classrooms?.[0]?.classroom === undefined
        const NoSubject = values?.classrooms?.[0]?.subject_ids === undefined
        const NoClassroomAndNosSubject = NoClassroom || NoSubject

        return (
          <form onSubmit={handleSubmit}>
            <Box>
              <PrimaryTitle>
                <DataIcon /> Dados cadastrais
              </PrimaryTitle>
              <Paper sx={{ borderRadius: '16px', padding: '32px 24px' }}>
                <PrimaryTitle>Professor</PrimaryTitle>
                <SecondaryTitle sx={{ pb: '5px' }}>Dados pessoais</SecondaryTitle>
                <Box sx={{ mb: '32px' }}>
                  <Box>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                      <Grid item md={6} lg={3} xl={3}>
                        <DataField>
                          <div>
                            CPF
                          </div>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '8px',
                              width: '100% !important'
                            }}
                          >
                            {isFetching ? (<Skeleton variant='rounded' animation='wave' sx={{
                              height: '40px',
                              width: '100%',
                              borderRadius: '8px'
                            }}
                            />
                            ) : (
                              <Field
                                name='taxpayer_number'
                                id='taxpayer_number'
                                component={AutoCompleteSelect}
                                disabled={initialValues?.id}
                                validate={required}
                                placeholder='Digite o CPF'
                                setTaxpayerNumber={setTaxpayerNumber}
                                options={items && items
                                  .map(item => item?.teachers)
                                  .flat()
                                  .map((option: any) => option?.user?.taxpayer_number)
                                }
                                disableClearable
                              />
                            )
                            }
                          </Box>
                        </DataField>
                      </Grid>
                      <Grid item md={6} lg={6}>
                        <DataField>
                          <div>Nome do professor</div>
                          <Field
                            name='name'
                            component={Input}
                            validate={required}
                            placeholder='Insira o nome do professor'
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={6} lg={3}>
                        <DataField>
                          <div>Data de nascimento</div>
                          <Field
                            name='birthday'
                            component={DateInput}
                            placeholder='00/00/00'
                            label='Data de nascimento'
                            validate={required}
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={6} lg={3} xl={3}>
                        <DataField>
                          <div>Contato</div>
                          <Field
                            name='phone'
                            component={PhoneInput}
                            placeholder='(00) 0 0000-0000'
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={6} lg={6}>
                        <DataField>
                          <div>Email</div>
                          <Field
                            name='email'
                            component={Input}
                            validate={composeValidators(required, validateEmail)}
                            placeholder='Insira o email do professor'
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={3} lg={4} xl={3}>
                        <SelectContainer>
                          <SelectTitle>Sexo biológico</SelectTitle>
                          <Field
                            name='genre'
                            placeholder={'Selecione...'}
                            component={SelectField}
                            width='100%'
                            hasBorder
                            options={[
                              { name: 'Masculino', value: 'male' },
                              { name: 'Feminino', value: 'female' }
                            ]}
                          />
                        </SelectContainer>
                      </Grid>
                      <SecondaryTitle sx={{
                        mb: 0,
                        pt: '56px',
                        pl: '24px',
                        pb: 0,
                        width: '100%'
                      }}
                      >
                        Atribuições Acadêmicas
                      </SecondaryTitle>
                      {
                        currentItem.isFetching ? (
                          <CircularProgress sx={{ margin: '24px' }} />
                        ) : (
                          <FieldArray
                            name='classrooms'
                          >
                            {({ fields }) => {
                              return (
                                <Box
                                  sx={{
                                    width: '100%'
                                  }}
                                >
                                  <Box sx={{
                                    width: '100%', pl: '24px',
                                    '& > button': {
                                      paddingBottom: '0px '
                                    }
                                  }}>
                                    <AddSubjectWithClassroom
                                      type='button'
                                      onClick={() => fields.push({})}
                                      disabled={false}
                                    >
                                      <RoundedPlusIcon />
                                      Adicione mais disciplinas
                                    </AddSubjectWithClassroom>

                                  </Box>
                                  <>
                                    {fields.map((name, index) => (
                                      <Grid
                                        key={index}
                                        container
                                        columnGap={2}
                                        pl='24px'
                                        mt={2}
                                      >

                                        <Grid item xs={3} >
                                          <SelectContainer>
                                            <SelectTitle>Disciplina</SelectTitle>
                                            <Field
                                              name={`${name}.subject_ids`}
                                              hasBorder
                                              component={FiltersSelect}
                                              placeholder='Selecione...'
                                              searchParam='name'
                                              request={{
                                                path: '/subjects'
                                              }}
                                              validate={required}
                                            />
                                          </SelectContainer>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <SelectContainer>
                                            <SelectTitle>Turma</SelectTitle>
                                            <Field
                                              name={`${name}.classroom`}
                                              component={AsyncSelectInput}
                                              validate={required}
                                              placeholder={classroomPlaceholder()}
                                              request={{
                                                path: '/classrooms/with_students_for_coordinator',
                                                params: {
                                                  school_unit_ids: selectedUnit?.id,
                                                }
                                              }}
                                            />
                                          </SelectContainer>
                                        </Grid>
                                        <Grid item xs={1} alignSelf='flex-end' pb='5px'>
                                          <ButtonOnlyIcon
                                            type='button'
                                            onClick={() => fields.remove(index)}
                                          >
                                            <CloseIcon />
                                          </ButtonOnlyIcon>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </>
                                  {
                                    NoClassroomAndNosSubject && <Typography
                                      sx={{
                                        color: theme.palette.error.main,
                                        fontSize: '0.75rem',
                                        paddingLeft: '24px',

                                      }}
                                    >
                                      Campos Obrigatórios *
                                    </Typography>
                                  }
                                </Box>
                              )
                            }}
                          </FieldArray>
                        )
                      }
                    </Grid>
                  </Box>
                  <AddressForm defaultValue={teacher?.additional_data} change={form.change} values={values?.zipcode} />
                </Box>
              </Paper>
            </Box>
            <Box sx={{ mt: '32px', display: 'flex', gap: '16px', justifyContent: 'flex-end' }}>
              <BackButton
                onClick={() => navigate('/manage-teachers')}
              >
                Voltar
              </BackButton>

              <SaveButton
                startIcon={
                  (isSaving || isFetching)
                    ? <CircularProgress
                      size={20}
                      variant='indeterminate'
                      color='inherit' />
                    : undefined
                }
                disabled={isSaving || isFetching || NoClassroomAndNosSubject}
                type='submit'
              >
                Salvar
              </SaveButton>
            </Box>
          </form>
        )
      }}
    />
  )
}

export default TeacherForm
