
import React from 'react'
import { RouteProps } from 'react-router-dom'
import NonLoggedPage from '../pages/NonLoggedPage'

import Home from '../pages/HomePage'
import LoginPage from '../pages/LoginPage'
import ActivitiesListPage from '../pages/ActivitiesListPage'
import DashboardTemplate from '../components/template/DashboardTemplate'
import ActivityPage from '../pages/ActivityPage'
import ActivityDetailsPage from '../pages/ActivityDetailsPage'
import ActivityRanking from '../pages/ActivityRankingPage'
import ActivitiesNewPage from '../pages/ActivitiesNewPage'
import QuestionsPage from '../pages/QuestionsPage'
import ActivityDetailsDistractorsTablePage from '../pages/ActivityDetailsDistractorsTablePage'
import ReopenActivityPage from '../pages/ReopenActivityPage'
import ActivityDiscursiveQuestionPage from '../pages/ActivityDiscursiveQuestionPage'
import QuestionsFormPage from '../pages/QuestionsFormPage'
import ContentsPage from '../pages/ContentsPage'
import ContentsFormPage from '../pages/ContentsFormPage'
import BooksPage from '../pages/BooksPage'
import BooksFormPage from '../pages/BooksFormPage'
import BooksPublishPage from '../pages/BooksPublishPage'
import BooksDetailsPage from '../pages/BooksDetailsPage'
import BooksReportsPage from '../pages/BooksReportsPage'
import BooksClonePage from '../pages/BooksClonePage'
import BooksReportPage from '../pages/BooksReportPage'
import BooksReportStudentsPerformancePage from '../pages/BooksReportStudentsPerformancePage'
import ManageStudentsPage from '../pages/ManageStudentsPage'
import ManageStudentCreatePage from '../pages/ManageStudentCreatePage'
import ManageStudentEditPage from '../pages/ManageStudentEditPage'
import ManageTeacherPage from '../pages/ManageTeachersPage'
import ManageTeacherCreatePage from '../pages/ManageTeacherCreatePage'
import ManageTeacherEditPage from '../pages/ManageTeacherEditPage'
import BooksAuthorsPage from '../pages/BooksAuthorsPage'
import BooksReportsStudentPerformancePage from '../pages/BooksReportsStudentPerformancePage'
// import ContentsFormEditContainer from '../containers/ContentsFormEditContainer'


/**
 * Only logged users can access these routes
 *
 * - Major precedence when user IS LOGGED:
 *    Overwrites the same paths of the public routes
 * - No match when user IS NOT LOGGED:
 *    Render LoginPage component by default
 */

export interface Route extends Omit<RouteProps, 'component'> {
  path: string,
  component: React.ElementType,
  template?: React.ElementType,
  default?: boolean,
  onlyNoAuth?: boolean,
  key?: string,
  exact?: boolean,
  title?: string
}

export const privateRoutes: Route[] = [
  {
    path: '/',
    component: Home,
    default: true,
    title: 'Homepage',
    template: DashboardTemplate
  },
  {
    path: '/activities',
    title: 'Atividades',
    component: ActivitiesListPage,
    template: DashboardTemplate
  },
  {
    path: '/activities/:id/view',
    title: 'Atividades',
    component: ActivityPage,
    template: DashboardTemplate
  },
  {
    path: '/activities/:id/view-activity-details',
    title: 'Atividades',
    component: ActivityDetailsPage,
    template: DashboardTemplate
  },
  {
    path: '/activities/:id/view-activity-distractors',
    title: 'Atividades',
    component: ActivityDetailsDistractorsTablePage,
    template: DashboardTemplate
  },
  {
    path: '/activities/:id/view-discursive-questions',
    title: 'Atividades',
    component: ActivityDiscursiveQuestionPage,
    template: DashboardTemplate
  },
  {
    path: '/activities/:id/ranking',
    title: 'Atividades',
    component: ActivityRanking,
    template: DashboardTemplate
  },
  {
    path: '/activities/new',
    title: 'Atividades',
    component: ActivitiesNewPage,
    template: DashboardTemplate
  },
  {
    path: '/activities/:id/edit',
    title: 'Editar Atividade',
    component: ActivitiesNewPage,
    template: DashboardTemplate
  },
  {
    path: '/activities/:id/reopen',
    title: 'Atividades',
    component: ReopenActivityPage,
    template: DashboardTemplate
  },
  {
    path: '/questions',
    title: 'Questões',
    component: QuestionsPage,
    template: DashboardTemplate
  },
  {
    path: '/contents',
    title: 'Conteúdos',
    component: ContentsPage,
    template: DashboardTemplate
  },
  {
    path: '/questions/form',
    title: 'Criar Questões',
    component: QuestionsFormPage,
    template: DashboardTemplate
  },
  {
    path: '/questions/:id/edit',
    title: 'Editar Questões',
    component: QuestionsFormPage,
    template: DashboardTemplate
  },
  {
    path: '/questions/:id/delete',
    title: 'Deletar Questões',
    component: QuestionsFormPage,
    template: DashboardTemplate
  },
  {
    path: '/content/:id/edit',
    title: 'Editar Conteúdo',
    component: ContentsFormPage,
    template: DashboardTemplate
  },
  {
    path: '/contents/form',
    title: 'Criar Conteúdos',
    component: ContentsFormPage,
    template: DashboardTemplate
  },
  {
    path: '/books',
    title: 'Livros',
    component: BooksPage,
    template: DashboardTemplate
  },
  {
    path: '/books/reports',
    title: 'Livros',
    component: BooksReportsPage,
    template: DashboardTemplate
  },
  {
    path: '/books/reports/authors',
    title: 'Livros',
    component: BooksAuthorsPage,
    template: DashboardTemplate
  },
  {
    path: '/books/reports/students-performance',
    title: 'Livros',
    component: BooksReportsStudentPerformancePage,
    template: DashboardTemplate
  },
  {
    path: '/books/form',
    title: 'Livros',
    component: BooksFormPage,
    template: DashboardTemplate
  },
  {
    path: '/books/clone',
    title: 'Livros',
    component: BooksClonePage,
    template: DashboardTemplate
  },
  {
    path: '/books/:id/publish',
    title: 'Livros',
    component: BooksPublishPage,
    template: DashboardTemplate
  },
  {
    path: '/books/:id/details',
    title: 'Livros',
    component: BooksDetailsPage,
    template: DashboardTemplate
  },
  {
    path: '/books/:id/edit',
    title: 'Editar Livro',
    component: BooksFormPage,
    template: DashboardTemplate
  },
  {
    path: '/books/:id/report',
    title: 'Livros',
    component: BooksReportPage,
    template: DashboardTemplate
  },
  {
    path: 'books/:id/students-performance',
    title: 'Livros',
    component: BooksReportStudentsPerformancePage,
    template: DashboardTemplate
  },
  {
    path: '/manage-students',
    title: 'Administração',
    component: ManageStudentsPage,
    template: DashboardTemplate
  },
  {
    path: '/manage-student/create',
    title: 'Administração',
    component: ManageStudentCreatePage,
    template: DashboardTemplate
  },
  {
    path: '/manage-student/edit',
    title: 'Administração',
    component: ManageStudentEditPage,
    template: DashboardTemplate
  },
  {
    path: '/manage-teachers',
    title: 'Administração',
    component: ManageTeacherPage,
    template: DashboardTemplate
  },
  {
    path: '/manage-teacher/create',
    title: 'Administração',
    component: ManageTeacherCreatePage,
    template: DashboardTemplate
  },
  {
    path: '/manage-teacher/edit',
    title: 'Administração',
    component: ManageTeacherEditPage,
    template: DashboardTemplate
  },
]

/**
 * Only not logged users can access these routes
 *
 * - Precedence over same paths of private routes when user IS NOT LOGGED:
 *    Overwrites the login rendering
 * - No match when user IS LOGGED
 *    Redirect to default private route
 */
export const notLoggedRoutes: Route[] = [
  {
    path: '/',
    component: NonLoggedPage,
    onlyNoAuth: true
  },
  {
    path: '/login',
    component: LoginPage,
    onlyNoAuth: true,
  },
]


