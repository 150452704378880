import React, { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Avatar, Box, Grid, LinearProgress, Paper, Pagination, styled, Typography } from '@mui/material'
import Label from '../components/label/Label'
import SelectInput from '../components/select/SelectInput'
import Table, { Columns } from '../components/table/Table'
import Button from '../components/button/Button'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { activityRankingReportFetchRequest, activityRankingReset } from '../store/reports'
import { ReactComponent as IconTitleSVG } from '../assets/icons/icon-title.svg'
import { ReactComponent as StarIcon } from '../assets/components/activity/star-icon.svg'
import { stringAvatar } from '../utils/functions'
import { IRanking } from '../models/IRanking'

const BoxStyled = styled(Box)({
  '.MuiTable-root': {
    borderSpacing: '0 24px'
  },
  '.MuiTableHead-root > tr > th': {
    padding: '0 16px 0 0'
  },
  '.MuiTableBody-root > tr': {
    background: '#FFF'
  },
  '.MuiTableBody-root > tr > td': {
    padding: '0 16px 0 0'
  }
})

const ActivitiesRankingContainer = () => {
  const { id } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [students, setStudents] = useState<IRanking[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const [classroomOptions, setClassroomOptions] = useState<{ value: string, label: string }[]>([])
  const [classroomSelected, setClassroomSelected] = useState<string>('')
  const [participantsOptions, setParticipantsOptions] = useState<{ value: number, label: string }[]>([])
  const [participantSelected, setParticipantSelected] = useState<number>(0)
  const [page, setPage] = useState(1)
  const perPage = 10
  const {
    reports: { activitiesRanking },
    persistable: { selectedUnit }
  } = useAppSelector(state => state)
  const dispatch = useAppDispatch()

  const payloadRanking: any = {
    activityId: id,
    school_unit_ids: selectedUnit?.id,
    per: 99999999,
    page: 1
  }

  const slicer = (items: any[], max: number) => {
    return items.reduce((acc, item, index) => {
      const group = Math.floor(index / max)
      acc[group] = [...(acc[group] || []), item]
      return acc
    }, [])
  }

  const formatTime = (seconds: any) => {
    const hour = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secondsRest = seconds % 60

    const hourFormated = hour.toString().padStart(2, '0')
    const minutesFormated = minutes.toString().padStart(2, '0')
    const secondsRestFormated = secondsRest.toString().padStart(2, '0')

    return `${hourFormated}:${minutesFormated}:${secondsRestFormated}`
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  useEffect(() => {
    if (id) {
      dispatch(activityRankingReportFetchRequest(payloadRanking))
    }
    return () => {dispatch(activityRankingReset())}
  }, [])

  useEffect(() => {
    if (activitiesRanking.items.length > 0) {
      const classrooms = activitiesRanking.items.filter((obj: IRanking, index, self) =>
        index === self.findIndex((t: any) => (
          t.classroom_name === obj.classroom_name
        )))
        .map((item: any) => ({ value: item.classroom_name, label: item.classroom_name }))
        .sort((a: any, b: any) => {
          const anoA = a && parseInt(a?.value.match(/\d+/)?.[0])
          const anoB = b && parseInt(b?.value.match(/\d+/)?.[0])

          if (anoA !== anoB) {
              return anoA - anoB
          }

          const turmaA = a?.value.match(/[A-Z]/)?.[0]
          const turmaB = b?.value.match(/[A-Z]/)?.[0]

          return turmaA?.localeCompare(turmaB)
      })

      if (classrooms && classrooms.length > 1) {
        setClassroomOptions([{
          value: 'all_classrooms',
          label: 'Todas as turmas'
        }, ...classrooms])
        setClassroomSelected('all_classrooms')
      } else {
        setClassroomOptions(classrooms)
        setClassroomSelected(classrooms[0]?.value)
      }

      const participants = activitiesRanking.items.map((item: IRanking) => ({
        label: item.participant_name,
        value: item.id
      }))

      if (participants && participants.length > 0) {
        setParticipantsOptions([{
          label: 'Todos os alunos',
          value: 0
        }, ...participants])
      }
    }
  }, [activitiesRanking.items])

  useEffect(() => {
    if (activitiesRanking.items.length > 0) {
      const hasClassroom = classroomOptions.some(item => item.value === classroomSelected)
      if (hasClassroom) {
        if (classroomSelected === 'all_classrooms') {
          const data = slicer(activitiesRanking.items, perPage)
          const options = activitiesRanking.items.map((item: IRanking) => ({
            value: item.id,
            label: item.participant_name
          }))
          setParticipantsOptions([{ value: 0, label: 'Todos os alunos' }, ...options])
          setParticipantSelected(0)
          if (data.length === 1) {
            setPage(1)
            setStudents(data[0])
            setTotalPages(data.length)
          } else {
            setStudents(data[page - 1])
            setTotalPages(data.length)
          }
        } else {
          const data = activitiesRanking.items.filter((item: IRanking) => item.classroom_name === classroomSelected)
          const options = activitiesRanking.items.filter((item: IRanking) => item.classroom_name === classroomSelected).map((item: IRanking) => ({
            value: item.id,
            label: item.participant_name
          }))
          setParticipantsOptions([{ value: 0, label: 'Todos os alunos' }, ...options])
          setParticipantSelected(0)
          if (data && data.length > 0) {
            const dataslice = slicer(data, perPage)
            if (dataslice.length === 1) {
              setPage(1)
              setStudents(dataslice[0])
              setTotalPages(dataslice.length)
            } else {
              setStudents(dataslice[page - 1])
              setTotalPages(dataslice.length)
            }
          }
        }
      }
    }
  }, [activitiesRanking.items, page, classroomOptions, classroomSelected])

  const handleClassroomSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setClassroomSelected(value)
  }

  const handleParticipantSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setParticipantSelected(Number(value))
    if (Number(value) === 0) {
      if (classroomSelected === 'all_classrooms') {
        const data = slicer(activitiesRanking.items, perPage)
        if (data.length === 1) {
          setPage(1)
          setStudents(data[0])
          setTotalPages(data.length)
        } else {
          setStudents(data[page - 1])
          setTotalPages(data.length)
        }
      } else {
        const data = activitiesRanking.items.filter((item: IRanking) => item.classroom_name === classroomSelected)
        if (data && data.length > 0) {
          const dataslice = slicer(data, perPage)
          if (dataslice.length === 1) {
            setPage(1)
            setStudents(dataslice[0])
            setTotalPages(dataslice.length)
          } else {
            setStudents(dataslice[page - 1])
            setTotalPages(dataslice.length)
          }
        }
      }
    } else {
      const data = activitiesRanking.items.filter((item: IRanking) => item.id === Number(value))
      setStudents(data)
      setTotalPages(data.length)
    }
  }

  const columns: Columns[] = [
    {
      key: 'position',
      name: '',
      width: '3%',
      align: 'left',
      render: (row: IRanking) => {
        return (
          <Box>{row.position}</Box>
        )
      }
    },
    {
      key: 'name',
      name: <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Nome</Typography>,
      width: '37%',
      align: 'left',
      render: (row: IRanking) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', color: '#666', fontWeight: 600 }}>
            <Avatar
              {...stringAvatar(row?.participant_name, 30, 30)}
              variant='rounded'
            />
            {row?.participant_name}
          </Box>
        )
      }
    },
    {
      key: 'grade',
      name: <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Série</Typography>,
      width: '15%',
      align: 'left',
      render: (row: IRanking) => {
        return (
          <Box sx={{ color: '#666', fontWeight: 500 }}>{row.grade_name}</Box>
        )
      }
    },
    {
      key: 'classroom',
      name: <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Turma</Typography>,
      width: '20%',
      align: 'left',
      render: (row: IRanking) => {
        return (
          <Box sx={{ color: '#666', fontWeight: 500 }}>{row.classroom_name}</Box>
        )
      }
    },
    {
      key: 'score',
      name: <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Acertos</Typography>,
      width: '10%',
      align: 'center',
      render: (row: IRanking) => <Box sx={{ color: '#666', fontWeight: 500 }}>{row.score}</Box>
    },
    {
      key: 'participant_score',
      name: <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Notas</Typography>,
      width: '5%',
      align: 'center',
      render: (row: IRanking) => <Box sx={{ color: '#666', fontWeight: 500 }}>{row.participant_score}</Box>
    },
    {
      key: 'performance',
      name: <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Performance</Typography>,
      width: '30%',
      align: 'center',
      render: (row: IRanking) => {
        return (
          <Box sx={{ color: '#666666', fontWeight: 500 }}>
            {row.performance}%
          </Box>
        )
      }
    },
    {
      key: 'time',
      name: <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Tempo</Typography>,
      width: '10%',
      align: 'center',
      render: (row: IRanking) => {
        return (
          <Box sx={{ color: '#666', fontWeight: 500 }}>
            {row.time_on_activity ? formatTime(row.time_on_activity) : '00:00:00'}
          </Box>
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = state?.type === 'exam'
      ? ['position', 'name', 'grade', 'classroom', 'score', 'participant_score', 'time']
      : ['position', 'name', 'grade', 'classroom', 'performance', 'time']
    return columns.filter((column: { key: string }) => columnKeys.includes(column.key))
  }

  if (activitiesRanking.isFetching) return <LinearProgress color='secondary' />

  return (
    <div>
      {state?.title && (
        <Label>
          <IconTitleSVG />
          {state?.title}
        </Label>
      )}
      <Box sx={{ marginTop: '32px' }}>
        <Label>
          <StarIcon />
          Ranking dos alunos
        </Label>

        <Box mt={4} mb={2}>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <SelectInput
                label='Turma'
                name='turma'
                width='100%'
                defaultValue={classroomSelected}
                onChange={handleClassroomSelectChange}
                options={classroomOptions}
                sx={{ background: '#FFFFFF' }}
              />
            </Grid>

            <Grid item xs={6}>
              <SelectInput
                label='Nome'
                name='turma'
                width='100%'
                defaultValue={participantSelected}
                onChange={handleParticipantSelectChange}
                options={participantsOptions}
                sx={{ background: '#FFFFFF' }}
              />
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ background: '#FFFFFF', borderRadius: '16px', padding: '6px 22px' }}>
          <BoxStyled>
            <Table columns={getColumns()} data={students} />
          </BoxStyled>

          {!activitiesRanking.isFetching && totalPages > 1 && (
            <Box sx={{ mt: 4, marginBottom: '18px' }}>
              <Pagination
                count={totalPages}
                size='small'
                color='primary'
                page={page}
                onChange={handleChangePage}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              />
            </Box>)}
        </Paper>
      </Box>
      <Box sx={{ mt: 4, textAlign: 'right' }}>
        <Button variant='contained' onClick={() => navigate('/activities')}>Voltar</Button>
      </Box>
    </div>
  )
}

export default ActivitiesRankingContainer
