import { createReducer} from '@reduxjs/toolkit'
import { JsonFormat, JsonResponseFormat } from '../../utils/types'

import { IUser, UserValue } from '../../models/IUser'
import { IRole } from '../../models/IRole'
import { getRoleRejected, getRoleRequest, getRoleSuccess, getUserDataRejected, getUserDataRequest, getUserDataSuccess, signOutRequest, updateCredentialsSuccess } from './actions'

export type RolesJson = JsonFormat<'roles', IRole>
export type UserJson = JsonFormat<'users', IUser>
export type GetRoleResponse = JsonResponseFormat<UserJson[],(RolesJson)[]>

export interface UpdateCredentialsSuccessPayload {
  credentials: object
}

export interface GetRolesSuccessPayload {
  roles: IRole[]
}

export interface GetUserSuccessPayload {
  user: IUser
}


interface StateType {
  user: IUser
  credentials: object
  isLogged: boolean
  isFetching: boolean
  isError: boolean
  messageError: string | null
  roles: IRole[]
}

const initialState: StateType = {
  user: {...UserValue,
    genre: ''
  },
  credentials: {},
  isLogged: false,
  isFetching: false,
  isError: false,
  messageError: '',
  roles: []
}

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUserDataRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(getUserDataSuccess, (state: StateType, action: { payload: IUser } ): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      user: action.payload,
    }))
    .addCase(getUserDataRejected, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(getRoleRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(getRoleSuccess, (state: StateType, action): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      roles: action.payload,
    }))
    .addCase(getRoleRejected, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(
      signOutRequest,
      (): StateType => ({
        ...initialState
      })
    )
    .addCase(updateCredentialsSuccess, (state: StateType, action: { payload: UpdateCredentialsSuccessPayload }): StateType => ({
      ...state,
      isFetching: false,
      isLogged: true,
      isError: false,
      messageError: '',
      credentials: action.payload.credentials
    }))
})

export default authReducer
