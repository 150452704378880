import React from 'react'
import { Field } from 'react-final-form'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { styled } from '@mui/material/styles'
import AsyncSelectInput from './AsyncSelectInput'
import { required } from '../../utils/formUtils'

const DivSubjectTopics = styled('div')({
  background: '#F9F9F9',
  borderRadius: 16,
  marginTop: 16,
})

export interface ISelectSubjectComboProps {
  subjects: any
  isTeacher: boolean
  userId?: number | string | null
  highSchoolSegmentId: number
  name: string
  segment?: { id: number | string, name: string }
  hasBackground?: boolean
  nameTopics?: string
  validate?: boolean
  fields?: FieldArrayRenderProps<any, HTMLElement>['fields']
}

const SelectSubjectCombo: React.FC<ISelectSubjectComboProps> = ({
  fields,
  name,
  nameTopics = 'topics',
  validate,
  hasBackground,
  subjects,
  segment,
  isTeacher,
  userId,
  highSchoolSegmentId,
}) => {
  const Slot = hasBackground ? DivSubjectTopics : 'div'
  return (
    <div>
      <Field
        name={name}
        component={AsyncSelectInput}
        isMulti
        placeholder='Selecione a disciplina...'
        label='Disciplinas'
        searchParam='name'
        validate={validate ? required : undefined}
        request={{
          path: '/subjects',
          params: {
            basic: true,
            with_knowledge_area: true,
            teacher_user_id: isTeacher ? userId : null
          }
        }}
      />
      {segment && segment?.id && segment?.id === 4 && (
        <Slot>
          {fields?.map((name: string, index: number) => {
            return (
              <div key={`${index}-${fields.length}-${name}`} style={{ marginTop: '16px' }}>
                <Field
                  name={`${name}.${nameTopics}`}
                  component={AsyncSelectInput}
                  cacheUniqs={segment && segment?.id}
                  defaultOptions={true}
                  isMulti
                  placeholder='Selecione...'
                  label={`Tópicos - ${subjects[index]?.name}`}
                  request={{
                    path: '/topics',
                    params: {
                      segment_ids: segment && segment?.id || highSchoolSegmentId || 4,
                      subject_ids: subjects[index]?.id
                    }
                  }}
                />
              </div>
            )
          })
          }
        </Slot>
      )}
    </div>
  )
}

export default React.memo(SelectSubjectCombo)
