import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

import Input from '../../input/Input'
import Button from '../../button/Button'
import Grid from '@mui/material/Grid'
import TextAreaInput from '../../input/TextAreaInput'
import { ReactComponent as CalendarSVG } from '../../../assets/icons/calendar.svg'
import { ReactComponent as ReloadIcon } from '../../../assets/components/activity/reload-icon.svg'

import { Box, InputAdornment, TextField } from '@mui/material'
import QuestionCard from '../../Questions/QuestionCard'
import ContentCard from '../../Contents/ContentCard'
import { isEmpty } from 'lodash'
import Label from '../../label/Label'
import SelectField from '../../select/SelectField'

const DivBtns = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: '3rem',
})

const PointsContainer = styled('div')({
  alignItems: 'center',
  background: '#F0F3F4',
  borderRadius: 8,
  display: 'flex',
  gap: '8px',
  height: 40,
  justifyContent: 'center',
  marginLeft: 'auto',
  width: 153,
  '& p': {
    fontSize: 12,
    fontWeight: 600,
  }
})

const ButtonStyled = styled('button')({
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '2px',
  cursor: 'pointer',
  display: 'flex',
  height: '22px',
  justifyContent: 'center',
  padding: 0,
  width: '30px',
  '&:hover': {
    backgroundColor: '#6666660a'
  }
})

interface ScoreProps {
  value: number
  isLocked: boolean
  wasEdited: boolean
  type: string
}

const ActivitiesQuestionsForm = (props: any) => {
  const {
    handleSubmit,
    questions,
    previous,
    user,
    values,
    removeQuestion,
    changeOrder,
    change,
    initialValues
  } = props
  const [totalPoints, setTotalPoints] = useState<number>(values?.value ? Number(values.value) : 10)
  const [score, setScore] = useState<ScoreProps[]>([])
  const numberOfQuestions = questions.selectedQuestions.allIds.length
  const totalOfQuestions = questions.selectedQuestions.types.filter((question: any) => question.type !== 'content').length
  const showTotalPoints = values.activity_type?.value === 'exam'

  useEffect(() => {
    initialValues?.value && setTotalPoints(Number(initialValues.value))
  }, [initialValues.value])

  useEffect(() => {
    const totalScore = initialValues && initialValues?.content_items && initialValues?.content_items.reduce((acc: number, item: any) => {
      let score = 0
      score += Number(item?.value_item)
      return acc + score
    }, 0)

    const scorePerQuestion = (initialValues && initialValues?.content_items && totalScore === totalPoints)
      ? initialValues?.content_items.map((item: any) => {
        return {
          value: Number(item.value_item),
          wasEdited: false,
          isLocked: true
        }
      })
      : questions.selectedQuestions.types.map((item: any) => {
        return {
          value: item.type !== 'content' ? Number((totalPoints / totalOfQuestions).toFixed(2)) : null,
          isLocked: true,
          wasEdit: false,
          type: item.type
        }
      })
    setScore(scorePerQuestion)
    change('value', String(totalPoints))
  }, [initialValues, totalPoints, numberOfQuestions, questions.selectedQuestions.types])

  useEffect(() => {
    const contentItems = questions.selectedQuestions.allIds.map((item: any, index: number) => {
      return {
        id: item,
        order: index,
        value_item: showTotalPoints && score[index]?.value ? String(score[index]?.value) : null
      }
    })
    change('content_items', contentItems)
  }, [questions.selectedQuestions.allIds, score])

  const handleResetScore = () => {
    setTotalPoints(10)
    const pointsReseted = questions.selectedQuestions.types.map((item: any) => {
      return {
        value: item.type !== 'content' ? Number((totalPoints / totalOfQuestions).toFixed(2)) : null,
        isLocked: true,
        wasEdit: false,
        type: item.type
      }
    })
    setScore(pointsReseted)
  }

  const handleTotalPoints = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalPoints(Number(event.target.value))
    change('value', event.target.value)
  }

  const handleLockInput = (index: number) => {
    const updatedValues = [...score]
    updatedValues[index] = { ...updatedValues[index], isLocked: !updatedValues[index].isLocked }
    setScore(updatedValues)
  }

  const handleChangeScore = (index: number, value: number) => {
    const updatedValues = [...score]
    updatedValues[index] = { ...updatedValues[index], value, wasEdited: true }
    const totalUpdated = updatedValues.reduce((acc, item) => {
      let updated = 0
      if (item.wasEdited || !item.isLocked) updated += item.value
      return acc + updated
    }, 0)
    const difference = totalPoints - totalUpdated
    const numberOfQuestionsEdited = updatedValues.filter(item => (item.wasEdited === true || item.isLocked === false))
    const updatedValuesWithoutContent = updatedValues.filter((item: any) => item.type !== 'content')
    const remainingIndices = updatedValuesWithoutContent.length - numberOfQuestionsEdited.length
    const distributedDifference = (difference / remainingIndices)

    for (let i = 0; i < updatedValues.length; i++) {
      if (i !== index && updatedValues[i].isLocked && !updatedValues[i].wasEdited) {
        updatedValues[i] = {
          ...updatedValues[i],
          value: updatedValues[i].type !== 'content' ? parseFloat((distributedDifference).toFixed(2)) : 0
        }
      }
    }

    if (totalUpdated <= totalPoints) setScore(updatedValues)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Paper elevation={0} sx={{ borderRadius: '16px', padding: '45px 24px', mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Label>Cabeçalho</Label>
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Field
              name='student'
              component={Input}
              placeholder=''
              label='Aluno(a)'
              disabled
            />
          </Grid>
          <Grid item sm={4}>
            <Field
              name='teacher'
              component={Input}
              placeholder='Professor(a)'
              defaultFieldValue={user?.name || ''}
              label='Professor(a)'
              disabled
            />
          </Grid>
          <Grid item sm={4}>
            <Field
              name='subject'
              component={Input}
              placeholder='Disciplina'
              defaultFieldValue={values?.subject?.name || ''}
              label='Disciplina'
              disabled
            />
          </Grid>
          <Grid item sm={3}>
            <Field
              name='title'
              component={Input}
              placeholder='Título'
              defaultFieldValue={values?.title || ''}
              label='Título'
              disabled
            />
          </Grid>
          <Grid item sm={3}>
            <Field
              name='grades'
              component={Input}
              placeholder='Série'
              defaultFieldValue={values?.grades?.name || ''}
              label='Série'
              disabled
            />
          </Grid>
          <Grid item sm={3}>
            <Field
              name='classroom'
              component={Input}
              placeholder=''
              label='Turma'
              disabled
            />
          </Grid>
          <Grid item sm={3}>
            <Field
              name='date'
              component={Input}
              placeholder='00/00/0000'
              label='Data'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarSVG />
                  </InputAdornment>
                ),
              }}
              disabled
            />
          </Grid>
          <Grid item sm={12}>
            <Field
              name='description'
              component={TextAreaInput}
              placeholder='Descrição'
              label='Descrição'
              value={values?.description || ''}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name='shuffle_options'
              component={SelectField}
              placeholder='Selecione...'
              label='Embaralhar'
              rowLabel
              width='220px'
              hasBorder
              defaultFieldValue={{ name: 'Não embaralhar', value: 'no_shuffle' }}
              options={[
                { name: 'Não embaralhar', value: 'no_shuffle' },
                { name: 'Questões e itens', value: 'questions_and_options' },
                { name: 'Apenas a ordem das questões', value: 'only_questions' },
                { name: 'Apenas a ordem dos itens', value: 'only_options' },
              ]}
            />
          </Grid>
          <Grid item xs={5}>
            <Field
              name='number_of_versions'
              component={SelectField}
              placeholder='00'
              label='Número de provas diferentes'
              rowLabel
              hasBorder
              defaultFieldValue={{ name: 0, value: 0 }}
              options={[...Array(11).keys()].map(number => {
                return { name: number, value: number }
              })}
              width='80px'
            />
          </Grid>
          <Grid item xs={3}>
            {showTotalPoints && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <PointsContainer>
                  <p>Valor Total:</p>
                  <TextField
                    name='scoreTotal'
                    type='number'
                    inputProps={{ min: 0 }}
                    value={totalPoints}
                    onChange={handleTotalPoints}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        width: '50px',
                        height: '40px',
                        fontSize: '14px',
                        textAlign: 'center',
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'center',
                          padding: 0
                        },
                        '& fieldset': {
                          border: 'none'
                        }
                      }
                    }}
                  />
                </PointsContainer>
                <ButtonStyled
                  type='button'
                  onClick={handleResetScore}
                  title='Resetar notas'
                >
                  <ReloadIcon />
                </ButtonStyled>
              </Box>
            )}
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          {
            questions?.selectedQuestions && !isEmpty(questions.selectedQuestions.byId) && questions.selectedQuestions.allIds.length > 0 && questions.selectedQuestions.allIds.map((question: any, index: number) => {
              const selectedQuestion = questions.selectedQuestions.byId[question]?.question
              const contentId = questions.selectedQuestions.byId[question]?.id
              return selectedQuestion?.question_type ? (
                <Box
                  key={`${question}-${index}`}
                  sx={{ marginBottom: '16px', border: '1px solid #d9d9d9', borderRadius: '16px', overflow: 'hidden' }}
                >
                  <QuestionCard
                    question={selectedQuestion}
                    contentId={contentId}
                    removeQuestion={removeQuestion}
                    addValue={showTotalPoints}
                    questionOrder={true}
                    index={index}
                    totalQuestions={questions.selectedQuestions.allIds.length}
                    changeOrder={changeOrder}
                    score={score[index]?.value}
                    handleChangeScore={handleChangeScore}
                    isLocked={score[index]?.isLocked}
                    handleLockInput={handleLockInput}
                  />
                </Box>
              )
                : selectedQuestion
                  ? (
                    <Box
                      key={index}
                      sx={{ marginBottom: '16px', border: '1px solid #d9d9d9', borderRadius: '16px', overflow: 'hidden' }}
                    >
                      <ContentCard
                        key={index}
                        content={selectedQuestion}
                        removeQuestion={removeQuestion}
                        questionOrder={true}
                        index={index}
                        totalQuestions={questions.selectedQuestions.allIds.length}
                        changeOrder={changeOrder}
                      />
                    </Box>
                  )
                  : null
            })
          }
        </Box>
      </Paper>
      <DivBtns>
        <Button variant='outlined' onClick={previous}>Voltar</Button>
        <Button type='submit' disabled={questions.selectedQuestions.allIds.length === 0}>Avançar</Button>
      </DivBtns>
    </form>
  )
}

export default ActivitiesQuestionsForm
