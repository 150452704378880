import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { Class, IClassroom } from '../../models/IClassroom'
import { classroomFetchFailure, classroomFetchRequest, classroomFetchSuccess, fetchClassroomWithStudentsCoordinatorRequest, fetchClassroomWithStudentsCoordinatorSuccess, fetchClassroomWithStudentsRequest, fetchClassroomWithStudentsFailure, fetchClassroomWithStudentsSuccess, fetchClassroomWithStudentsCoordinatorFailure, classroomWithStudentsChangePagination } from './actions'

const initialState: StateType = {
  items: [],
  classes: [],
  isFetching: false,
  pagination: {
    current: 1,
    totalOfPages: 0,
    total: 0
  },
  school:[],
  students:[{}],
  isError:false,
  messageError:null
}


interface StateType {
  items: IClassroom[] | []
  classes: Class[] | []
  isFetching: boolean
  pagination: {
    current: number,
    totalOfPages: number,
    total: number
  }
  school: object
  students: object[]
  isError:boolean
  messageError: string|null
}

const classroomReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(classroomFetchRequest,(state: StateType):StateType => ({
        ...state,
        isFetching:true,
        isError:false,
        messageError:''
    }))
    .addCase(classroomFetchSuccess, (state: StateType, action:AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      items: action.payload.data,
      pagination: action.payload.pagination
    }))
    .addCase(classroomFetchFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
    }))
    .addCase(fetchClassroomWithStudentsRequest,(state: StateType):StateType => ({
      ...state,
      isFetching:true,
      isError:false,
      messageError:''
  }))
    .addCase(fetchClassroomWithStudentsSuccess, (state: StateType, action:AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      classes: action.payload.data
  }))
    .addCase(fetchClassroomWithStudentsFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
  }))
    .addCase(fetchClassroomWithStudentsCoordinatorRequest,(state: StateType):StateType => ({
      ...state,
      isFetching:true,
      isError:false,
      messageError:''
  }))
    .addCase(fetchClassroomWithStudentsCoordinatorSuccess, (state: StateType, action:AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      classes: action.payload.data
  }))
    .addCase(fetchClassroomWithStudentsCoordinatorFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
  }))
  .addCase(classroomWithStudentsChangePagination, (state: StateType, action: AnyAction) => ({
    ...state,
    pagination: {
      ...state.pagination,
      total: action.payload.total,
      totalOfPages: action.payload.totalOfPages,
      current: action.payload.current
    }
  }))
})

export default classroomReducer
