import * as React from 'react'
import { useLocation, NavLink } from 'react-router-dom'

// assets
import closeSideBarArrow from '../../assets/arrowlfts.svg'
import minimalistLogo from '../../assets/icons/logo-icon.svg'
import { IconButton } from '@mui/material'
import fullLogo from '../../assets/Logo2.svg'
import arrow from '../../assets/arrow.svg'
import { ReactComponent as HomeSVG } from '../../assets/icons/homepage.svg'
import { ReactComponent as ActivitiesSVG } from '../../assets/icons/atividades.svg'
import { ReactComponent as AutoralSVG } from '../../assets/components/Content/autoral-icon.svg'
import { ReactComponent as GearIcon } from '../../assets/components/ManagersPage/configuration-icon.svg' // eslint-disable-line
// import { ReactComponent as BookIcon } from '../../assets/components/Sidebar/book-icon.svg'
import logOut from '../../assets/logout.svg'

// components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles'

// Redux
import { useAppSelector } from '../../store/hooks'
import { signOutRequest } from '../../store/auth/actions'
import { useDispatch } from 'react-redux'

import styles from './styles.module.scss'

const drawerWidth = 200

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  background: theme.palette.primary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(10)} + 1px)`,
  background: theme.palette.primary.main,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  right: 'auto',
  width: 66,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginRight: drawerWidth,
    width: `calc(66px - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const menuOptions = [
  {
    key: 'home',
    label: 'Homepage',
    icon: <HomeSVG />,
    path: '/',
  },
  {
    key: 'atividades',
    label: 'Atividades',
    icon: <ActivitiesSVG />,
    path: '/activities',
  }
]

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))


const Sidebar = ({ openSidebar }: any) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [openSideMenu, setOpenSideMenu] = React.useState(false)
  const [openAutoralCollapse, setOpenAutoralCollapse] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openManagementCollapse, setOpenManagementCollapse] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { auth: { roles } } = useAppSelector(state => state)
  const location = useLocation()

  // const isCoordinator = roles?.some(role => role.name === 'coordinator')
  // const isAdmin = roles?.some(role => role.name === 'admin')
  // const isDirector = roles?.some(role => role.name === 'director')

  const handleArrowOpenButton = () => {
    openSidebar(true)
    setOpen(true)
    setOpenSideMenu(true)
  }
  const handleArrowCloseButton = () => {
    openSidebar(false)
    setOpen(false)
    setOpenSideMenu(false)
    setOpenAutoralCollapse(false)
    setOpenManagementCollapse(false)
  }

  const handleClickAutoral = () => {
    if (!openAutoralCollapse) {
      setOpenAutoralCollapse(true)
      openSidebar(true)
      setOpen(true)
      setOpenSideMenu(true)
    } else {
      setOpenAutoralCollapse(false)
    }
  }

  // const handleClickManagement = () => {
  //   if (!openManagementCollapse) {
  //     setOpenManagementCollapse(true)
  //     openSidebar(true)
  //     setOpen(true)
  //     setOpenSideMenu(true)
  //   } else {
  //     setOpenManagementCollapse(false)
  //   }
  // }

  const handleLogoClick = () => {
    if (!open) {
      setOpen(true)
      openSidebar(true)
      setOpenSideMenu(true)
    } else {
      setOpen(false)
      openSidebar(false)
      setOpenSideMenu(false)
      setOpenAutoralCollapse(false)
      setOpenManagementCollapse(false)
    }
  }

  return (
    <Box sx={{ display: 'flex', maxHeight: '100vh', overflow: 'none', justifyContent: 'space-between' }}>
      <AppBar position='fixed' open={openSideMenu} className={styles.appBar}>
        <Toolbar
          sx={{
            marginRight: 200,
            ...(openSideMenu && { display: 'none' }),
          }}
        >
          <img onClick={handleLogoClick} src={minimalistLogo} className={styles.minimalistLogo} />
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleArrowOpenButton}
            edge='start'
            className={styles.arrow}
          >
            <img src={closeSideBarArrow} className={styles.arrowIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={openSideMenu} >
        <DrawerHeader>
          <img onClick={handleLogoClick} src={fullLogo} className={styles.fullLogo} />
          <IconButton className={styles.iconButton} onClick={handleArrowCloseButton}>
            <img src={arrow} />
          </IconButton>
        </DrawerHeader>
        <ul className={styles.menuContent}>
          {menuOptions.map(({ key, label, icon: Icon, path }) => {

            function extractPath(inputString: string) {
              const parts = inputString.split('/')

              if (parts.length >= 1) {
                return '/' + parts[1]
              }

              return null
            }
            const inputString = location.pathname
            const extractedPath = extractPath(inputString)
            const isActive = extractedPath === path
            return (
              <li style={{ borderLeft: `${isActive ? `2px solid ${theme.palette.primary.light}` : '2px solid transparent'}` }} key={key} className={styles.menuLi}>
                <NavLink to={path}>
                  <span style={{ backgroundColor: `${isActive ? theme.palette.primary.light : ''}` }}>{Icon}</span>
                  {openSideMenu && (
                    <span
                      style={{ color: `${isActive ? theme.palette.primary.light : '#fff'}`, fontWeight: `${isActive ? 600 : 400}` }}
                      className={styles.nameMenu}
                    >
                      {label}
                    </span>)}
                </NavLink>
              </li>
            )
          })}
          <li className={styles.menuItem}>
            <div
              style={{
                borderLeft: `${location.pathname === '/questions' ||
                  location.pathname === '/contents' ?
                  `2px solid ${theme.palette.primary.light}` :
                  '2px solid transparent'}`
              }}
              className={styles.collapseMenu}
              onClick={handleClickAutoral}
            >
              <span
                id={styles.autoralIcon}
                style={{
                  backgroundColor: `${(location.pathname === '/questions' ||
                    location.pathname === '/contents') ?
                    theme.palette.primary.light : 'transparent'}`
                }}
              >
                <AutoralSVG />
              </span>
              {
                openSideMenu && <span className={styles.nameMenu}>
                  Autoral
                </span>
              }
              {
                openAutoralCollapse ? <ExpandLess /> : <ExpandMore />
              }
            </div>
            <Collapse
              className={styles.collapseBody}
              in={openAutoralCollapse}
              timeout='auto'
              unmountOnExit
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <NavLink style={{ marginTop: '14px', marginBottom: '24px' }} to='/questions'>
                  <span
                    className={styles.subMenuHover}
                    style={{
                      color: `${location.pathname === '/questions' ? theme.palette.primary.light : '#fff'}`,
                      fontWeight: `${location.pathname === '/questions' ? 600 : 400}`
                    }}
                  >
                    Questões
                  </span>
                </NavLink>
                <NavLink to='/contents'>
                  <span
                    className={styles.subMenuHover}
                    style={{
                      color: `${location.pathname === '/contents' ? theme.palette.primary.light : '#fff'}`,
                      fontWeight: `${location.pathname === '/contents' ? 600 : 400}`
                    }}
                  >
                    Conteúdos
                  </span>
                </NavLink>
              </div>
            </Collapse>
          </li>

          {/* <li
            style={{ borderLeft: `${location.pathname === '/books' ? '2px solid #BC9BFA' : '2px solid transparent'}` }}
            className={styles.menuLi}
          >
            <NavLink to='/books'>
              <span style={{ backgroundColor: `${location.pathname === '/books' ? '#BC9BFA' : ''}` }}><BookIcon /></span>
              {open && (
                <span
                  style={{ color: `${location.pathname === '/books' ? '#BC9BFA' : '#fff'}`, fontWeight: `${location.pathname === '/books' ? 600 : 400}` }}
                  className={styles.nameMenu}
                >
                  Livros
                </span>)}
            </NavLink>
          </li> */}
          {/* {(
            isCoordinator ||
            isAdmin ||
            isDirector
          ) && <li className={styles.menuItem}>
              <div
                style={{
                  borderLeft: `${location.pathname === '/manage-students' ||
                    location.pathname === '/manage-teachers' ?
                    `2px solid ${theme.palette.primary.light}` :
                    '2px solid transparent'}`
                }}
                className={styles.collapseMenu}
                onClick={handleClickManagement}
              >
                <span
                  id={styles.autoralIcon}
                  style={{
                    backgroundColor: `${(location.pathname === '/manage-students' ||
                      location.pathname === '/manage-teachers') ?
                      theme.palette.primary.light : 'transparent'}`
                  }}
                >
                  <GearIcon />
                </span>
                {
                  openSideMenu && <span className={styles.nameMenu}>
                    Gerenciar
                  </span>
                }
                {
                  openManagementCollapse ? <ExpandLess /> : <ExpandMore />
                }
              </div>
              <Collapse
                className={styles.collapseBody}
                in={openManagementCollapse}
                timeout='auto'
                unmountOnExit
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <NavLink style={{ marginTop: '14px', marginBottom: '24px' }} to='/manage-students'>
                    <span
                      className={styles.subMenuHover}
                      style={{
                        color: `${location.pathname === '/manage-students' ? theme.palette.primary.light : '#fff'}`,
                        fontWeight: `${location.pathname === '/manage-students' ? 600 : 400}`
                      }}
                    >
                      Turmas
                    </span>
                  </NavLink>
                  <NavLink to='/manage-teachers'>
                    <span
                      className={styles.subMenuHover}
                      style={{
                        color: `${location.pathname === '/manage-teachers' ? theme.palette.primary.light : '#fff'}`,
                        fontWeight: `${location.pathname === '/manage-teachers' ? 600 : 400}`
                      }}
                    >
                      Professores
                    </span>
                  </NavLink>
                </div>
              </Collapse>
            </li>
          } */}
        </ul>

        <ul className={styles.menuLogout}>
          <li className={styles.profiles}>
            {openSideMenu && (
              <Button
                className={styles.logoutButton}
                onClick={() => {
                  dispatch(signOutRequest())
                }}
              >
                <img src={logOut} />
                <p className={styles.logout}>Sair</p>
              </Button>
            )}
            {!openSideMenu && (
              <Button
                className={styles.logoutButton}
                onClick={() => {
                  dispatch(signOutRequest())
                }}
              >
                <img src={logOut} />
              </Button>
            )}
          </li>
        </ul>
      </Drawer>
    </Box>
  )
}

export default Sidebar
