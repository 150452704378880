import { combineReducers, AnyAction } from 'redux'
import { all } from 'redux-saga/effects'

// reducers
import authReducer from './auth/reducers'
import activitiesReducer from './activities/reducers'
import booksReducer from './books/reducer'
import contentsReducer from './contents/reducer'
import educationReducer from './education'
import examsReducer from './exams/reducer'
import modulesReducer from './modules/reducer'
import questionsReducer from './questions/reducer'
import reportsReducer from './reports'
import unitsReducer from './units'
import classroomReducer from './classroom/reducers'
import persistableReducer from './persistable'
import gradesReducer from './grades'
import filtersReducer from './filters'
import alertReducer from './alert'
import schoolAppIdReducer from './school_app_id/reducer'

// sagas
import authSagas from './auth/sagas'
import activitiesSagas from './activities/sagas'
import booksSagas from './books/sagas'
import contentsSagas from './contents/sagas'
import educationSagas from './education/sagas'
import examsSagas from './exams/sagas'
import modulesSagas from './modules/sagas'
import questionsSagas from './questions/sagas'
import reportsSagas from './reports/sagas'
import unitsSagas from './units/sagas'
import classroomSaga from './classroom/sagas'
import filtersSaga from './filters/sagas'
import teachersClassroomsSagas from './teachersClassrooms/sagas'
import teachersClassroomsReducer from './teachersClassrooms/reducers'
import userSagas from './user/sagas'
import userReducer from './user'
import studentReducer from './students/reducers'
import studentsSagas from './students/sagas'
import teachersSagas from './teachers/sagas'
import teachersReducer from './teachers/reducers'
import getSchoolsAppsIdsSagas from './school_app_id/sagas'

export function * rootSaga () {
  yield all([
    authSagas(),
    activitiesSagas(),
    booksSagas(),
    contentsSagas(),
    educationSagas(),
    examsSagas(),
    modulesSagas(),
    questionsSagas(),
    reportsSagas(),
    unitsSagas(),
    classroomSaga(),
    filtersSaga(),
    teachersClassroomsSagas(),
    userSagas(),
    studentsSagas(),
    teachersSagas(),
    getSchoolsAppsIdsSagas()
  ])
}

export const appReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  activities: activitiesReducer,
  books: booksReducer,
  classroom :classroomReducer,
  contents: contentsReducer,
  education: educationReducer,
  exams: examsReducer,
  filters: filtersReducer,
  grades: gradesReducer,
  modules: modulesReducer,
  persistable: persistableReducer,
  questions: questionsReducer,
  reports: reportsReducer,
  teachersClassrooms: teachersClassroomsReducer,
  units: unitsReducer,
  users: userReducer,
  students: studentReducer,
  teachers: teachersReducer,
  schoolAppId: schoolAppIdReducer
})

const rootReducer = (state:any, action: AnyAction) => {
  if (action.type === 'app:RESET_STORE') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
