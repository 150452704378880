import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PlayerIcon from '../../assets/components/Content/player-icon.svg'

const Video = styled('div')({
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  'iframe': {
    height: '350px',
    width: '100%',
  }
})

const VideoEmbed = (props: any) => {

  const [embedId, setEmbedId] = useState('')

  function getVideoPlataform(url: string) {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return 'Youtube'
    } else if (url.includes('vimeo.com')) {
      return 'Vimeo'
    } else {
      return 'Undefined'
    }
  }
  const platformCode = getVideoPlataform(props.urlVideo)

  function getEmbedId(url: string) {
    if (url.includes('youtube.com')) {
      const embed = props.urlVideo.match(/[?&]v=([^&]+)/)
      return embed
    } else if (url.includes('youtu.be')) {
      const embed = props.urlVideo.match(/^https?:\/\/(?:[^/]+\/*)([^/?]+)/)
      return embed
    } else if (url.includes('vimeo.com')) {
      const embed = props.urlVideo.match(/vimeo\.com\/(\d+)/)
      return embed
    }
  }

  useEffect(() => {
    setEmbedId(getEmbedId(props.urlVideo))
  }, [])

  return (
    <Video>

      {platformCode === 'Youtube' &&
        (
          <iframe
            src={embedId && `https://www.youtube.com/embed/${embedId[1]}`}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Embedded youtube'
          />
        )
      }
      {platformCode === 'Vimeo' &&
        (
          <iframe
            src={embedId && `https://player.vimeo.com/video/${embedId[1]}`}
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
          />
        )
      }
      {platformCode === 'Undefined' &&
        (
          <>
            <Box textAlign='center'>
              <Box width='85px' height='85px' mb={1} marginX='auto'>
                <img src={PlayerIcon} alt='ícone de player' style={{ width: '100%' }} />
              </Box>
              <Typography sx={{ fontSize: '14px' }}>
                <strong>Link:</strong>{' '}
                <a
                  href={props.urlVideo && props.urlVideo}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {props.urlVideo && props.urlVideo}
                </a>
              </Typography>
            </Box>
          </>
        )
      }

    </Video>
  )
}


export default VideoEmbed
