/* eslint-disable no-console */
import React, { useEffect } from 'react'
import { Grid, Box, Paper, styled, CircularProgress, Typography } from '@mui/material'
import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import SchemaContent from './SchemaContent'
import Button from '../../button/Button'
import Input from '../../input/Input'
import Label from '../../label/Label'
import SuccessMessage from '../../message/SuccessMessage'
import AsyncSelectInput from '../../select/AsyncSelectInput'
import SelectMultipleSubjects from '../../select/SelectMultipleSubjects'
import ListIcon from '../../../assets/components/Content/list-icon.svg'
import { highSchoolSegmentId } from '../../../utils/constants'
import { required } from '../../../utils/formUtils'
import { contentItemCreateRequest, contentItemEditRequest } from '../../../store/contents/actions'
import { gradesFetchRequest } from '../../../store/education'

const ImgStyle = styled('img')({
  marginRight: 16,
})

const PaperContainer = styled(Paper)(({
  borderRadius: 4,
  padding: '30px',

  '& .required': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontStyle: 'italic',
    marginTop: '20px',
    textAlign: 'right',
  }
}))

export interface fileData {
  file_name?: string
  id?: number | null
  type?: string
  url?: string
}

export interface FormValues {
  name?: string
  id?: number | string
  description?: string
  subject_ids?: { id: number | string, name: string, topics?: { id: number | string, name: string }[] }[]
  age_ranges?: { id?: number, name: string }
  segments?: { id: number | string, name: string }
  topics?: any,
  attachment?: object | null,
  schema_content?: { file_name?: string, original_file_name?: string, size?: number, type?: string, value?: string } | { value?: string }
  content_format_id?: number | string | null
  content_format?: number | string | null
  textInput?: string | null
  videoInput?: string | null
  fileData?: { data: fileData }
  fileUpload?: { size: number }
}

interface ContentsFormProps {
  isTeacher: any;
  userId?: any;
  initialValues?: FormValues
}

const DivBtns = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: '3rem',
  height: 90,
})

const ContentsForm = ({ isTeacher, userId, initialValues }: ContentsFormProps) => {
  const dispatch = useAppDispatch()
  const {
    contents: { isSuccess, isSavingOrUpdating },
    teachersClassrooms: { data },
    education: { grades: { items }}
  } = useAppSelector(state => state)

  useEffect(() => {
    dispatch(gradesFetchRequest())
  }, [])

  const segments = data && items && items.filter(grade => data.some((item) => item.grade.id === grade.id))

  const segmentsOptions = segments && segments.map((option: any) => ({
    id: option.education_segment.id,
    value: option.education_segment.name,
    name: option.education_segment.name
  })).reduce((result: any, current: any) => {
    const isDuplicate = result.some((item: any) => item.id === current.id)
    if (!isDuplicate) result.push(current)
    return result
  }, [])

  const onSubmitForm = (values: FormValues) => {
    const data = { ...values }
    !initialValues?.id
      ? dispatch(contentItemCreateRequest({ data }))
      : dispatch(contentItemEditRequest({ data }))
  }

  const Buttons = ({ invalid }: { invalid: boolean }) => {
    return (
      <DivBtns>
        <Button
          type='submit'
          variant='contained'
          disabled={invalid || isSavingOrUpdating}
          startIcon={
            isSavingOrUpdating
              ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
              : undefined
          }
        >
          Salvar
        </Button>
      </DivBtns>
    )
  }

  return (
    <>
      <div>
        <Box sx={{ display: 'flex', alignItems: 'left', mb: 2, flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'left', width: '100%' }}>
            <Label>
              <ImgStyle src={ListIcon} alt='icone de lista' />
              Lista de Conteúdos / Criar Conteúdo
            </Label>
          </Box>
        </Box>

        <Form
          sx={{ display: 'flex', gap: 2 }}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmitForm}
          render={({ handleSubmit, values, form, invalid }) => {
            return (
              <form onSubmit={handleSubmit}>
                <PaperContainer>

                  {isSuccess && !isSavingOrUpdating && (
                    <SuccessMessage
                      type='contents'
                      path='contents'
                      isEdit={Boolean(initialValues?.id)}
                    />
                  )}

                  {!isSuccess &&
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Field
                          component={Input}
                          placeholder='Digite o nome do seu conteúdo'
                          label='Nome *'
                          name='name'
                          required
                          validate={required}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Field
                          component={Input}
                          placeholder='Digite a descrição do seu conteúdo'
                          label='Descrição *'
                          name='description'
                          required
                          validate={required}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={SchemaContent}
                          name='schema_content'
                          contentFormat={values.content_format_id}
                          change={form.change}
                          initialValues={initialValues}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name='segments'
                          component={AsyncSelectInput}
                          placeholder='Selecione o segmento...'
                          label='Segmento *'
                          options={segmentsOptions ?? []}
                          required
                          validate={required}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SelectMultipleSubjects
                          isTeacher={isTeacher}
                          userId={userId}
                          subjects={values.subject_ids}
                          segments={values.segments}
                          highSchoolSegmentIds={highSchoolSegmentId}
                          change={form.change}
                          topics={values?.topics}
                          initialValues={initialValues}
                        />
                      </Grid>
                    </Grid>
                  }

                  {!isSuccess && <Typography className='required'>* Campo obrigatório</Typography>}
                </PaperContainer>
                {!isSuccess &&
                  <Buttons invalid={invalid} />
                }
              </form>
            )
          }}
        />
      </div>
    </>
  )
}
export default ContentsForm
