import { takeLatest, call, put, all } from 'redux-saga/effects'
import {
  classroomFetchRequest,
  classroomFetchSuccess,
  classroomFetchFailure,
  fetchClassroomWithStudentsSuccess,
  fetchClassroomWithStudentsFailure,
  fetchClassroomWithStudentsRequest,
  fetchClassroomWithStudentsCoordinatorRequest,
  fetchClassroomWithStudentsCoordinatorFailure,
  fetchClassroomWithStudentsCoordinatorSuccess,
  classroomWithStudentsChangePagination
} from './actions'
import * as services from './services'
import { AxiosResponse } from 'axios'

export function * fetchClassroom({ payload }:any) {
  try {
    const params ={
      basic:true,
      ...payload
    }
    const { data } = yield call(services.fetchClassroom, params)
    yield put(classroomFetchSuccess(data))
  } catch(error:any){
    yield put (classroomFetchFailure())
  }
}
export function * fetchClassroomWithStudents({payload}:any ) {
  try {
    const params = {
      school_unit_ids: payload.school_unit_ids
    }
    const response: AxiosResponse = yield call(services.fetchClassroomWithStudents, params)
    if (response.status === 200) {
      yield put(fetchClassroomWithStudentsSuccess(response.data))
    }
  } catch(error:any){
    yield put (fetchClassroomWithStudentsFailure())
  }
}
export function * fetchClassroomWithStudentsCoordinator({payload}:any ) {
  try {
    const params = {
      school_unit_ids: payload.school_unit_ids,
      page: payload.page
    }
    const response: AxiosResponse = yield call(services.fetchManagerClassroomWithStudents, params)
    const pagination = {
      totalOfPages: Number(response.headers['x-total-pages']),
      current: Number(response.headers['x-page']),
      total: Number(response.headers['x-total-count'])
    }
    if (response.status === 200) {
      yield put(fetchClassroomWithStudentsCoordinatorSuccess(response.data))
      yield put (classroomWithStudentsChangePagination(pagination))
    }
  } catch(error){
    yield put (fetchClassroomWithStudentsCoordinatorFailure())
  }
}

export function * watchFetchClassroom() {
  yield takeLatest(classroomFetchRequest, fetchClassroom)
}
export function * watchFetchClassroomWithStudents() {
  yield takeLatest(fetchClassroomWithStudentsRequest, fetchClassroomWithStudents)
}
export function * watchFetchClassroomWithStudentsCoordinator() {
  yield takeLatest(fetchClassroomWithStudentsCoordinatorRequest, fetchClassroomWithStudentsCoordinator)
}

export default function * classroomSaga () {
  yield all([watchFetchClassroom(), watchFetchClassroomWithStudents(), watchFetchClassroomWithStudentsCoordinator()])
}
