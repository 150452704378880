import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import BooksLeastUsed from '../Books/BooksLeastUsed'
import SelectInput from '../../components/select/SelectInput'
import { styled } from '@mui/material/styles'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { ReactComponent as BookSVG } from '../../assets/components/Books/book-icon.svg'
import { ReactComponent as NotebookSVG } from '../../assets/components/Books/notebook-icon.svg'
import { ReactComponent as SchoolSVG } from '../../assets/components/Books/school-icon.svg'
import { ReactComponent as ViewSVG } from '../../assets/components/Books/view-icon.svg'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

const paperStyled = {
  borderRadius: 4,
  mt: 4,
  p: 3,
  '& .MuiTable-root': {
    minWidth: '300px'
  },
  '& td': {
    p: 1,
    '&:first-of-type': {
      pl: 2
    },
    '&:last-of-type': {
      pr: 2
    }
  },
  '& span': {
    color: '#04AEC9',
    fontWeight: '500'
  },
  '& .red span': {
    color: '#F69E9E'
  }

}

const SelectBox = styled('div')(() => ({
    '& .MuiSelect-select' : {
       paddingLeft: '8px',
       textAlign: 'left'
    }
   }))

const FullList = styled('a')(({theme}) => ({
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
    color: theme.palette.primary.light,
    'svg':{
        marginRight: '8px'
    }
}))


const BoxStyled = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    position: 'relative',
  
    '& .main-slider-prev-2, .main-slider-next-2': {
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#9f9f9f',
        borderRadius: '50%',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 2,
    },

    '& .main-slider-prev-2': {
        left:'-9px'
     },
     '& .main-slider-next-2': {
        right:'-9px'
     },
  
    '& .swiper-button-disabled': {
      opacity: 0.4,
    }
  }))


const BookLeastUsedView: any = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [seachParam, setSearchParams] = useSearchParams()

  const book = {
    'id': 566022,
    'name': 'Livro Física',
    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed dignissim metus, in aliquet orci....',
    'status': 'ready_to_publish',
    'photo_url': '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/022/original/image_%287%29.png?1720014010',
    'authors': [
        {
            'id': 278944,
            'name': 'Professor N P Marcio'
        }
    ],
    'co_authors': [],
    'subjects': [
        {
            'id': 5,
            'name': 'Física'
        }
    ],
    'chapters': [
        {
            'id': 3120,
            'name': 'Fisica I',
            'number': '1',
            'chapter_order': 1,
            'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed dignissim metus, in aliquet orci. ',
            'lms_syllabus': [
                {
                    'id': 994229,
                    'syllabus_order': 1,
                    'school_curriculum_id': 210058,
                    'book_book_chapter_id': 3120
                },
                {
                    'id': 994231,
                    'syllabus_order': 1,
                    'school_curriculum_id': 210036,
                    'book_book_chapter_id': 3120
                }
            ],
            'classroom_chapters': [
                {
                    'id': 89,
                    'school_classroom': {
                        'id': 759,
                        'name': 'Turma C 3 EM',
                        'shift': null,
                        'grade': {
                            'id': 23,
                            'name': '3º E.M.'
                        },
                        'term': {
                            'id': 10,
                            'name': '2024'
                        },
                        'school_unit': {
                            'id': 251651,
                            'name': 'Bertoni'
                        }
                    },
                    'classroom_modules': [
                        {
                            'id': 211,
                            'book_classrooms_chapter_id': 89,
                            'content_module': {
                                'id': 943,
                                'name': 'Atrito ',
                                'is_fractal': true,
                                'content_module_items_count': 10,
                                'questions_count': 10,
                                'content_items_count': 0
                            },
                            'module_order': 1,
                            'start_date': null,
                            'end_date': null
                        },
                        {
                            'id': 212,
                            'book_classrooms_chapter_id': 89,
                            'content_module': {
                                'id': 946,
                                'name': 'Trabalho e Energia',
                                'is_fractal': true,
                                'content_module_items_count': 10,
                                'questions_count': 10,
                                'content_items_count': 0
                            },
                            'module_order': 2,
                            'start_date': null,
                            'end_date': null
                        }
                    ],
                    'start_date': null,
                    'end_date': null
                },
                {
                    'id': 90,
                    'school_classroom': {
                        'id': 758,
                        'name': 'Turma B 2º EM',
                        'shift': null,
                        'grade': {
                            'id': 22,
                            'name': '2º E.M.'
                        },
                        'term': {
                            'id': 10,
                            'name': '2024'
                        },
                        'school_unit': {
                            'id': 251651,
                            'name': 'Bertoni'
                        }
                    },
                    'classroom_modules': [
                        {
                            'id': 213,
                            'book_classrooms_chapter_id': 90,
                            'content_module': {
                                'id': 943,
                                'name': 'Atrito ',
                                'is_fractal': true,
                                'content_module_items_count': 10,
                                'questions_count': 10,
                                'content_items_count': 0
                            },
                            'module_order': 1,
                            'start_date': '2024-07-01T00:00:00.000-03:00',
                            'end_date': '2024-07-15T00:00:00.000-03:00'
                        },
                        {
                            'id': 214,
                            'book_classrooms_chapter_id': 90,
                            'content_module': {
                                'id': 946,
                                'name': 'Trabalho e Energia',
                                'is_fractal': true,
                                'content_module_items_count': 10,
                                'questions_count': 10,
                                'content_items_count': 0
                            },
                            'module_order': 2,
                            'start_date': '2024-07-16T00:00:00.000-03:00',
                            'end_date': '2024-07-31T00:00:00.000-03:00'
                        }
                    ],
                    'start_date': '2024-07-01T00:00:00.000-03:00',
                    'end_date': '2024-07-31T00:00:00.000-03:00'
                }
            ]
        },
        {
            'id': 3121,
            'name': 'Simulados',
            'number': '2',
            'chapter_order': 2,
            'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et commodo massa. Maecenas porta est et sapien laoreet cursus. Sed id neque ac risus ornare tincidunt sit amet ut diam. Vivamus est dolor, mattis sed felis non, dapibus posuere libero. Mauris eu ex sed nunc venenatis vestibulum sit amet vel felis. Quisque non viverra sapien. Nunc vitae libero in velit pretium consectetur vitae in leo. In pretium sapien at augue consectetur, eget aliquet elit pellentesque. Curabitur malesuada a lacus nec sollicitudin. Integer rutrum leo ac velit fringilla malesuada.',
            'lms_syllabus': [
                {
                    'id': 994230,
                    'syllabus_order': 2,
                    'school_curriculum_id': 210058,
                    'book_book_chapter_id': 3121
                },
                {
                    'id': 994232,
                    'syllabus_order': 2,
                    'school_curriculum_id': 210036,
                    'book_book_chapter_id': 3121
                }
            ],
            'classroom_chapters': [
                {
                    'id': 91,
                    'school_classroom': {
                        'id': 759,
                        'name': 'Turma C 3 EM',
                        'shift': null,
                        'grade': {
                            'id': 23,
                            'name': '3º E.M.'
                        },
                        'term': {
                            'id': 10,
                            'name': '2024'
                        },
                        'school_unit': {
                            'id': 251651,
                            'name': 'Bertoni'
                        }
                    },
                    'classroom_modules': [
                        {
                            'id': 215,
                            'book_classrooms_chapter_id': 91,
                            'content_module': {
                                'id': 785,
                                'name': 'Simulado ENEM Física ',
                                'is_fractal': true,
                                'content_module_items_count': 10,
                                'questions_count': 10,
                                'content_items_count': 0
                            },
                            'module_order': 1,
                            'start_date': null,
                            'end_date': null
                        },
                        {
                            'id': 216,
                            'book_classrooms_chapter_id': 91,
                            'content_module': {
                                'id': 974,
                                'name': 'Simulado FISICA FUVEST',
                                'is_fractal': true,
                                'content_module_items_count': 10,
                                'questions_count': 10,
                                'content_items_count': 0
                            },
                            'module_order': 2,
                            'start_date': null,
                            'end_date': null
                        }
                    ],
                    'start_date': null,
                    'end_date': null
                },
                {
                    'id': 92,
                    'school_classroom': {
                        'id': 758,
                        'name': 'Turma B 2º EM',
                        'shift': null,
                        'grade': {
                            'id': 22,
                            'name': '2º E.M.'
                        },
                        'term': {
                            'id': 10,
                            'name': '2024'
                        },
                        'school_unit': {
                            'id': 251651,
                            'name': 'Bertoni'
                        }
                    },
                    'classroom_modules': [
                        {
                            'id': 217,
                            'book_classrooms_chapter_id': 92,
                            'content_module': {
                                'id': 785,
                                'name': 'Simulado ENEM Física ',
                                'is_fractal': true,
                                'content_module_items_count': 10,
                                'questions_count': 10,
                                'content_items_count': 0
                            },
                            'module_order': 1,
                            'start_date': null,
                            'end_date': null
                        },
                        {
                            'id': 218,
                            'book_classrooms_chapter_id': 92,
                            'content_module': {
                                'id': 974,
                                'name': 'Simulado FISICA FUVEST',
                                'is_fractal': true,
                                'content_module_items_count': 10,
                                'questions_count': 10,
                                'content_items_count': 0
                            },
                            'module_order': 2,
                            'start_date': null,
                            'end_date': null
                        }
                    ],
                    'start_date': null,
                    'end_date': null
                }
            ]
        }
    ]
}


  return (
    <Paper sx={paperStyled}>

      {
          <>
            
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                    <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600 }}>
                        <BookSVG style={{ marginRight: 16 }} />
                        Livros menos utilizados
                    </Typography>
                    <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                        <NotebookSVG />
                        <SelectInput
                            border='none'
                            defaultValue='geral'
                            name='selectStatus'
                            sx={{ width: '140px', textAlign: 'center' }}
                            onChange={''}
                            options={[
                                { label: 'Disciplina:', value: 'geral' },
                                // ...Object.values(activitiesStatus)
                            ]}
                            />
                    </SelectBox>
                    <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                        <SchoolSVG />
                        <SelectInput
                            border='none'
                            defaultValue='geral'
                            name='selectStatus'
                            sx={{ width: '100px', textAlign: 'center' }}
                            onChange={''}
                            options={[
                                { label: 'Escola:', value: 'geral' },
                                // ...Object.values(activitiesStatus)
                            ]}
                            />
                    </SelectBox>
                    </Box>
                <BoxStyled>
                    <button className='main-slider-prev-2'>
                        <ArrowBackIosNewRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
                    </button>
                    <Swiper
                        slidesPerView={'auto'}
                        className='slider'
                        modules={[Navigation]}
                        navigation={{
                        prevEl: '.main-slider-prev-2',
                        nextEl: '.main-slider-next-2'
                        }}
                    >
                        <SwiperSlide>
                            <BooksLeastUsed
                                key={0}
                                bookId={book.id}
                                bookCover={book?.photo_url}
                                bookDescription={book?.description}
                                bookTitle={book?.name}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <BooksLeastUsed
                                key={9}
                                bookId={book.id}
                                bookCover={book?.photo_url}
                                bookDescription={book?.description}
                                bookTitle={book?.name}
                            />
                        </SwiperSlide>
                    </Swiper>
                    <button className='main-slider-next-2'>
                        <ArrowForwardIosRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
                    </button>
                </BoxStyled>
              </Grid>
              <Box sx={{ fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%'}}>
                <FullList href='#'>
                    <ViewSVG /> Lista completa
                </FullList>
              </Box>
            </Grid>
          </>
        // )
      }
    </Paper>
  )
}

export default BookLeastUsedView
