import React, { useState } from 'react'

import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import styles from './styles.module.scss'

type DashboardProps = PropsWithChildren<{
  title: string
}>

const DashboardTemplate = ({ children, title }: DashboardProps) => {
  const [openSidebar, setOpenSidebar] = useState(false)
  return (
    <div className={styles.dashboard} style={{ gridTemplateColumns: openSidebar ? '200px 1fr' : '81px 1fr' }}>
      <Sidebar className={styles.dashboardSidebar} openSidebar={setOpenSidebar} />
      <Header className={styles.dashboardNavbar} title={title} />
      <div className={styles.dashboardContent}>
        {children}
      </div>
    </div>
  )
}

export default DashboardTemplate