import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Chip, Divider, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import Button from '../button/Button'
import SelectInput from '../select/SelectInput'
import BoxCard, { BoxHeader, BoxBottom, BoxOrderItems } from '../card/BoxCard'
import DialogModal from '../Modal/DialogModal'
import ModalDelete from '../Modal/ModalDelete'
import { ButtonCardContainer } from '../Activities/FiltersContainer'
import ParentContent from '../Contents/ParentContent'
import { deleteQuestionRequest } from '../../store/questions/actions'
import BarCodeIcon from '../../assets/components/Questions/barCode-icon.svg'
import TagIcon from '../../assets/components/Questions/tag-icon.svg'
import EditIcon from '../../assets/components/Questions/edit-icon.svg'
import TrashIcon from '../../assets/components/Questions/trash-white-icon.svg'
import { ReactComponent as ViewIcon } from '../../assets/components/Questions/view-icon.svg'
import { ReactComponent as ViewDisabledIcon } from '../../assets/components/Questions/view-disable-icon.svg'
import { ReactComponent as ActivityListIcon } from '../../assets/components/Questions/activityList-icon.svg'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'


const QuestionOption = styled('ul')(({ theme }) => ({
  paddingLeft: 0,
  '& li': {
    display: 'flex',
    alignItems: 'center',
    '& div:first-of-type': {
      marginRight: 8
    }
  },
  '& .item--correct': {
    '&:before': {
      content: '""',
      backgroundImage:
        'url("data:image/svg+xml;charset=utf-8,%3Csvg width=\'10\' height=\'10\' viewBox=\'0 0 10 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath' +
        ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12.0458 0.235595C12.3299 0.537026 12.3158 1.01169 12.0144 1.29579L4.08456 8.76968C3.74829 9.08662 3.22042 9.07686 2.89609 8.7477L0.215757 6.02737C-0.074959 5.73231 ' +
        '-0.0714425 5.25745 0.223612 4.96674C0.518666 4.67602 0.993526 4.67954 1.28424 4.97459L3.51839 7.24207L10.9856 0.204214C11.287 -0.0798856 11.7617 -0.0658356 12.0458 0.235595Z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
      backgroundColor: theme.palette.secondary.main,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      borderRadius: '50%',
      width: 14.6,
      height: 14,
      marginLeft: -20,
      marginRight: 5,
      position: 'relative',
    }
  }
}))

const ChipStyled = styled(Chip)(({ theme }) => ({
  borderColor: theme.palette.text.secondary,
  color: theme.palette.text.secondary
}))

const ButtonStyled = styled('button')({
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '2px',
  cursor: 'pointer',
  display: 'flex',
  height: '20px',
  justifyContent: 'center',
  padding: 0,
  width: '30px',
  '&:hover': {
    backgroundColor: '#6666660a'
  }
})

const alphabeticOptions = ['a)', 'b)', 'c)', 'd)', 'e)', 'f)']

const QuestionCard = (props: any) => {
  const { question, contentId, selectQuestionCheck, removeQuestion, addValue, questionOrder, index, totalQuestions, changeOrder, score, handleChangeScore, isLocked, handleLockInput, isToActivity, showSettings } = props
  const theme = useTheme()
  const [resolutionView, setResolutionView] = useState(false)
  const [materialView, setMaterialView] = useState(false)
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { auth: { user } } = useAppSelector((state) => state)

  const handleOpenDeleteQuestionModal = () => {
    setOpenDeleteQuestionModal(true)
  }

  const handleCloseDeleteQuestionModal = () => {
    setOpenDeleteQuestionModal(false)
  }

  const deleteQuestionById = (id: any) => {
    dispatch(deleteQuestionRequest(id))
  }

  const handleSelectChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    changeOrder(event.target.value, contentId)
  }

  const handleResolutionView = () => {
    setResolutionView(prevState => !prevState)
  }

  const handleMaterialView = () => {
    setMaterialView(prevState => !prevState)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^[0-9,.]*$/
    if (!regex.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault()
    }
  }

  return (
    <>
      <BoxCard>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
          <BoxHeader>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <img src={BarCodeIcon} alt='ícone do código da questão' />
                Código: <span className='info'>{question?.id}</span>
              </Box>
              {showSettings && question.who_created_user?.id === user?.id && !isToActivity && <ButtonCardContainer>
                <Button
                  onClick={() => navigate(`/questions/${question.id}/edit`)}
                  sx={{ width: 227, backgroundColor: theme.palette.primary.main }}
                  disableElevation
                >
                  <img src={EditIcon} alt='ícone de editar questão' />
                  Editar
                </Button>
                <Button
                  onClick={handleOpenDeleteQuestionModal}
                  type='button'
                  style={{
                    backgroundColor: '#B7B2C8',
                    color: '#FFF',
                    border: 'none'
                  }}
                  variant='outlined'>
                  <img src={TrashIcon} alt='deletar questão' />
                  Deletar
                </Button>
              </ButtonCardContainer>}
            </Box>
            <Box sx={{ margin: '0 -24px', padding: '16px' }}>
              <img src={TagIcon} alt='ícone das tags da questão' />
              Tags: {question.concourse_exams?.map((item: any, index: any) => {
                return (item?.concourse) ? (
                  <Chip color='primary' key={index} label={item?.concourse.name} variant='outlined' />
                ) : (
                  <Chip color='primary' key={index} label={item?.concourse_exam?.concourse.name} variant='outlined' />
                )
              })}
              <div>
                {[
                  { value: 'only_choice', label: 'Objetiva' },
                  { value: 'discursive', label: 'Discursiva' }
                ].map(
                  (item, index) =>
                    item.value === question.question_type && (
                      <ChipStyled key={index} label={item.label} variant='outlined' />
                    )
                )}
                {[
                  { value: 'text_only', label: 'Texto' },
                  { value: 'image_only', label: 'Imagem' },
                  { value: 'video_only', label: 'Video' }
                ].map(
                  (item, index) =>
                    item.value === question.answer_type && (
                      <ChipStyled key={index} label={item.label} variant='outlined' />
                    )
                )}
                {question.segments.map((item: { name: string }, index: number) => (
                  <ChipStyled key={index} label={item.name} variant='outlined' />
                ))}
                {question.subjects.map((item: any, index: number) => (
                  <ChipStyled key={index} label={item.name} variant='outlined' />
                ))}
              </div>
              <div>
                {question.topics.map((item: any, index: number) => (
                  <ChipStyled key={index} label={item.complete_name} variant='outlined' />
                ))}
              </div>
            </Box>
          </BoxHeader>
          {addValue && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography sx={{ fontSize: '12px', fontWeight: 400, whiteSpace: 'nowrap' }}>Valor questão:</Typography>
              <TextField
                name={`value-${question.id}`}
                type='number'
                onChange={(event) => handleChangeScore(index, Number(event.target.value))}
                onKeyDown={handleKeyPress}
                value={Number(score) || 0}
                inputProps={{ min: 0 }}
                disabled={isLocked}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    width: '87px',
                    height: '40px',
                    fontSize: '14px',
                    textAlign: 'center',
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'center',
                      padding: 0
                    }
                  }
                }}
              />
              <ButtonStyled type='button' onClick={() => handleLockInput(index)}>
                {isLocked
                  ? <LockOutlinedIcon fontSize='small' sx={{ color: '#f29d9d' }} />
                  : <LockOpenOutlinedIcon fontSize='small' sx={{ color: '#4cbfd4' }} />
                }
              </ButtonStyled>
            </Box>
          )}
        </Box>
        <Divider sx={{
          borderBottom: '1px dashed #9F9F9F',
          margin: '0 10px 34px -24px',
          width: 'calc(100% + 48px)'
        }}
        />
        <div>
          <div style={{ overflow: 'hidden' }}>
            <div dangerouslySetInnerHTML={{ __html: question.statement }} />
          </div>
          <QuestionOption>
            {question.question_options?.map((item: any, index: number) => (
              <li key={index} className={`${item.is_correct ? 'item--correct' : ''}`}>
                <div>{alphabeticOptions[index]}</div>
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              </li>
            ))}
          </QuestionOption>
        </div>
      </BoxCard >
      <BoxBottom>
        {questionOrder && (
          <BoxOrderItems>
            <ActivityListIcon />
            <Typography>Ordem questão:</Typography>
            <SelectInput
              name='questionsOrder'
              border='none'
              defaultValue={index + 1}
              onChange={handleSelectChange}
              placeholder={String(index + 1)}
              width='71px'
              options={[...Array(totalQuestions).keys()].map(number => {
                const optionValue = ++number
                return { label: optionValue, value: optionValue }
              })}
            />
          </BoxOrderItems>
        )}
        {question.resolution && (
          <Button
            onClick={handleResolutionView}
            type='button'
            sx={{ marginRight: '10px', background: '#FFF' }}
            variant='outlined'
            startIcon={<ViewIcon />}
          >
            Ver resolução
          </Button>
        )}
        {(question.content_parents && question.content_parents.length > 0) && (
          <Button
            onClick={handleMaterialView}
            type='button'
            sx={{ marginRight: '10px' }}
            variant='outlined'
            startIcon={question.content_parents.length > 0 ? <ViewIcon /> : <ViewDisabledIcon />}
            disabled={question.content_parents.length === 0}
          >
            Material de apoio
          </Button>
        )}
        {selectQuestionCheck}
        {removeQuestion && <Button
          onClick={() => removeQuestion(contentId)}
          type='button'
          style={{
            marginRight: 10,
            backgroundColor: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            color: '#FFF'
          }}
          variant='outlined'
        >
          Remover
        </Button>}
      </BoxBottom>
      <DialogModal
        open={resolutionView}
        handleClose={handleResolutionView}
        width='700px'
      >
        <Typography component='h3' sx={{ fontSize: '18px', fontWeight: 600 }}>Resolução:</Typography>
        <div dangerouslySetInnerHTML={{ __html: question.resolution }} />
      </DialogModal>
      <DialogModal
        open={materialView}
        handleClose={handleMaterialView}
        width='700px'
      >
        <Box >
          <ParentContent id={question.id} />
        </Box>
      </DialogModal>

      <ModalDelete
        isOpen={openDeleteQuestionModal}
        closeModal={handleCloseDeleteQuestionModal}
        action={() => deleteQuestionById(question?.id)}
        type='question'
      />
    </>
  )
}

export default QuestionCard
