import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { fetchTeachersClassroomsFailure, fetchTeachersClassroomsRequest, fetchTeachersClassroomsSuccess } from './actions'
import { ClassRoomType } from '../../components/Home/ClassroomsList'

interface StateType {
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
  messageError: string | null
  data?: ClassRoomType[]
}
const initialState: StateType = {
  data: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  messageError: '',
}

export const teachersClassroomsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTeachersClassroomsRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      isSuccess: false,
      messageError: '',
    }))
    .addCase(fetchTeachersClassroomsSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      isSuccess: true,
      data: action.payload,

    }))
    .addCase(fetchTeachersClassroomsFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isSuccess: false,
      isError: true,
    }))

})

export default teachersClassroomsReducer
