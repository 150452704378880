/* eslint-disable camelcase */
import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import * as actions from './actions'
import { AnyAction } from 'redux'
import { FiltersType } from '../books/reducer'
import * as services from './services'
import axios, { AxiosResponse } from 'axios'
import { showAlertMessage } from '../alert'
import { ITeacherClassroom } from '../../models/IClassroom'
import { Chapter } from '../../models/IBooks'

function* fetchBooks({ payload }: AnyAction) {
  try {
    const filters: FiltersType = yield select((state) => state.books.filters)
    const { selectedUnit } = yield select(state => state.persistable)
    const params = {
      ...payload,
      ...filters.query,
      belongs_to_unit_id: selectedUnit?.id
    }
    const response: AxiosResponse = yield call(services.getBooks, { params })
    const { data, headers } = response
    const pagination = {
      totalOfPages: Number(headers['x-total-pages']),
      current: Number(headers['x-page']),
      total: Number(headers['x-total-count']),
      per: filters.query.per
    }
    yield put(actions.fetchBooksSuccess(data))
    yield put(actions.booksChangePagination(pagination))
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      yield put(actions.fetchBooksFailure(error.message))
    } else {
      throw Error(String(error))
    }
  }
}

function* createBook({ payload }: { payload: any }) {
  try {
    const { subjects, name, description, nextTab, coauthors, photo } = payload

    const { selectedUnit } = yield select(state => state.persistable)
    const fd = new FormData()
    fd.append('name', name)
    fd.append('description', description)
    subjects?.map((subject: any) => fd.append('subject_ids[]', subject?.id))
    coauthors?.map((coauthor: any) => fd.append('co_author_user_ids[]', coauthor?.id))
    fd.append('school_unit_id', selectedUnit?.id)
    fd.append('status', 'draft')

    if (photo && typeof photo === 'number') {  // add default cover
      fd.append('default_cover_id', String(photo))
    } else if (photo) {
      fd.append('photo', photo)
    }

    const response: AxiosResponse = yield call(services.createNewBook, fd)
    if (response.status === 201 || response.status === 200) {
      const book = { id: response.data.id }
      localStorage.setItem('book', JSON.stringify(book))
      yield put(actions.createBookSuccess())
      nextTab()
    }

  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      yield put(actions.createBookFailure(error.message))
    }

    yield put(showAlertMessage({
      message: 'Falha ao criar livro!',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* updateBook({ payload }: { payload: any }) {
  try {
    const {
      id,
      subjects,
      name,
      description,
      coauthors,
      nextTab,
      status,
      photo,
      changeSaveDraft,
      changeSaveBook,
      navigateTo
    } = payload
    const { selectedUnit } = yield select(state => state.persistable)
    const fd = new FormData()
    fd.append('name', name)
    fd.append('description', description)
    subjects?.map((subject: any) => fd.append('subject_ids[]', subject?.id))
    fd.append('school_unit_id', selectedUnit?.id)
    fd.append('status', status)

    if (coauthors && coauthors?.length > 0) {
      coauthors?.map((coauthor: any) => fd.append('co_author_user_ids[]', coauthor?.id))
    } else {
      // remove coauthors
      fd.append('co_author_user_ids[]', 'delete')
    }

    if (photo && typeof photo === 'number') {  // add default cover
      fd.append('default_cover_id', String(photo))
    } else if (photo && typeof photo !== 'string') {
      fd.append('photo', photo)
    }

    const response: AxiosResponse = yield call(services.updateNewBook, { id, data: fd })
    if (response.status === 200 || response.status === 204) {
      yield put(actions.updateBookSuccess())
      if (typeof nextTab === 'function') {
        yield put(actions.fetchBookByIdRequest({ id }))
        nextTab?.()
      }
      changeSaveDraft?.()
      changeSaveBook?.()
      navigateTo?.()
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.updateBookFailure(error.message))
    }

    yield put(showAlertMessage({
      message: 'Falha ao atualizar livro!',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* addBookCoauthor({ payload }: any) {
  try {
    const { filters } = yield select(state => state.books)
    const response: AxiosResponse = yield call(services.editBookCoauthor, payload)
    if (response.status === 200) {
      yield put(actions.addCoauthorSuccess())
      payload?.closeModal?.()
      yield put(showAlertMessage({
        message: 'Coautor adicionado com sucesso!',
        severity: 'success',
        duration: 5000
      }))

    yield put(actions.fetchBooksRequest({ ...filters.query }))
    }
  } catch (error: any) {
    yield put(actions.addCoauthorFailure(error.message))
    payload?.closeModal?.()
    yield put(showAlertMessage({
      message: 'Erro ao adicionar coautor.',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* fetchBookById({ payload }: { payload: { id: number } }) {
  try {
    const response: AxiosResponse = yield call(services.fetchBook, payload)
    if (response.status === 200) {
      yield put(actions.fetchBookByIdSuccess({ ...response.data }))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.fetchBookByIdFailure(error.message))
    }
    console.error(error)
  }
}

function* createChapter({ payload }: { payload: any }) {
  try {
    const { book_id, chapter_title, chapter_description, chapter_order, closeForm } = payload
    const { modulesSelected } = yield select(state => state.books)
    const modules = modulesSelected.map((module: { id: number, order: number, name: string }) => {
      return {
        id: module.id,
        module_type: 'exam',
        content_module_order: module.order,
        freebie: false
      }
    })

    const params = {
      number: chapter_order,
      name: chapter_title,
      description: chapter_description,
      chapter_order,
      book_book_id: book_id,
      modules,
      status: 'draft'
    }

    const response: AxiosResponse = yield call(services.createNewChapter, params)

    if (response.status === 201 || response.status === 200) {
      yield put(actions.createChapterSuccess())
      yield put(actions.fetchChaptersRequest({ bookId: book_id }))
      yield put(actions.clearModules())
      closeForm()
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.createBookFailure(error.message))
    }

    yield put(showAlertMessage({
      message: 'Falha ao criar capítulo!',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* fetchChapters({ payload }: { payload: any }) {
  try {
    const response: AxiosResponse = yield call(services.fetchNewChapter, { id: payload.bookId })
    if (response.status === 200) {
      const chapters = response.data.data?.chapters
      const chaptersOdered = chapters && chapters.sort((a: Chapter, b: Chapter) => {
        return a.chapter_order - b.chapter_order
      })
      yield put(actions.fetchChaptersSuccess(chaptersOdered))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.fetchChaptersFailure(error.message))
    }
    console.error(error)
  }
}

function* updateChapter({ payload }: { payload: any }) {
  try {
    const { modulesSelected } = yield select(state => state.books)
    const params = {
      number: payload?.order,
      name: payload?.name,
      description: payload?.description,
      chapter_order: payload?.order,
      book_book_id: payload?.bookId,
      modules: modulesSelected.map((module: any, index: number) => ({
        id: module?.id,
        module_type: 'exam',
        content_module_order: index + 1,
        freebie: 'no'
      }))
    }
    const response: AxiosResponse = yield call(services.editChapter, {
      params,
      id: payload?.id
    })

    if (response.status === 200) {
      yield put(actions.updateChapterSuccess())
      yield put(actions.fetchChaptersRequest({ bookId: payload?.bookId }))
      yield put(actions.clearModules())
      payload.closeEditForm()
    }

  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.updateChapterFailure(error.message))
    }

    yield put(showAlertMessage({
      message: 'Falha ao atualizar capítulo!',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* deleteChapter({ payload }: { payload: any }) {
  try {
    const { currentItem: { item } } = yield select(state => state.books)
    const response: AxiosResponse = yield call(services.deleteChapterById, payload)
    if (response.status === 200 || response.status === 204) {
      yield put(actions.deleteChapterSuccess())
      yield put(actions.fetchChaptersRequest({ bookId: item?.id }))
      yield put(showAlertMessage({
        message: 'Capítulo deletado com sucesso!',
        severity: 'error',
        duration: 5000
      }))
    }
  } catch (error) {
    yield put(showAlertMessage({
      message: 'Falha ao deletar capítulo!',
      severity: 'error',
      duration: 5000
    }))

    if (axios.isAxiosError(error)) {
      yield put(actions.deleteChapterFailure(error.message))
    }
    console.error(error)
  }
}

function* handleChangeChapterOrder({ payload }: { payload: any }) {
  try {
    const response: AxiosResponse[] = yield all(payload?.map((chapter: Chapter, index: number) => {
      const params = {
        chapter_order: index + 1,
        number: String(index + 1)
      }
      return call(services.changeChapterOrder, {
        id: chapter.id,
        params
      })
    }))

    const newResponse = response.map((item: any) => item.data)
    yield put(actions.changeChapterOrderSuccess(newResponse))
  } catch (error) {
    console.error(error)
    if (axios.isAxiosError(error)) {
      yield put(actions.changeChapterOrderFailure(error.message))
    }

    yield put(showAlertMessage({
      message: 'Falha ao ordenar capítulo!',
      severity: 'error',
      duration: 5000
    }))
  }
}

function* deleteBook({ payload }: { payload: any }) {
  try {
    const { filters } = yield select(state => state.books)
    const response: AxiosResponse = yield call(services.deleteteBookById, payload)
    if (response.status === 200 || response.status === 204) {
      yield put(actions.deleteBookSuccess())
      yield put(showAlertMessage({
        message: 'Livro deletado com sucesso!',
        severity: 'success',
        duration: 5000
      }))
      yield put(actions.fetchBooksRequest({ per: filters?.query?.per, page: filters?.query?.page }))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.deleteBookFailure(error.message))
    }

    yield put(showAlertMessage({
      message: 'Falha ao deletar livro!',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* fetchClassrooms({ payload }: { payload: any }) {
  try {
    const { school_unit_ids } = payload
    const response: AxiosResponse[] = yield all(school_unit_ids.map((school: any) => {
      const params = {
        school_unit_id: school
      }
      return call(services.getClassrooms, params)
    }))

    const newResponse = response.map((item: any) => item.data.data)
    const data = newResponse.reduce((acc: any, item: ITeacherClassroom[], index: number) => {
      acc[school_unit_ids[index]] = item
      return acc
    }, {})

    const allClassrooms = Object.values(data).flatMap((item) => item)
    const newData = {
      ...data,
      all: allClassrooms
    }
    yield put(actions.fetchClassroomsSuccess(newData))
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.fetchClassroomsFailure(error.message))
    }
    console.error(error)
  }
}

function* addClassrooms({ payload }: { payload: any }) {
  try {
    const params = {
      id: payload.id,
      school_classroom_ids: payload.school_classroom_ids,
      modules: payload.modules
    }

    const response: AxiosResponse = yield call(services.addClassroomsOnBook, params)
    if (response.status === 200 || response.status === 201) {
      yield put(actions.addClassroomsSuccess())
      yield put(actions.fetchBookPublishedRequest({ id: payload.id }))
      payload?.nextTab?.()
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.addClassroomsFailure(error.message))
    }
    yield put(showAlertMessage({
      message: 'Falha ao destinar livro!',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* changeClassrooms({ payload }: { payload: any }) {
  try {
    const params = {
      id: payload.id,
      school_classroom_ids: payload.school_classroom_ids
    }

    const response: AxiosResponse = yield call(services.changeClassroomsOnBook, params)
    if (response.status === 200 || response.status === 201) {
      yield put(actions.changeClassroomsSuccess())
      yield put(actions.fetchBookPublishedRequest({ id: payload.id }))
      payload?.nextTab?.()
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.changeClassroomsFailure(error.message))
    }
    yield put(showAlertMessage({
      message: 'Falha ao destinar livro!',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* fetchBookPublished({ payload }: { payload: any }) {
  try {
    const response: AxiosResponse = yield call(services.getBookPublished, payload)
    if (response.status === 200) {
      const result = {
        ...response.data,
        chapters: response.data?.chapters.sort((a: any, b: any) => a?.chapter_order - b?.chapter_order)
      }
      yield put(actions.fetchBookPublishedSuccess(result))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.fetchBookPublishedFailure(error.message))
    }
    console.error(error)
  }
}

function* cloneBook({ payload }: { payload: { id: number } }) {
  try {
    // get book to clone
    const { selectedUnit } = yield select(state => state.persistable)
    const response: AxiosResponse = yield call(services.fetchNewChapter, { id: payload.id })

    if (response.status === 200) {
      const book = {
        name: response.data?.data?.title,
        description: response.data?.data?.description,
        subject_ids: response.data?.data?.subjects?.map((subject: any) => subject.id),
        status: 'draft',
        school_unit_id: selectedUnit?.id
      }
      const chapters = response.data?.data?.chapters

      // cloning book
      try {
        const bookResponse: AxiosResponse = yield call(services.cloneBook, book)
        if (bookResponse.status === 200 || bookResponse.status === 201) {
          // cloning chapters
          try {
            const chaptersResponse: AxiosResponse[] = yield all(chapters?.map((chapter: any) => {
              const params = {
                book_book_id: bookResponse.data?.id,
                name: chapter?.name,
                description: chapter?.description,
                chapter_order: chapter?.chapter_order,
                number: chapter?.number,
                modules: chapter?.modules?.map((module: any, index: number) => ({
                  id: module?.id,
                  content_module_order: index + 1,
                  freebie: false,
                  module_type: 'exam'
                }))
              }

              return call(services.cloneChapters, params)
            }))

            const isSuccess = chaptersResponse.some((chapter) => (chapter.status === 200 || chapter.status === 201))
            if (isSuccess) {
              yield put(actions.cloneBookSuccess({ bookId: bookResponse.data?.id }))
            }
          } catch (error) {
            yield put(actions.cloneBookSuccess({ bookId: bookResponse.data?.id }))
            yield put(showAlertMessage({
              message: 'Falha ao clonar os capítulos do livro!',
              severity: 'error',
              duration: 5000
            }))
            console.error(error)
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          yield put(actions.cloneBookFailure(error.message))
        }
        yield put(showAlertMessage({
          message: 'Falha ao clonar livro!',
          severity: 'error',
          duration: 5000
        }))
        console.error(error)
      }

    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.cloneBookFailure(error.message))
    }
    yield put(showAlertMessage({
      message: 'Falha ao clonar livro!',
      severity: 'error',
      duration: 5000
    }))
    console.error(error)
  }
}

function* getTeachersWithSubjects({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(services.getTeacherWithSubjects, payload)
    if (response.status === 200) {
      yield put(actions.getTeachersWithSubjectsSuccess(response.data))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.getTeachersWithSubjectsFailure(error.message))
    }
    console.error(error)
  }
}

function* updateBookStatus({ payload }: any) {
  try {
    const { selectedUnit } = yield select(state => state.persistable)
    const params = {
      name: payload?.name,
      description: payload?.description,
      subject_ids: payload?.subjects?.map((subject: { id: number, name: string}) => subject?.id),
      co_author_user_ids: payload?.coauthors?.map((coauthor: any) => coauthor?.user?.id),
      status: payload?.status,
      school_unit_id: selectedUnit?.id
    }

    const newResponse: AxiosResponse = yield call(services.changeStatusBook,
      { id: payload?.id, params }
    )

    if (newResponse.status === 200) {
      yield put(actions.updateBookStatusSuccess())
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.updateBookStatusFailure(error.message))
    }
    console.error(error)
  }
}

function* publishBook({ payload }: any) {
  try {
    const response: AxiosResponse[] = yield all(payload?.dates?.map((item: any) => {
      const params = {
        classroom_chapters: item?.classroom_chapters
      }
      return call(services.publishBook, { id: item.chapterId, params})
    }))

    const isSuccess = response?.some((item) => (item.status === 200 || item.status === 201))
    if (isSuccess) {
      yield put(actions.publishBookSuccess())
      yield put(actions.updateBookStatusRequest(payload?.bookData))
    }

  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.publishBookFailure(error.message))
    }
    console.error(error)
  }
}

function* fetchClassroomsPerformance({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(services.getClassroomsPerformance, { id: payload.id })
    if (response.status === 200) {
      yield put(actions.classroomsPerformanceSuccess(response.data))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.classroomsPerformanceFailure(error.message))
    }
    console.error(error)
  }
}

function* getCoverBooks(){
  try {
    const response: AxiosResponse = yield call(services.getCovers)
    if (response.status === 200) {
      yield put(actions.getCoverBooksSuccess(response.data))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.getCoverBooksFailure(error.message))
    }
    console.error(error)
  }
}

function* watchFetchBooks() {
  yield takeLatest(actions.fetchBooksRequest.type, fetchBooks)
}

function* watchCreateBook() {
  yield takeLatest(actions.createBookRequest.type as any, createBook)
}

function* watchUpdateBook() {
  yield takeLatest(actions.updateBookRequest.type as any, updateBook)
}

function* watchFetchBookById() {
  yield takeLatest(actions.fetchBookByIdRequest.type as any, fetchBookById)
}

function* watchCreateChapter() {
  yield takeLatest(actions.createChapterRequest.type as any, createChapter)
}

function* watchFetchChapters() {
  yield takeLatest(actions.fetchChaptersRequest.type as any, fetchChapters)
}

function* watchUpdateChapter() {
  yield takeLatest(actions.updateChapterRequest.type as any, updateChapter)
}

function* watchDeleteChapter() {
  yield takeLatest(actions.deleteChapterRequest.type as any, deleteChapter)
}

function* watchChangeChapterOrder() {
  yield takeLatest(actions.changeChapterOrderRequest.type as any, handleChangeChapterOrder)
}

function* watchDeleteBook() {
  yield takeLatest(actions.deleteBookRequest.type as any, deleteBook)
}

function* watchFetchClassrooms() {
  yield takeLatest(actions.fetchClassroomsRequest.type as any, fetchClassrooms)
}

function* watchAddClassroom() {
  yield takeLatest(actions.addClassroomsRequest.type as any, addClassrooms)
}

function* watchChangeClassroom() {
  yield takeLatest(actions.changeClassroomsRequest.type as any, changeClassrooms)
}

function* watchFetchBookPublished() {
  yield takeLatest(actions.fetchBookPublishedRequest.type as any, fetchBookPublished)
}

function* watchCloneBook() {
  yield takeLatest(actions.cloneBookRequest.type as any, cloneBook)
}

function* watchEditBookCoauthor() {
  yield takeLatest(actions.addCoauthorRequest.type as any, addBookCoauthor)
}

function* watchGetTeachersWithSubjects() {
  yield takeLatest(actions.getTeachersWithSubjectsRequest.type as any, getTeachersWithSubjects)
}

function* watchUpdateBookStatus() {
  yield takeLatest(actions.updateBookStatusRequest.type as any, updateBookStatus)
}

function* watchPublishBook() {
  yield takeLatest(actions.publishBookRequest.type as any, publishBook)
}

function* watchFetchClassroomsPerformance() {
  yield takeLatest(actions.classroomsPerformanceRequest.type as any, fetchClassroomsPerformance)
}

function* watchGetCoverBooks() {
  yield takeLatest(actions.getCoverBooksRequest.type as any, getCoverBooks)
}

export default function* booksSagas() {
  yield all([
    watchFetchBooks(),
    watchCreateBook(),
    watchUpdateBook(),
    watchFetchBookById(),
    watchCreateChapter(),
    watchFetchChapters(),
    watchUpdateChapter(),
    watchDeleteChapter(),
    watchChangeChapterOrder(),
    watchDeleteBook(),
    watchFetchClassrooms(),
    watchAddClassroom(),
    watchChangeClassroom(),
    watchFetchBookPublished(),
    watchCloneBook(),
    watchEditBookCoauthor(),
    watchGetTeachersWithSubjects(),
    watchPublishBook(),
    watchUpdateBookStatus(),
    watchFetchClassroomsPerformance(),
    watchGetCoverBooks()
  ])
}
