import React from 'react'
import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'

const LoadingModalCoauthor = () => {
  return (
    <Box>
      <Skeleton
        variant='rounded'
        animation='wave'
        sx={{
          height: '30px',
          width: '55%',
          borderRadius: '8px',
          marginBottom: '32px'
        }}
      />
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'flex-start'
            }}
          >
            <Box
              width='100%'
            >
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{
                  height: '30px',
                  width: '200px',
                  borderRadius: '8px'
                }}
              />
            </Box>
            <Box
              width='100%'
            >
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{
                  height: '30px',
                  width: '200px',
                  borderRadius: '8px'
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'flex-start'
              }}
            >
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'flex-start'
              }}
            >
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'flex-start'
              }}
            >
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'flex-start'
              }}
            >
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'flex-start'
              }}
            >
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'flex-start'
              }}
            >
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
              <Box
                width='100%'
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  sx={{
                    height: '40px',
                    width: '65%',
                    borderRadius: '8px'
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LoadingModalCoauthor
