/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box, Card, Skeleton, Grid, Pagination } from '@mui/material'
import { ReactComponent as BookSVG } from '../assets/components/Books/book-icon.svg'
import { ReactComponent as CreatedSVG } from '../assets/components/Books/created-icon.svg'
import { ReactComponent as PublishedSVG } from '../assets/components/Books/published-icon.svg'
import { ReactComponent as SketchSVG } from '../assets/components/Books/sketch-icon.svg'
import { ReactComponent as AuthorSVG } from '../assets/components/Books/authors-icon.svg'
import { ReactComponent as ExportSVG } from '../assets/components/Books/export-icon.svg'

import { RootState } from '../store/configureStore'
import {
  clearSelectedQuestions,
  clearQuestionDetails,
  questionDetailsFetchRequest
} from '../store/questions/actions'

import {
  activitiesOverviewFetchRequest,
  activityItemByIdFetchRequest,
  clearActivityItem
} from '../store/activities/actions'
import Label from '../components/label/Label'
import { DefaultOptionType } from '../utils/types'
import { styled } from '@mui/system'
import BooksCreatorsRankingView from '../components/view/BooksCreatorsRankingView'
import BooksCoauthorsView from '../components/view/BookCoauthorsView'
import BooksStudentsPerformanceView from '../components/view/BookStudentsPerformanceView'
import BooksCatalogView from '../components/view/BooksCatalogView'
import BooksMoreUsedView from '../components/view/BooksMoreUsedView'
import BooksLeastUsedView from '../components/view/BooksLeastUsedView'
import Button from '../components/button/Button'
import ActivityStudentsView from '../components/view/ActivityStudentsView'
import { IQuestion } from '../models/IQuestion'
import { IActivities } from '../models/IActivities'
import { activityRankingReportFetchRequest, activityRankingReset } from '../store/reports'
import { scrollToTop } from '../utils/functions'


const SelectsContainer = styled('div')({
  display: 'flex',
  gap: 48,
  marginTop: 32,
  '& .MuiBox-root': {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 16
  },
  '& > div > .MuiFormControl-root > .MuiOutlinedInput-root': {
    backgroundColor: '#FFF',
    maxWidth: '381px'
  },
  '& .MuiTypography-body2': {
    color: '#666'
  }
})

const InfoCardContainer = styled('div')(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.secondary}`,
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  marginTop: 32,
  gap: 8,
  '& > div': {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    gap: 32
  }
}))

const InfoCard = styled(Card)(({ theme }) => ({
  borderRadius: 8,
  height: 201,
  paddingTop: 28,
  textAlign: 'center',
  width: 247,
  '& p': {
    fontSize: 14,
    '& span': {
      color: theme.palette.primary.light,
      fontWeight: 800
    }
  }
}))

const HeaderReports = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  columnGap: '32px',
  '.createReports': {
    justifySelf: 'end',
    'button' : {
       backgroundColor: '#BC9BFA',
       'svg':{
        marginRight: '8px'
       }
    }
  },
}))

export const normalizeClassroomsAndStudents = (participants: any[]) => {
  const obj: any = {}
  const classrooms: Array<any> = []
  participants.forEach(elem => {
    !classrooms.find(classroom => classroom.id === elem.school_classroom.id) && classrooms.push(elem.school_classroom)
    if (obj[elem.school_classroom.unit.name]) {
      if (obj[elem.school_classroom.unit.name][elem.school_classroom.name]) {
        obj[elem.school_classroom.unit.name][elem.school_classroom.name].push(
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        )
      } else {
        obj[elem.school_classroom.unit.name][elem.school_classroom.name] = [
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        ]
      }
    } else {
      obj[elem.school_classroom.unit.name] = {
        [elem.school_classroom.name]: [
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        ]
      }
    }
  })
  return Object.keys(obj).map(unitName => {
    return ({
      unit_name: unitName,
      classrooms: Object.keys(obj[unitName]).map(classroomName => {
        return ({
          name: classroomName,
          id: classrooms.find(classroom => classroomName === classroom.name)?.id,
          students: obj[unitName][classroomName]
        })
      })
    })
  })
}

const ActivityItemContainer = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const [searchParam, setSearchParams] = useSearchParams()
  const { activities: { overview, item, isFetching, participantStatus } } = useSelector((state: RootState) => state)

  const classroomWithStudents = item.participants && normalizeClassroomsAndStudents(item.participants)
  const classrooms: Array<any> = classroomWithStudents ? classroomWithStudents.map(classroomUnit => classroomUnit.classrooms.map(classroom => classroom)).flat() : []
  // const classroomsOrdered = classrooms.sort((a: any, b: any) => a.id - b.id)
  const classroomOptions: Array<DefaultOptionType> = classrooms ? classrooms.map(classroom => {
    return ({ label: classroom.name, value: classroom.id })
  }).flat() : []
  const [selectedClassroom, setSelectedClassroom] = useState<any>(classroomOptions[0]?.value)
  const currentClassroom = selectedClassroom || classroomOptions[0]

  const StatusFinished = participantStatus && participantStatus?.status?.finished
  const StatusPending = participantStatus && participantStatus?.status?.pending
  const StatusInProgress = participantStatus && participantStatus?.status?.in_progress
  const needFeedback = StatusFinished && (
    participantStatus?.status?.need_feedback?.length !== undefined
      ? participantStatus?.status?.need_feedback?.length * StatusFinished
      : 0
  )

  const classroomList = classrooms.map(classroom => {
    return classroom.id
  })

  const discursiveQuestionsIds = participantStatus && participantStatus?.status?.need_feedback?.questions

  const classroomId = currentClassroom && classroomOptions[0]?.value

  const modules = item.modules
  let questions: IQuestion[] | undefined = []
  questions = modules?.flatMap((moduleWrapper: any) => {
    return moduleWrapper.module?.content_module_items.map((item: IActivities) => item.content?.question)
  })

  const questionDetailsData: any = {
    id: params.id,
    classroomIds: String(classroomList),
  }

  const studentActivitiesData: any = {
    activityId: params.id,
    classroomId: classroomList
  }

  const payloadRanking: any = {
    activityId: params?.id,
    per: 1000000,
    page: 1
  }

  useEffect(() => {
    scrollToTop()
    params?.id && dispatch(activityItemByIdFetchRequest(params.id))
    params?.id && dispatch(activityRankingReportFetchRequest(payloadRanking))
    return () => {
      setSelectedClassroom(null)
      dispatch(clearActivityItem())
      dispatch(clearQuestionDetails())
      dispatch(clearSelectedQuestions())
      dispatch(activityRankingReset())
      localStorage.removeItem('classroom')
    }
  }, [params?.id])

  useEffect(() => {
    classroomId && classroomList && dispatch(questionDetailsFetchRequest(questionDetailsData))
    classroomId && dispatch(activitiesOverviewFetchRequest(studentActivitiesData))
  }, [classroomId])


  const handleChangePage = () => {
    scrollToTop()
  }

  const renderInfoPage = (<>
    <InfoCardContainer>
      <div>
        {isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <CreatedSVG />
            <p><span>{StatusFinished}</span> Livros criados</p>
          </InfoCard>
        )
        }
        {isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <PublishedSVG />
            <p><span>{StatusInProgress}</span> Livros publicados</p>
          </InfoCard>
        )
        }
        {isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <SketchSVG />
            <p><span>{StatusPending}</span> Rascunho(s)</p>
          </InfoCard>

        )
        }
        {
          <InfoCard>
            <AuthorSVG />
            <p><span>{String(needFeedback).padStart(2, '0')}</span> Autores</p>
          </InfoCard>
        }
      </div>
      {isFetching ? (<Skeleton sx={{ mt: '16px' }} animation='wave' height={30} width={'300px'} />) : <div style={{ cursor: 'pointer' }} onClick={() => setSearchParams({ info: 'students' })}>
      </div>}
    </InfoCardContainer>

    <SelectsContainer />
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{ width: '50%' }}>
        <BooksMoreUsedView
            classroomId={currentClassroom?.value}
            participants={item?.participants}
            overview={overview?.participants}
            activityType={item?.activity_type}
            activityValue={item?.value}
            isFetching={isFetching}
          />
      </Grid>
      <Grid item xs={12} md={6} sx={{ width: '50%' }}>
        <BooksLeastUsedView
            classroomId={currentClassroom?.value}
            participants={item?.participants}
            overview={overview?.participants}
            activityType={item?.activity_type}
            activityValue={item?.value}
            isFetching={isFetching}
          />
      </Grid>
   </Grid>

    <BooksCreatorsRankingView
      classroomId={currentClassroom?.value}
      participants={item?.participants}
      overview={overview?.participants}
      activityType={item?.activity_type}
      activityValue={item?.value}
      isFetching={isFetching}
    />

    <BooksCoauthorsView
      classroomId={currentClassroom?.value}
      participants={item?.participants}
      overview={overview?.participants}
      activityType={item?.activity_type}
      activityValue={item?.value}
      isFetching={isFetching}
    />

    <BooksStudentsPerformanceView
      classroomId={currentClassroom?.value}
      participants={item?.participants}
      overview={overview?.participants}
      activityType={item?.activity_type}
      activityValue={item?.value}
      isFetching={isFetching}
    />

    <BooksCatalogView
      activityType={item?.activity_type}
      activityValue={item?.value}
      isFetching={isFetching}
    />
  </>)

  return (
    <div>
      <HeaderReports>
        <Label sx={{
          display: 'flex'
        }}>
          <BookSVG /> Visão geral livros
        </Label>
        <div className='createReports'>
          <Button variant='contained'>
            <ExportSVG /> Exportar relatório completo
          </Button>
        </div>
      </HeaderReports>
      {searchParam.get('info') !== 'students' &&
        searchParam.get('info') !== 'finished' && searchParam.get('info') !== 'students_classroom' && renderInfoPage}
      {searchParam.get('info') === 'students' && (
        <ActivityStudentsView
          questions={questions}
          overview={overview?.participants}
          participants={item.participants}
          activityType={item?.activity_type}
          activityValue={item?.value}
          discursiveQuestionsIds={discursiveQuestionsIds}
          isFetching={isFetching}
        />
      )}
       <Box sx={{ marginTop: '32px' }}>
          <Pagination
            count={5}
            size='small'
            color='primary'
            page={1}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          />
        </Box>
    </div>
  )
}

export default ActivityItemContainer
