import React from 'react'

import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import SelectInput from '../../components/select/SelectInput'
import { ReactComponent as NotebookSVG } from '../../assets/components/Books/notebook-icon.svg'
import { ReactComponent as SchoolSVG } from '../../assets/components/Books/school-icon.svg'
import CatalogTable, { Columns } from '../table/CatalogTable'
import { ReactComponent as PerformanceSVG } from '../../assets/components/activity/icon-performance.svg'

const paperStyled = {
  borderRadius: 4,
  mt: 4,
  p: 3,
  '& .MuiTable-root': {
    minWidth: '300px'
  },
  '& td': {
    p: 1,
    '&:first-of-type': {
      pl: 2
    },
    '&:last-of-type': {
      pr: 2
    }
  },
  '& span': {
    color: '#04AEC9',
    fontWeight: '500'
  },
  '& .red span': {
    color: '#F69E9E'
  }

}

const SelectBox = styled('div')(() => ({
  '& .MuiSelect-select' : {
     paddingLeft: '8px',
     textAlign: 'left'
  }
 }))

interface IStudentsPerformanceViewProps {
  activityValue?: number | string | null
  activityType?: string
  isFetching?: boolean
}

const BookStudentsPerformanceView: React.FC<IStudentsPerformanceViewProps> = ({
  activityValue,
  activityType,
  isFetching
}) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [seachParam, setSearchParams] = useSearchParams()

  const getColumns = (isLower: boolean) => {
    const columns: Columns[] = [
      {
        key: 'titulo',
        name: 'Título',
        render: (row: any) => {
            return <Box display='flex' flexDirection='column' >
            <Typography variant='subtitle1' color='#666' fontWeight={600}>{row.name}</Typography>
          </Box>
        }
      },
      {
        key: 'escola',
        name: 'Escola',
        render: (row: any) => {
          return <Box display='flex' flexDirection='column' >
            <Typography variant='subtitle1' color='#666'>{row.school_unit.name}</Typography>
          </Box>
        }
      },
      {
        key: 'autor',
        name: 'Autor',
        render: (row: any) => {
          return <Box display='flex' flexDirection='column' >
            <p style={{ margin: '0' }}>{row.authors.name}</p>
          </Box>
        }
      },
      {
        key: 'disciplina',
        name: 'Disciplina',
        width: '150px',
        render: (row: any) => {
          return activityType === 'exam'
            ? (
              <Box
                alignItems='center'
                display='flex'
                justifyContent='flex-end'
                gap='2px'
              >
                <Typography
                  color={isLower ? 'error' : 'secondary'}
                  fontSize={16}
                  fontWeight={500}
                >
                  {0}
                </Typography>
                <span style={{ color: 'currentcolor', fontWeight: 500 }}>/</span>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  {activityValue}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', width: '100%' }}>
                <p style={{ margin: '0' }}>{row.subjects.name}</p>
              </Box>
            )
        }
      },
      {
        key: 'acessos',
        name: 'Acessos',
        width: '150px',
        render: (row: any) => {
            return activityType === 'exam'
              ? (
                <Box
                  alignItems='center'
                  display='flex'
                  justifyContent='flex-end'
                  gap='2px'
                >
                  <Typography
                    color={isLower ? 'error' : 'secondary'}
                    fontSize={16}
                    fontWeight={500}
                  >
                    {row.id}
                  </Typography>
                  <span style={{ color: 'currentcolor', fontWeight: 500 }}>/</span>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500
                    }}
                  >
                    {activityValue}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', width: '100%', justifyContent: 'flex-end' }}>
                  <Typography sx={{ width: '100%' }}>{'000'}</Typography>
                </Box>
              )
          }
      }
    ]

    return columns
  }

    const book = [
      {
        'id': 566022,
        'name': 'Livro Física',
        'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed dignissim metus, in aliquet orci....',
        'status': 'ready_to_publish',
        'photo_url': '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/022/original/image_%287%29.png?1720014010',
        'authors': 
          {
              'id': 278944,
              'name': 'Professor N P Marcio'
          }
        ,
        'subjects': 
          {
              'id': 5,
              'name': 'Física'
          }
        ,
        'school_unit': {
          'id': 4563465,
          'name': 'Bertoni'
        }
      },
      {
        'id': 566022,
        'name': 'Livro Física',
        'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed dignissim metus, in aliquet orci....',
        'status': 'ready_to_publish',
        'photo_url': '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/022/original/image_%287%29.png?1720014010',
        'authors': 
          {
              'id': 278944,
              'name': 'Professor N P Marcio'
          }
        ,
        'subjects': 
          {
              'id': 5,
              'name': 'Física'
          }
        ,
        'school_unit': {
          'id': 4563465,
          'name': 'Bertoni'
        }
      }
    ]


  return (
    <Paper sx={paperStyled}>

      {
        isFetching ? (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Skeleton animation='wave' height={50} width={100} />
              <Skeleton animation='wave' height={50} width={100} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '32px'
              }}
            >
              <Box width={'100%'}>
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />

              </Box>
              <Box width={'100%'}>
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
              </Box>

            </Box>
          </Box>
        ) : (
          <>
            <Box display='flex' justifyContent='space-between' mb={2}>
              <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
                <PerformanceSVG style={{ marginRight: 16 }} />
                Catálogo de Livros Disponíveis
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                        <NotebookSVG />
                        <SelectInput
                            border='none'
                            defaultValue='geral'
                            name='selectStatus'
                            sx={{ width: '140px', textAlign: 'center' }}
                            onChange={''}
                            options={[
                                { label: 'Disciplina:', value: 'geral' },
                            ]}
                            />
                    </SelectBox>
                    <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                        <SchoolSVG />
                        <SelectInput
                            border='none'
                            defaultValue='geral'
                            name='selectStatus'
                            sx={{ width: '100px', textAlign: 'center' }}
                            onChange={''}
                            options={[
                                { label: 'Escola:', value: 'geral' },
                            ]}
                            />
                    </SelectBox>
                    <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                        <SchoolSVG />
                        <SelectInput
                            border='none'
                            defaultValue='geral'
                            name='selectStatus'
                            sx={{ width: '100px', textAlign: 'center' }}
                            onChange={''}
                            options={[
                                { label: 'Autor:', value: 'geral' },
                            ]}
                            />
                    </SelectBox>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ width: 520 }}>
                <CatalogTable
                  columns={getColumns(true)}
                  data={book || []}
                  withoutHead
                />
              </Grid>
            </Grid>
          </>
        )
      }
    </Paper>
  )
}

export default BookStudentsPerformanceView
