// React
import React, { useEffect, useRef, useState } from 'react'

// Components
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material'
import BookStudentCard from '../card/BookStudentCard'
import ChapterStudentCollapse from '../collapse/ChapterStudentCollapse'
import SimpleSelect from '../../select/SimpleSelect'

// Utils
import { styled } from '@mui/material/styles'
import { formatTime, stringAvatar } from '../../../utils/functions'
import { IBookDetails, IStudentDetails, bookDetails } from '../../../containers/data.mockup'

// Icons
import ChartIcon from '../../../assets/components/Books/chart-icon.svg'
import ClockIcon from '../../../assets/components/Books/clock-icon.svg'
import NewCheckIcon from '../../../assets/components/Books/new-check-icon.svg'
import ProgressIcon from '../../../assets/components/Books/progress-dark-icon.svg'

const BoxFilter = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.14px'
  }
}))

const RenderStudentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .datasheet': {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',

    '&_description': {
      '& p:first-of-type': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0.18px',
        marginBottom: '4px'
      },

      '& p:last-of-type': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.14px'
      }
    }
  },

  '& .info': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    marginTop: '16px',

    '&_activities, &_time': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px',
      '& span': {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',

        '&:first-of-type': {
          color: '#9f9f9f'
        },

        '&:last-of-type': {
          color: theme.palette.text.primary
        }
      }
    }
  },

  [theme.breakpoints.down('lg')]: {
    '& .info': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }
}))

const Divider = styled(Box)(({ theme }) => ({
  borderBottom: `1px dashed ${theme.palette.text.secondary}`,
  marginBlock: '32px',
  marginInline: '-16px'
}))

const BooksContainer = styled(Box)(({ theme }) => ({
  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '0.18px',
    marginBottom: '24px'
  },

  '& .filters': {
    marginBottom: '16px',
    '&_cell:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },

  '& .books': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },

  '& .book_stats': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    '&-title': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.18px',
      marginBottom: '8px'
    },

    '&-progress': {
      alignItems: 'center',
      display: 'flex',

      '& img': {
        marginRight: '8px'
      },

      '& p': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        lineHeight: '120%',
        marginRight: '16px'
      },

      '& p:first-of-type': {
        fontWeight: 600
      },

      '& p:last-of-type': {
        fontWeight: 500
      },

      '& .linear_progress': {
        marginRight: '16px',
        maxWidth: '250px',
        width: '100%',
      }
    },

    '&-time': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& p': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '120%'
      },

      '& .time_box': {
        background: theme.palette.primary.light,
        borderRadius: '8px',
        color: '#fff',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',
        marginLeft: '8px',
        padding: '5.5px 18px'
      }
    },

    '&-performance': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& p': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '120%'
      },

      '& .performance': {
        background: theme.palette.secondary.main,
        borderRadius: '8px',
        color: '#f9f9f9',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '120%',
        padding: '5.5px 18px',
      }
    }
  },

  '& .chapters_stats': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '16px'
  }
}))

interface IStudentDetailProps {
  studentDetail: IStudentDetails
}

const RenderStudent: React.FC<IStudentDetailProps> = ({ studentDetail }) => {
  const [bookSelected, setBookSelected] = useState<number | null>(null)
  const [book, setBook] = useState({} as IBookDetails)
  const bookRef = useRef<HTMLDivElement | null>(null)

  const handleSelectBook = (id: number) => {
    setBookSelected(id)
    const result = bookDetails.find(book => book.id === id)
    result && setBook(result)
    setTimeout(() => {
      bookRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }, 400)
  }

  useEffect(() => {
    return () => {
      setBookSelected(null)
    }
  }, [studentDetail.id])

  return (
    <RenderStudentContainer>
      <Box className='datasheet'>
        <Avatar
          alt={studentDetail.name}
          className='datasheet_avatar'
          variant='rounded'
          {...stringAvatar(studentDetail.name, 58, 58, 20)}
        />

        <Box className='datasheet_description'>
          <Typography>{studentDetail.name}</Typography>
          <Typography>{studentDetail.grade.name} / {studentDetail.school_unit.name}</Typography>
        </Box>
      </Box>

      <Box className='info'>
        <Box className='info_activities'>
          <img src={NewCheckIcon} />
          <Box component='span'>Atividades Finalizadas:</Box>
          <Box component='span'>
            {studentDetail.activities_finished} / {studentDetail.number_of_activities}
          </Box>
        </Box>

        <Box className='info_time'>
          <img src={ClockIcon} />
          <Box component='span'>Tempo no(s) livro(s):</Box>
          <Box component='span'>
            {formatTime(studentDetail.time_on_books)}
          </Box>
        </Box>
      </Box>

      <Divider />

      <BooksContainer>
        <Typography
          component='h3'
          className='title'
        >
          Selecione o livro que deseja visualizar
        </Typography>

        <Grid
          container
          columnSpacing={5}
          rowSpacing={2}
          className='filters'
        >
          <Grid item xs={12} lg={6} className='filters_cell'>
            <BoxFilter>
              <label htmlFor='subject'>Disciplina</label>
              <SimpleSelect
                className='subject'
                placeholder='Selecione...'
                hasBackground
                hasBorder
                maxWidth='100%'
              />
            </BoxFilter>
          </Grid>

          <Grid item xs={12} lg={6} className='filters_cell'>
            <BoxFilter>
              <label htmlFor='book'>Livro</label>
              <SimpleSelect
                className='book'
                placeholder='Selecione...'
                hasBackground
                hasBorder
                maxWidth='100%'
              />
            </BoxFilter>
          </Grid>

          <Grid item xs={12} className='filters_cell'>
            <BoxFilter>
              <label htmlFor='order'>Ordernar por:</label>
              <SimpleSelect
                className='order'
                placeholder='Selecione...'
                hasBackground
                hasBorder
                maxWidth='132px'
              />
            </BoxFilter>
          </Grid>
        </Grid>

        <Box className='books'>
          {studentDetail.books.map((book) => {
            return (
              <BookStudentCard
                key={book.id}
                book={book}
                selected={bookSelected === book.id}
                handleSelectBook={handleSelectBook}
              />
            )
          })}
        </Box>

        <Collapse in={Boolean(bookSelected)}>
          <Box>
            <Divider />

            <Box className='book_stats'>
              <Typography
                component='h3'
                className='book_stats-title'
              >
                Resultados
              </Typography>

              <Box className='book_stats-progress'>
                <img src={ProgressIcon} />
                <Typography>Progresso</Typography>
                <LinearProgress
                  className='linear_progress'
                  color={book?.progress >= 50 ? 'secondary' : 'error'}
                  value={book?.progress}
                  variant='determinate'
                />
                <Typography>{book?.progress}%</Typography>
              </Box>

              <Box className='book_stats-time'>
                <img src={ClockIcon} />
                <Typography>Tempo de leitura</Typography>
                <Box className='time_box'>{formatTime(book.time)}</Box>
              </Box>

              <Box className='book_stats-performance'>
                <img src={ChartIcon} />
                <Typography>Desempenho</Typography>
                <Box className='performance'>{book.performance}%</Box>
              </Box>
            </Box>

            <Box className='chapters_stats' ref={bookRef}>
              {book?.chapters?.map((book) => {
                return (
                  <ChapterStudentCollapse
                    key={book.id}
                    chapterOrder={book.chapter_order}
                    chapterName={book.name}
                    modules={book.modules}
                  />
                )
              })}
            </Box>
          </Box>
        </Collapse>
      </BooksContainer>
    </RenderStudentContainer>
  )
}

export default RenderStudent
