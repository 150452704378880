import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'
import { Books, Book, BookPublished, Chapter, ITeacher, IClassroomsPerformance } from '../../models/IBooks'
import { ITeacherClassroom } from '../../models/IClassroom'

interface IFetchBooksPayload {
  per?: number
  page?: number
  desc?: boolean
  asc?: boolean
}

interface IFetchBooksByIdPayload {
  id: number
}

export type IPaginationPayload = {
  totalOfPages: number,
  current: number,
  total: number,
  per?: number
}

interface ICreateBookRequestPayload {
  subjects: { id: number, name: string }[]
  name: string
  description: string
  nextTab?: () => void
  status?: string
  coauthors?: { id: number, name: string }[]
}

interface IBookUpdatePayload {
  id?: number
  subjects?: { id: number, name: string }[]
  name?: string
  description?: string
  coauthors?: { id: number, name: string }[]
  nextTab?: () => void
  status?: string
  photo?: any
  changeSaveDraft?: () => void
  changeSaveBook?: () => void
  navigateTo?: () => void
}

interface IChapterRequestPayload {
  chapter_title: string
  chapter_description: string
  chapter_order: number
  closeForm: () => void | undefined
  book_id: number
}

interface IUpdateChapterRequestPayload {
  id?: number
  name?: string
  description?: string
  order?: number
  bookId?: number
  closeEditForm: () => void
}

interface IFetchClassroomsRequestPayload {
  school_unit_ids: number[]
}

interface IFetchClassroomsSuccessPayload {
  [key: string]: ITeacherClassroom[]
  all: ITeacherClassroom[]
}

interface IAddClassroomRequestPayload {
  id?: number
  school_classroom_ids: number[]
  modules: { id: number, module_order: number }[]
  nextTab: () => void
}

interface IGetTeachersWithSubjectsPayload {
  schoolUnitId?: number
}

export interface IFetchStudentsOnBookPayload {
  school_unit_ids?: any
}

interface IPublishBookPayload {
  dates?: {
    chapterId: number,
    classroom_chapters: {
      id: number,
      start_date: string,
      end_date: string,
      classroom_modules: {
        id: number,
        start_date: string,
        end_date: string
      }[]
    }[]
  }[],
  bookData?: {
    id?: number
    subjects?: { id: number, name: string }[]
    name?: string
    description?: string
    coauthors?: { id: number, name: string, user: { id: number, name: string } }[]
    status?: string
  }
}

interface ICoverBookResponse {
  id: number
  name: string
  photo_url: string
}

export const fetchBooksRequest = createAction('books/BOOK_ITEM_FETCH_REQUEST', withPayloadType<IFetchBooksPayload>())
export const fetchBooksSuccess = createAction('books/BOOK_ITEM_FETCH_SUCCESS', withPayloadType<Books[]>())
export const fetchBooksFailure = createAction('books/BOOK_ITEM_FETCH_FAILURE', withPayloadType<string | null>())

export const booksChangePagination = createAction('books/BOOKS_CHANGE_PAGINATION', withPayloadType<IPaginationPayload>())

export const createBookRequest = createAction('books/CREATE_BOOK_REQUEST', withPayloadType<ICreateBookRequestPayload>())
export const createBookSuccess = createAction('books/CREATE_BOOK_SUCCESS')
export const createBookFailure = createAction<string>('books/CREATE_BOOK_FAILURE')

export const updateBookRequest = createAction('books/UPDATE_BOOK_REQUEST', withPayloadType<IBookUpdatePayload>())
export const updateBookSuccess = createAction('books/UPDATE_BOOK_SUCCESS')
export const updateBookFailure = createAction<string>('books/UPDATE_BOOK_FAILURE')

export const fetchBookByIdRequest = createAction('books/FETCH_BOOK_BY_ID_REQUEST', withPayloadType<IFetchBooksByIdPayload>())
export const fetchBookByIdSuccess = createAction('books/FETCH_BOOK_BY_ID_SUCCESS', withPayloadType<Book>())
export const fetchBookByIdFailure = createAction<string>('books/FETCH_BOOK_BY_ID_FAILURE')

export const setModules = createAction('books/SET_MODULES_IN_CHAPTER', withPayloadType<any>())
export const clearModules = createAction('books/CLEAR_MODULES_IN_CHAPTER')
export const clearCurrentBook = createAction('books/CLEAR_CURRENT_BOOK')
export const clearBooksFilters = createAction('books/CLEAR_BOOKS_FILTERS')

export const createChapterRequest = createAction('books/CREATE_CHAPTER_IN_BOOK_REQUEST', withPayloadType<IChapterRequestPayload>())
export const createChapterSuccess = createAction('books/CREATE_CHAPTER_IN_BOOK_SUCCESS')
export const createChapterFailure = createAction<string>('books/CREATE_CHAPTER_IN_BOOK_FAILURE')

export const fetchChaptersRequest = createAction('books/FETCH_CHAPTERS_REQUEST', withPayloadType<{ bookId: number }>())
export const fetchChaptersSuccess = createAction('books/FETCH_CHAPTERS_SUCCESS', withPayloadType<Chapter[]>())
export const fetchChaptersFailure = createAction<string>('books/FETCH_CHAPTERS_FAILURE')

export const updateChapterRequest = createAction('books/UPDATE_CHAPTER_REQUEST', withPayloadType<IUpdateChapterRequestPayload>())
export const updateChapterSuccess = createAction('books/UPDATE_CHAPTER_SUCCESS')
export const updateChapterFailure = createAction<string>('books/UPDATE_CHAPTER_FAILURE')

export const deleteChapterRequest = createAction('books/DELETE_CHAPTER_REQUEST', withPayloadType<number | undefined>())
export const deleteChapterSuccess = createAction('books/DELETE_CHAPTER_SUCCESS')
export const deleteChapterFailure = createAction<string>('books/DELETE_CHAPTER_FAILURE')

export const deleteBookRequest = createAction('books/DELETE_BOOK_REQUEST', withPayloadType<number | undefined>())
export const deleteBookSuccess = createAction('books/DELETE_BOOK_SUCCESS')
export const deleteBookFailure = createAction<string>('books/DELETE_BOOK_FAILURE')

export const fetchClassroomsRequest = createAction('books/FETCH_CLASSROOMS_REQUEST', withPayloadType<IFetchClassroomsRequestPayload>())
export const fetchClassroomsSuccess = createAction('books/FETCH_CLASSROOMS_SUCCESS', withPayloadType<IFetchClassroomsSuccessPayload>())
export const fetchClassroomsFailure = createAction<string>('books/FETCH_CLASSROOMS_FAILURE')

export const clearClassrooms = createAction('books/CLEAR_CLASSROOMS')

export const addClassroomsRequest = createAction('books/ADD_CLASSROOMS_REQUEST', withPayloadType<IAddClassroomRequestPayload>())
export const addClassroomsSuccess = createAction('books/ADD_CLASSROOMS_SUCCESS')
export const addClassroomsFailure = createAction<string>('books/ADD_CLASSROOMS_FAILURE')

export const changeClassroomsRequest = createAction('books/CHANGE_CLASSROOMS_REQUEST', withPayloadType<IAddClassroomRequestPayload>())
export const changeClassroomsSuccess = createAction('books/CHANGE_CLASSROOMS_SUCCESS')
export const changeClassroomsFailure = createAction<string>('books/CHANGE_CLASSROOMS_FAILURE')

export const fetchBookPublishedRequest = createAction('books/FETCH_BOOK_PUBLISHED_REQUEST', withPayloadType<{ id?: number }>())
export const fetchBookPublishedSuccess = createAction('books/FETCH_BOOK_PUBLISHED_SUCCESS', withPayloadType<BookPublished>())
export const fetchBookPublishedFailure = createAction<string>('books/FETCH_BOOK_PUBLISHED_FAILURE')

export const clearBookPublished = createAction('books/CLEAR_BOOK_PUBLISHED')

export const changeChapterOrderRequest = createAction('books/CHANGE_CHAPTER_ORDER_REQUEST', withPayloadType<Chapter[]>())
export const changeChapterOrderSuccess = createAction('books/CHANGE_CHAPTER_ORDER_SUCCESS', withPayloadType<Chapter[]>())
export const changeChapterOrderFailure = createAction<string>('books/CHANGE_CHAPTER_ORDER_FAILURE')

export const cloneBookRequest = createAction('books/CLONE_BOOK_REQUEST', withPayloadType<IFetchBooksByIdPayload>())
export const cloneBookSuccess = createAction('books/CLONE_BOOK_SUCCESS', withPayloadType<{ bookId: number }>())
export const cloneBookFailure = createAction<string>('books/CLONE_BOOK_FAILURE')

export const addCoauthorRequest = createAction('books/ADD_COAUTHOR_REQUEST', withPayloadType<any>())
export const addCoauthorSuccess = createAction('books/ADD_COAUTHOR_SUCCESS')
export const addCoauthorFailure = createAction('books/ADD_COAUTHOR_FAILURE')

export const getTeachersWithSubjectsRequest = createAction('users:GET_TEACHERS_WITH_SUBJECTS_REQUEST', withPayloadType<IGetTeachersWithSubjectsPayload>())
export const getTeachersWithSubjectsSuccess = createAction('users:GET_TEACHERS_WITH_SUBJECTS_SUCCESS', withPayloadType<ITeacher[]>())
export const getTeachersWithSubjectsFailure = createAction<string>('users:GET_TEACHERS_WITH_SUBJECTS_FAILURE')

export const publishBookRequest = createAction('books/PUBLISH_BOOK_REQUEST', withPayloadType<IPublishBookPayload>())
export const publishBookSuccess = createAction('books/PUBLISH_BOOK_SUCCESS')
export const publishBookFailure = createAction<string>('books/PUBLISH_BOOK_FAILURE')

export const updateBookStatusRequest = createAction('books/UPDATE_BOOK_STATUS_REQUEST', withPayloadType<IBookUpdatePayload>())
export const updateBookStatusSuccess = createAction('books/UPDATE_BOOK_STATUS_SUCCESS')
export const updateBookStatusFailure = createAction<string>('books/UPDATE_BOOK_STATUS_FAILURE')

export const classroomsPerformanceRequest = createAction('books/FETCH_CLASSROOMS_PERFORMANCE_REQUEST', withPayloadType<{ id: number }>())
export const classroomsPerformanceSuccess = createAction('books/FETCH_CLASSROOMS_PERFORMANCE_SUCCESS', withPayloadType<IClassroomsPerformance>())
export const classroomsPerformanceFailure = createAction<string>('books/FETCH_CLASSROOMS_PERFORMANCE_FAILURE')

export const getCoverBooksRequest = createAction('books/GET_COVER_BOOKS_REQUEST')
export const getCoverBooksSuccess = createAction('books/GET_COVER_BOOKS_SUCCESS', withPayloadType<ICoverBookResponse[]>())
export const getCoverBooksFailure = createAction<string>('books/GET_COVER_BOOKS_FAILURE')
