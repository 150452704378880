import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, Pagination, Typography } from '@mui/material'
import { useAppDispatch } from '../../store/hooks'
import SelectInput from '../select/SelectInput'
import {
  fetchExamsRequest,
  fetchExamsQuestionsRequest,
  importExamQuestionsRequest,
  clearCurrentItem
} from '../../store/exams/actions'
import ExamCard from './ExamCard'
import NoItemsCard from '../card/NoItemsCard'
import { scrollToTop } from '../../utils/functions'
import { ReactComponent as NoOptionsImage } from '../../assets/components/Exams/noOptions-image.svg'

const HeaderList = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 24,
  '& p': {
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  '& span': {
    fontSize: 14
  },
  '& .selectPage': {
    alignItems: 'center',
    display: 'flex',
    gap: 9
  }
}))

const ExamsList = ({
  exams,
  perPage,
  setPerPage,
  page,
  setPage
}: any) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    setPage(1)
    dispatch(fetchExamsRequest({ per: perPage, page: 1 }))
  }, [perPage])

  const handleGetExamsItems = (params: object) => {
    dispatch(fetchExamsRequest(params))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    handleGetExamsItems({ page: value })
    scrollToTop()
  }

  const handleChangePerPage: React.ChangeEventHandler<HTMLInputElement> = event => {
    setPerPage(Number(event.target.value))
  }

  if (exams.isFetching) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <HeaderList>
        <Typography component='p' variant='body2'>{exams.pagination?.total} prova(s) listado(s)</Typography>
        <div className='selectPage'>
          <Typography component='div' variant='body2'>Exibir:</Typography>
          <SelectInput
            name='selectPage'
            defaultValue={perPage}
            onChange={handleChangePerPage}
            width='150px'
            options={[
              { label: '1 por página', value: 1 },
              { label: '5 por página', value: 5 },
              { label: '10 por página', value: 10 },
              { label: '15 por página', value: 15 },
            ]}
          />
        </div>
      </HeaderList>
      {
        exams?.items && !isEmpty(exams.items) ? exams.items.map((exam: { name: string, id: string | number }) => {
          return (
            <Box key={exam.id} sx={{ marginBottom: '16px', border: '1px solid #d9d9d9', borderRadius: '16px', overflow: 'hidden' }}>
              <ExamCard
                exam={exam}
                importExamQuestions={importExamQuestionsRequest}
                fetchExamsQuestions={fetchExamsQuestionsRequest}
                importingExamId={exams.importingExamId}
                isImporting={exams.isImporting}
                currentItem={exams.examQuestions}
                clearCurrentItem={clearCurrentItem}
              />
            </Box>

          )
        })
          : exams?.items && isEmpty(exams.items) && Object.keys(exams.filters).length > 2
            ? (
              <NoItemsCard>
                <NoOptionsImage />
                <h3>Nenhuma prova encontrada.</h3>
              </NoItemsCard>
            ) : (
              <NoItemsCard>
                <NoOptionsImage />
                <h3>Você ainda não possui provas listadas</h3>
                <p>Para listar provas preencha os <span>filtros de pesquisa</span> no canto esquerdo superior</p>
              </NoItemsCard>
            )
      }
      {!exams.isFetching && exams.pagination &&
        exams.pagination?.totalOfPages > 1 && (
          <Pagination
            count={exams.pagination?.totalOfPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          />
        )}
    </>
  )
}

export default ExamsList
