import React, { useState } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import {
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import AuthorsTooltip from '../../tooltip/AuthorsTooltip'
import ModalPermissions from '../../Modal/ModalPermissions'
import ModalDelete from '../../Modal/ModalDelete'
import ModalCoauthors from '../../Modal/ModalCoauthors'
import ModalClone from '../../Modal/ModalClone'
import { Author, Books, CoAuthor } from '../../../models/IBooks'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { deleteBookRequest } from '../../../store/books/actions'
import BarCodeIcon from '../../../assets/components/Books/barcode-icon.svg'
import AuthorIcon from '../../../assets/components/Books/author-icon.svg'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import CloseIcon from '@mui/icons-material/Close'
import ViewIcon from '../../../assets/components/Books/view-outlined-icon.svg'
import EditIcon from '../../../assets/components/Books/edit-icon.svg'
import CopyIcon from '../../../assets/components/Books/copy-icon.svg'
import DeleteIcon from '../../../assets/components/Books/trash-icon.svg'
import ClassroomIcon from '../../../assets/components/Books/people-icon.svg'
import BookIcon from '../../../assets/components/Books/book-icon.svg'
import NoteBookIcon from '../../../assets/components/Books/notebook-icon.svg'
import SeeMoreIcon from '../../../assets/components/Books/see-more-icon.svg'
import BookPublishIcon from '../../../assets/components/Books/book_publish-icon.svg'
import ReportIcon from '../../../assets/components/Books/report-icon.svg'

interface BookCardProps {
  book?: Books
}

interface IPermission {
  actionType: 'edit' | 'delete'
  isOpen: boolean
}

interface CardContainerProps extends BoxProps {
  status?: string
}

const CardContainer = styled(Box)<CardContainerProps>(({ theme, status }) => ({
  background: status === 'published'
    ? '#e6f7fa'
    : status === 'ready_to_publish'
      ? '#F5F0FE'
      : '#fce6e6',
  border: '1px solid #d9d9d9',
  borderRadius: '16px',
  display: 'flex',
  gap: '12px',
  padding: '16px 24px',

  '& .field_title': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    marginInline: '4px',
  },

  '& .field_result': {
    color: theme.palette.primary.light,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '120%',
  },

  '& .total_chapters': {
    color: theme.palette.text.primary,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '120%',
  },

  '& .draft': {
    backgroundColor: theme.palette.error.main,
    borderRadius: '32px',
    color: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '4px 6px',
  },

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '120%',
  },

  '& .description': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%',
  }
}))

const IconBtn = styled('button')({
  background: 'none',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background 0.3s ease-in-out',
  '& path': {
    fill: '#9F9F9F'
  },
  '&:hover': {
    background: '#0000000a'
  }
})

const MenuItemStyle = styled(MenuItem)({
  color: '#9F9F9F',
  fontSize: 14,
  width: 180,
  '& img': {
    marginRight: 10
  }
})

const SubjectElement = styled(Box)(({ theme }) => ({
  '.subject': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '&_button': {
      minWidth: 'auto',
      padding: 0,
    },

    '&_description': {
      alignItems: 'center',
      display: 'flex',

      'span': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '120%',
        textTransform: 'capitalize'
      }
    }
  }
}))

const PopoverStyled = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    padding: '15px'
  },

  'h4': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
    textTransform: 'capitalize'
  },

  'ul': {
    paddingInlineStart: '25px',

    'li': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      textTransform: 'capitalize'
    }
  }
}))

const AccessBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& .label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%'
  },

  '& .value': {
    background: theme.palette.primary.light,
    borderRadius: '32px',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '4px 6px'
  }
}))

const BookCard: React.FC<BookCardProps> = ({ book }) => {
  const [open, setOpen] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalPermission, setOpenModalPermission] = useState<IPermission>({ actionType: 'delete', isOpen: false })
  const [openModalCoauthors, setOpenModalCoauthors] = useState(false)
  const [openModalClone, setOpenModalClone] = useState(false)
  const [bookId, setBookId] = useState<any>(null)
  const [anchorMoreEl, setAnchorMoreEl] = useState<HTMLElement | null>(null)
  const openMore = Boolean(anchorMoreEl)
  const [popoverSubject, setPopoverSubject] = useState<HTMLButtonElement | null>(null)
  const [popoverClassroom, setPopoverClassroom] = useState<HTMLButtonElement | null>(null)
  const openPopoverSubject = Boolean(popoverSubject)
  const openPopoverClassroom = Boolean(popoverClassroom)
  const subjectId = openPopoverSubject ? 'subject-popover' : undefined
  const classroomId = openPopoverClassroom ? 'classroom-popover' : undefined
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xlDown = useMediaQuery('(max-width: 1440px)')

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMoreEl(event.currentTarget)
  }

  const handleCloseMore = () => {
    setAnchorMoreEl(null)
  }

  const handleSubjectPopoverOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverSubject(e.currentTarget)
  }

  const handleSubjectPopoverClose = () => {
    setPopoverSubject(null)
  }

  const handleClassroomPopoverOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverClassroom(e.currentTarget)
  }

  const handleClassroomPopoverClose = () => {
    setPopoverClassroom(null)
  }

  const textExtract = (text: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = text
    const extractedText = tempElement.innerText
    const limitedText = extractedText.length > 100 ? extractedText.substring(0, 240) + '...' : extractedText
    return limitedText
  }

  const isOwner = (authors?: Author[], coauthors?: CoAuthor[], enableEdit?: boolean) => {
    const isAuthor = authors && authors.some(author => author.user?.id === user.id)
    const isCoAuthor = enableEdit && coauthors && coauthors.some(coauthor => coauthor.user.id === user.id)
    return isAuthor || isCoAuthor
  }

  const handleDeleteBook = (bookId: any, authors?: Author[], coauthors?: CoAuthor[]) => {
    handleCloseMore()
    if (!isOwner(authors, coauthors)) {
      setOpenModalPermission({ actionType: 'delete', isOpen: true })
      return
    }
    setOpenModalDelete(true)
    setBookId(bookId)
  }

  const deleteBook = () => {
    dispatch(deleteBookRequest(bookId))
  }

  const handleEditBook = (bookId?: number, authors?: Author[], coauthors?: CoAuthor[]) => {
    handleCloseMore()
    if (!isOwner(authors, coauthors, true)) {
      setOpenModalPermission({ actionType: 'edit', isOpen: true })
      return
    }
    navigate(`/books/${bookId}/edit`, { state: { isEdit: true } })
  }

  const handleCloneBook = () => {
    handleCloseMore()
    setOpenModalClone(true)
  }

  const handleOpenModalCoauthor = () => {
    handleCloseMore()
    setOpenModalCoauthors(true)
  }

  const navigateToBookReport = () => {
    navigate(`/books/${book?.id}/report`)
  }

  return (
    <>
      <CardContainer status={book?.status}>
        <Box
          sx={{
            borderRadius: '8px',
            flexShrink: 0,
            height: '190px',
            overflow: 'hidden',
            width: '130px',
            }}
          >
          {book?.photo_url
            ? (
              <img src={book?.photo_url} alt='capa do livro' width='100%' height='100%' />
            )
            : (
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  fontSize: '20px',
                  fontWeight: 600,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fbfbfb',
                  color: '#c9c9c9'
                }}
              >
                <span>Livro</span>
                <span>sem</span>
                <span>capa</span>
              </Box>
            )
          }
        </Box>

        <Box sx={{ flex: 1 }}>
          <Grid container>
            <Grid item xs={3} sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ width: '20px', height: '20px' }}>
                <img src={BarCodeIcon} width='100%' height='100%' />
              </Box>
              <span className='field_title'>Código:</span>
              <span className='field_result'>{book?.id}</span>
            </Grid>

            <Grid item xs={8} sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ width: '20px', height: '20px' }}>
                <img src={AuthorIcon} width='20px' height='20px' />
              </Box>
              <span className='field_title'>Autores:</span>
              <AuthorsTooltip
                open={open}
                openTooltip={handleTooltipOpen}
                closeTooltip={handleTooltipClose}
                authors={book?.authors ?? []}
                coauthors={book?.co_authors ?? []}
              />
            </Grid>

            <Grid item xs={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
              <IconBtn
                id={`moreActionsButton[${book?.id}]`}
                aria-controls={openMore ? `moreActionsmenu[${book?.id}]` : undefined}
                aria-haspopup='true'
                aria-expanded={openMore ? 'true' : undefined}
                onClick={handleOpenMore}
              >
                <MoreVertRoundedIcon />
              </IconBtn>
              <Menu
                id={`moreActionsmenu[${book?.id}]`}
                keepMounted
                anchorEl={anchorMoreEl}
                open={anchorMoreEl?.id === `moreActionsButton[${book?.id}]`}
                onClose={handleCloseMore}
                TransitionComponent={Fade}
                sx={{
                  '& .MuiPaper-root': {
                    border: `1px solid ${theme.palette.text.secondary}`,
                    borderRadius: '8px',
                    width: '164px'
                  }
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconBtn onClick={handleCloseMore}>
                    <CloseIcon />
                  </IconBtn>
                </Box>

                {
                  <MenuItemStyle onClick={() => navigate(`/books/${book?.id}/details`)}>
                    <img src={ViewIcon} alt='ícone de visualizar' width='18' /> Visualizar
                  </MenuItemStyle>
                }

                <MenuItemStyle onClick={handleOpenModalCoauthor}>
                  <img src={AuthorIcon} alt='icone de coautor' width='16' /> Coautor
                </MenuItemStyle>

                <MenuItemStyle onClick={() => handleEditBook(book?.id, book?.authors, book?.co_authors)}>
                  <img src={EditIcon} alt='icone de editar' width='13' /> Editar
                </MenuItemStyle>

                {(book?.status === 'ready_to_publish' || book?.status === 'published') && (
                  <MenuItemStyle onClick={() => navigate(`/books/${book?.id}/publish`)}>
                    <img src={BookPublishIcon} alt='ícone de um livro' width='13px' /> Publicar
                  </MenuItemStyle>
                )}

                <MenuItemStyle onClick={handleCloneBook}>
                  <img src={CopyIcon} alt='ícone de clonar' width='12' /> Clonar
                </MenuItemStyle>

                <MenuItemStyle onClick={() => handleDeleteBook(book?.id, book?.authors, book?.co_authors)}>
                  <img src={DeleteIcon} alt='ícone de excluir' width='16' /> Excluir
                </MenuItemStyle>

                <MenuItemStyle onClick={navigateToBookReport}>
                  <img src={ReportIcon} alt='ícone de relatório' width='16' /> Relatório
                </MenuItemStyle>
              </Menu>
            </Grid>
          </Grid>

          <Box sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '8px' }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '8px'
              }}
            >
              {book?.published_to && book?.published_to?.length !== 0 && (
                <img src={ClassroomIcon} width='20px' height='20px' />
              )}
              {book?.published_to && book?.published_to?.length <= 3 && (
                <Stack
                  direction='row'
                  spacing={1}
                >
                  {book?.published_to?.map((classroom) => (
                    <Chip
                      key={classroom.id}
                      label={classroom.name}
                      title={classroom.name}
                      variant='outlined'
                      sx={{
                        background: '#fff',
                        border: 'none',
                        height: 'fit-content',
                        maxWidth: mdDown ? '120px' : lgDown ? '150px' : xlDown ? '170px' : '240px',

                        '& .MuiChip-label': {
                          color: theme.palette.text.primary,
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '120%',
                          padding: '4px 6px'
                        }
                      }}
                    />
                  ))}
                </Stack>
              )}

              {book?.published_to && book?.published_to?.length > 3 && (
                <Box sx={{ alignItems: 'center', display: 'flex', position: 'relative' }}>
                  <Stack direction='row' spacing={1}>
                    {book?.published_to?.slice(0, 3)?.map((classroom) => (
                      <Chip
                        key={classroom.id}
                        label={classroom.name}
                        title={classroom.name}
                        variant='outlined'
                        sx={{
                          background: '#fff',
                          border: 'none',
                          height: 'fit-content',
                          maxWidth: mdDown ? '120px' : lgDown ? '150px' : xlDown ? '170px' : '240px',

                          '& .MuiChip-label': {
                            color: theme.palette.text.primary,
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '120%',
                            padding: '4px 6px'
                          }
                        }}
                      />
                    ))}
                  </Stack>
                  <IconButton
                    aria-describedby={classroomId}
                    type='button'
                    className='classroom classroom_button'
                    onClick={handleClassroomPopoverOpen}
                    sx={{ padding: 0, right: '4px' }}
                  >
                    <img src={SeeMoreIcon} />
                  </IconButton>

                  <PopoverStyled
                    id={classroomId}
                    open={openPopoverClassroom}
                    anchorEl={popoverClassroom}
                    onClose={handleClassroomPopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    <Box>
                      <Typography component='h4'>Turmas</Typography>
                      <ul>
                        {book?.published_to?.map((classroom) => (
                          <li key={classroom?.id}>
                            {classroom?.name}
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </PopoverStyled>
                </Box>
              )}
            </Box>

            <Grid container alignItems='center'>
              <Grid item xs={4}>
                <SubjectElement>
                  {book?.subjects && book?.subjects?.length === 1 && (
                    <Box className='subject'>
                      <img src={NoteBookIcon} width='15.5px' height='15.5px' className='subject_image' />
                      <Box className='subject_description'>
                        <span>{book?.subjects?.[0]?.name}</span>
                      </Box>
                    </Box>
                  )}

                  {book?.subjects && book?.subjects?.length > 1 && (
                    <>
                      <Button
                        aria-describedby={subjectId}
                        type='button'
                        className='subject subject_button'
                        onClick={handleSubjectPopoverOpen}
                      >
                        <img src={NoteBookIcon} height='15.5px' width='15.5px' className='subject_image' />
                        <Box
                          className='subject_description'
                        >
                          <span>{book?.subjects?.[0]?.name}</span>
                          <img src={SeeMoreIcon} />
                        </Box>
                      </Button>
                      <PopoverStyled
                        id={subjectId}
                        open={openPopoverSubject}
                        anchorEl={popoverSubject}
                        onClose={handleSubjectPopoverClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                      >
                        <Box>
                          <Typography component='h4'>Disciplinas</Typography>
                          <ul>
                            {book?.subjects?.map((subject) => (
                              <li key={subject?.id}>
                                {subject?.name}
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </PopoverStyled>
                    </>
                  )}
                </SubjectElement>
              </Grid>

              <Grid item xs={4}>
                <AccessBox>
                  <Box
                    component='span'
                    className='label'
                  >
                    Acessos:
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {book && book.access < 10 && `00${book?.access}`}
                    {book && book.access > 10 && book.access < 100 && `0${book?.access}`}
                    {book && book.access >= 100 && `${book?.access}`}
                  </Box>
                </AccessBox>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', marginTop: '16px' }}>
            <img src={BookIcon} alt='ícone de um livro' width='18px' height='18px' />
            <Typography component='h3' className='title'>
              {book?.name}
            </Typography>
          </Box>

          <Box sx={{ marginTop: '8px' }}>
            <Typography className='description'>
              {book && book.description
                ? textExtract(book.description)
                : 'Este livro não possui descrição.'
              }
            </Typography>
          </Box>
        </Box>
      </CardContainer >

      <ModalDelete
        isOpen={openModalDelete}
        closeModal={() => setOpenModalDelete(false)}
        action={deleteBook}
        type='book'
      />

      <ModalPermissions
        open={openModalPermission.isOpen}
        closeModal={() => setOpenModalPermission({ ...openModalPermission, isOpen: false })}
        actionType={openModalPermission.actionType}
      />

      <ModalCoauthors
        isOpen={openModalCoauthors}
        closeModal={() => setOpenModalCoauthors(false)}
        schoolUnitId={selectedUnit?.id}
        book={book}
      />

      <ModalClone
        isOpen={openModalClone}
        closeModal={() => setOpenModalClone(false)}
        action={() => navigate('/books/clone', { state: book?.id })}
      />
    </>
  )
}

export default BookCard
