import { all, call, takeLatest, put, delay, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import {
  deleteStudentRequest,
  createStudentRequest,
  updateStudentDataRequest,
  updateStudentDataSuccess,
  deleteStudentSuccess,
  deleteStudentRejected,
  getStudentByIdRequest,
  getStudentByIdSuccess,
  getStudentByIdRejected,
  createStudentSuccess,
  createStudentRejected,
  updateStudentDataFailure,
  getAllStudentsSuccess,
  getAllStudentsRejected,
  getAllStudentsRequest
} from './actions'
import * as services from './services'
import { showAlertMessage } from '../alert'
import { TakeableChannel } from 'redux-saga'
import { removeNonNumericCharacters } from '../../utils/functions'
import { ISchool } from '../school_app_id/types'

function* createStudentSaga({ payload }: any) {

  const studentClassrooms = payload.classrooms.map((classroom: any) => {
    return {
      school_classroom_id: classroom?.classroom?.id,
      registration: payload.registration
    }
  })

  const { selectedUnit } = yield select(state => state.persistable)
  const { items } = yield select(state => state.schoolAppId)

  const schoolUnit = items?.filter((school: ISchool) => {
    return school?.school?.school_unit_id === selectedUnit?.id
  })


  try {
    const requestBody = {
      school_unit_id: payload?.school_unit_id,
      students: {
        name: payload?.name,
        email: payload?.email.toLowerCase(),
        genre: payload?.genre?.value,
        birthday: payload?.birthday,
        phone: payload?.phone,
        taxpayer_number: removeNonNumericCharacters(payload?.taxpayer_number),
        additional_data: {
          address: {
            number: payload?.number,
            street: payload?.street,
            zipcode: payload?.zipcode,
            complement: payload?.complement,
            neighborhood: payload?.neighborhood
          }
        }
      },
      parents: payload?.parents,
      classrooms_students_attributes: studentClassrooms,
      roles_users_attributes: {
        user_application_id: schoolUnit?.[0]?.id ?? null
      }
    }

    const response: AxiosResponse = yield call(services.createStudentService, requestBody)
    if (response.status === 201) {
      yield put(showAlertMessage({
        message: 'Estudante criado com sucesso.',
        severity: 'success',
        duration: 5000
      }))
      yield put(createStudentSuccess())
      yield delay(1500)
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    }
  } catch (error: any) {
    const errorMessageCpfAndEmail = 'A validação falhou: Email já está em uso, Taxpayer number já está em uso'
    const errorMessageCpf = 'A validação falhou: Taxpayer number já está em uso'
    const errorMessageEmail = 'A validação falhou: Email já está em uso'

    let message
    switch (error?.response?.data?.error) {
      case errorMessageCpfAndEmail:
        message = 'CPF e Email já estão em uso.'
        break
      case errorMessageCpf:
        message = 'CPF já está em uso.'
        break
      case errorMessageEmail:
        message = 'Email já está em uso'
        break
      default:
        message = 'Erro ao criar aluno.'
        break
    }

    yield put(showAlertMessage({
      message,
      severity: 'error',
      duration: 5000
    }))
    yield put(createStudentRejected())
  }
}

function* fetchStudentByIdSaga(action: { payload: number }) {
  const { payload } = action
  try {
    const response: AxiosResponse = yield call(services.getStudentById, payload)
    if (response.status === 200) {
      yield put(getStudentByIdSuccess(response.data))
    }
  } catch (error) {
    yield put(getStudentByIdRejected())
    console.error(error)
  }
}

function* getAllStudentSaga({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(services.getAllStudents, payload)
    if (response.status === 200) {
      yield put(getAllStudentsSuccess(response.data))
    }
  } catch (error) {
    yield put(getAllStudentsRejected())
    console.error(error)
  }
}

function* updateStudent({ payload }: any) {
  const { selectedUnit } = yield select(state => state?.persistable)
  const { items } = yield select(state => state?.schoolAppId)

  const schoolUnit = items?.filter((school: ISchool) => {
    return school?.school?.school_unit_id === selectedUnit?.id
  })

  let requestBody: any
  if (payload?.selectedUnit) {
    requestBody = {
      id: payload?.student?.id,
      school_unit_id: payload?.selectedUnit?.id,
      students: {
        name: payload?.student?.name,
        email: payload?.student?.email.toLowerCase(),
        genre: payload?.student?.genre ?? payload?.student?.genre?.value,
        birthday: payload?.student?.birthday,
        phone: payload?.student?.phone,
        additional_data: {
          address: {
            number: payload?.student?.additional_data?.address?.number,
            street: payload?.student?.additional_data?.address?.street,
            zipcode: payload?.student?.additional_data?.address?.zipcode,
            complement: payload?.student?.additional_data?.address?.complement,
            neighborhood: payload?.student?.additional_data?.address?.neighborhood
          }
        }
      },
      parents: payload?.student?.parents,
      classrooms_students_attributes: [
        {
          school_classroom_id: payload?.radioClassroomId,
          registration: payload?.student?.registration
        }
      ],
      roles_users_attributes: {
        user_application_id: schoolUnit?.[0]?.id ?? null
      }
    }
  } else {
    const studentClassrooms = payload.classrooms.map((classroom: any) => {
      return {
        school_classroom_id: classroom?.classroom?.id,
        registration: payload.registration
      }
    })
    requestBody = {
      id: payload.id,
      school_unit_id: payload?.school_unit_id,
      students: {
        name: payload?.name,
        email: payload?.email,
        genre: payload?.genre?.value,
        birthday: payload?.birthday,
        phone: payload?.phone,
        additional_data: {
          address: {
            number: payload?.number,
            street: payload?.street,
            zipcode: payload?.zipcode,
            complement: payload?.complement,
            neighborhood: payload?.neighborhood
          }
        }
      },
      parents: payload?.parents,
      classrooms_students_attributes: studentClassrooms,
      roles_users_attributes: {
        user_application_id: schoolUnit?.[0]?.id ?? null
      }

    }
  }

  try {
    const response: AxiosResponse = yield call(services.updateStudentService, requestBody)
    if (response.status === 200) {
      yield put(showAlertMessage({
        message: 'Dados atualizados com sucesso.',
        severity: 'success',
        duration: 5000
      }))
      yield delay(1500)
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
      yield put(updateStudentDataSuccess())
    }
  } catch (error: any) {

    const errorMessageCpfAndEmail = 'A validação falhou: Email já está em uso, Taxpayer number já está em uso'
    const errorMessageCpf = 'A validação falhou: Taxpayer number já está em uso'
    const errorMessageEmail = 'A validação falhou: Email já está em uso'

    let message
    switch (error?.response?.data?.error) {
      case errorMessageCpfAndEmail:
        message = 'CPF e Email já estão em uso.'
        break
      case errorMessageCpf:
        message = 'CPF já está em uso.'
        break
      case errorMessageEmail:
        message = 'Email já está em uso'
        break
      default:
        message = 'Erro ao atualizar dados.'
        break
    }

    yield put(showAlertMessage({
      message,
      severity: 'error',
      duration: 5000
    }))
    yield put(updateStudentDataFailure())
  }
}

function* managerDeleteUser({ payload }: any) {
  const requestBody = {
    user_user_id: payload?.id
  }
  try {
    yield call(services.deleteStudentService, requestBody)
    yield put(showAlertMessage({
      message: 'Aluno deletado com sucesso.',
      severity: 'success',
      duration: 5000
    }))
    yield put(deleteStudentSuccess('Sucesso ao deletar o aluno.'))
  } catch (error) {
    yield put(showAlertMessage({
      message: 'Erro ao deletar aluno.',
      severity: 'error',
      duration: 5000
    }))
    yield put(deleteStudentRejected(`Erro ao deletar o aluno ${payload?.name}.`))
  }
}

function* watchFetchStudentById() {
  yield takeLatest(getStudentByIdRequest.type as unknown as TakeableChannel<unknown>, fetchStudentByIdSaga)
}

function* watchGetAllStudents() {
  yield takeLatest(getAllStudentsRequest.type as unknown as TakeableChannel<unknown>, getAllStudentSaga)
}

function* watchDeleteUserSaga() {
  yield takeLatest(deleteStudentRequest.type, managerDeleteUser)
}

function* watchUpdateUserSaga() {
  yield takeLatest(updateStudentDataRequest.type, updateStudent)
}

function* watchCreateStudentSaga() {
  yield takeLatest(createStudentRequest.type, createStudentSaga)
}

export default function* studentsSagas() {
  yield all([
    watchDeleteUserSaga(),
    watchCreateStudentSaga(),
    watchUpdateUserSaga(),
    watchFetchStudentById(),
    watchGetAllStudents()
  ])
}
