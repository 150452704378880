// import { AnyAction } from '@reduxjs/toolkit'
import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import {
  subjectsFetchRequest,
  subjectsFetchSuccess,
  subjectsFetchFailure,
  themesFetchRequest,
  themesFetchSuccess,
  themesFetchFailure,
  gradesFetchRequest,
  gradesFetchSuccess,
  gradesFetchFailure,
  segmentsFetchRequest,
  segmentsFetchSuccess,
  segmentsFetchFailure,
  teachersBySchoolFetchRequest,
  teachersBySchoolFetchSuccess
} from './index'
import * as services from './services'
import axios, { AxiosResponse } from 'axios'

export function * fetchSubjects () {
  try {
    const params = {
      basic: true
    }
    const { data } = yield call(services.fetchSubjects, params)
    yield put(subjectsFetchSuccess(data))
  } catch (error: any) {
    yield put(subjectsFetchFailure(error))
  }
}

export function * fetchThemes () {
  try {
    const { data: { data } } = yield call(services.fetchThemes)
    yield put(themesFetchSuccess(data))
  } catch (error: any) {
    yield put(themesFetchFailure(error))
  }
}

function * fetchGrades() {
  try {
    const response: AxiosResponse = yield call(services.fetchSegments)
    const grades = response.data.map((segment: any) => segment.grades).reduce((grades: any, array: any) => grades.concat(array), [])
    yield put(gradesFetchSuccess(grades))
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      yield put(gradesFetchFailure({ message: error.message }))
    }
  }
}

function * fetchSegments() {
  try {
    const response: AxiosResponse = yield call(services.fetchSegments)
    yield put(segmentsFetchSuccess(response.data))
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      yield put(segmentsFetchFailure({ message: error.message }))
    }
  }
}

function * fetchTeachers() {
  try {
    const { selectedUnit } = yield select(state => state.persistable)
    const params = {
      school_unit_id: selectedUnit?.id,
      page: 1,
      per: 10000000
    }

    const response: AxiosResponse = yield call(services.fetchTeachersBySchool, params)
    if (response.status === 200) {
      yield put(teachersBySchoolFetchSuccess(response.data))
    }
  } catch (error) {
    console.error(error)
  }
}

export function * watchFetchSubjects () {
  yield takeLatest(subjectsFetchRequest, fetchSubjects)
}

export function * watchFetchThemes () {
  yield takeLatest(themesFetchRequest, fetchThemes)
}

export function * watchFetchGrades() {
  yield takeLatest(gradesFetchRequest, fetchGrades)
}

export function * watchFetchSegments() {
  yield takeLatest(segmentsFetchRequest, fetchSegments)
}

function * watchFetchTeachers() {
  yield takeLatest(teachersBySchoolFetchRequest, fetchTeachers)
}

export default function * educationSagas () {
  yield all([
    watchFetchSubjects(),
    watchFetchThemes(),
    watchFetchGrades(),
    watchFetchSegments(),
    watchFetchTeachers()
  ])
}
