import React from 'react'
import {
  Chart as ChartJS,
  registerables
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

ChartJS.register(...registerables)

const LineStyled = styled(Line)({
  backgroundColor: '#FFFFFF',
  borderRadius: 16,
  height: '250px !important',
  padding: 16
})

const LineChart = () => {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true
      }
    }
  }

  const data = {
    labels: ['Cap1', 'Cap2', 'Cap3', 'Cap4', 'Cap5', 'Cap6', 'Cap7', 'Cap8', 'Cap9', 'Cap10'],
    datasets: [
      {
        label: 'Turma Fractal',
        data: [20, 85, 60, 12, 30, 10, 48, 79, 98, 50],
        backgroundColor: '#FF0000',
        // borderRadius: 6
      },
      {
        label: 'Turma Kingdom',
        data: [72, 85, 32, 49, 25, 65, 80, 100, 58, 78],
        backgroundColor: '#00FF00',
        // borderRadius: 6
      }
    ]
  }

  return (
    <Box>
      <LineStyled options={options} data={data} />
    </Box>
  )
}

export default LineChart
