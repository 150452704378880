// React
import React, { useState } from 'react'

// Components
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material'

// Utils
import { styled } from '@mui/material/styles'
import { IModule } from '../../../containers/data.mockup'

// Icons
import CalendarIcon from '../../../assets/components/Books/calendar-icon.svg'
import ChartIcon from '../../../assets/components/Books/chart-icon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ProgressIcon from '../../../assets/components/Books/progress-dark-icon.svg'
import ViewIcon from '../../../assets/components/Books/view-icon.svg'

const ModuleContainer = styled(Box)(({ theme }) => ({
  background: '#f9f9f9',
  borderRadius: '16px',
  margin: '0px 16px',

  '& .module': {
    cursor: 'pointer',
    padding: '16px 24px',

    '&_name': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px',
      justifyContent: 'space-between',

      '& .name': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
      },

      '& .button': {
        height: '18px',
        width: '18px'
      }
    },

    '&_stats': {
      marginTop: '8px',
      '&-dates': {
        alignItems: 'center',
        display: 'flex',
        gap: '24px',

        '& .date': {
          alignItems: 'center',
          display: 'flex',
          gap: '6px',

          '& p': {
            color: '#9f9f9f',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '120%'
          }
        }
      },

      '&-progress': {
        alignItems: 'center',
        display: 'flex',
        gap: '6px',

        '& p': {
          fontSize: '14px',
          lineHeight: '120%',

          '&:first-of-type': {
            color: '#9f9f9f',
            fontWeight: 500
          },

          '&:last-of-type': {
            color: theme.palette.secondary.main,
            fontWeight: 700
          }
        }
      },

      '&-performance': {
        alignItems: 'center',
        display: 'flex',
        gap: '6px',

        '& p': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%'
        },

        '& .linear_progress': {
          maxWidth: '120px',
          width: '100%'
        }
      },

      '&-numberOfHits': {
        alignItems: 'center',
        display: 'flex',
        gap: '6px',

        '& p': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%',

          '& span': {
            color: theme.palette.secondary.main,
            fontWeight: 700
          }
        }
      },

      '& .last_item': {
        display: 'flex',
        justifyContent: 'flex-end'
      }
    },

    '&_btn_container': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',

      '& .view_module_btn': {
        color: theme.palette.primary.light,
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.12px',
        textDecoration: 'underline',
        textTransform: 'none'
      }
    }
  }
}))

interface IModuleStudentCollapseProps {
  module: IModule
}

const ModuleStudentCollapse: React.FC<IModuleStudentCollapseProps> = ({ module }) => {
  const [open, setOpen] = useState(false)

  return (
    <ModuleContainer>
      <Box
        className='module'
        onClick={() => setOpen(prevState => !prevState)}
      >
        <Box className='module_name'>
          <Typography
            component='h3'
            className='name'
          >
            {module?.name}
          </Typography>

          <IconButton className='button'>
            <KeyboardArrowDownIcon
              fontSize='small'
              sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </IconButton>
        </Box>

        <Collapse in={open}>
          <Grid
            container
            className='module_stats'
            rowSpacing={2}
          >
            <Grid item xs={6}>
              <Box className='module_stats-dates'>
                <Box className='date'>
                  <img src={CalendarIcon} />
                  <Typography>Início:</Typography>
                  <Typography>{module?.start_date}</Typography>
                </Box>

                <Box className='date'>
                  <img src={CalendarIcon} />
                  <Typography>Final:</Typography>
                  <Typography>{module?.end_date}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} className='last_item'>
              <Box className='module_stats-progress'>
                <img src={ProgressIcon} />
                <Typography>Progresso:</Typography>
                <Typography>{module?.progress}%</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box className='module_stats-performance'>
                <img src={ChartIcon} />
                <Typography>Desempenho:</Typography>
                <LinearProgress
                  className='linear_progress'
                  color={module?.performance >= 50 ? 'secondary' : 'error'}
                  value={module?.performance}
                  variant='determinate'
                />
                <Typography>{module?.performance}%</Typography>
              </Box>
            </Grid>

            <Grid item xs={6} className='last_item'>
              <Box className='module_stats-numberOfHits'>
                <Typography>Quant. de acertos:</Typography>
                <Typography><span>{module.number_of_hits}</span> / {module.total_questions}</Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className='module_btn_container'>
            <Button
              type='button'
              variant='text'
              startIcon={<img src={ViewIcon} />}
              className='view_module_btn'
            >
              Visualizar módulo
            </Button>
          </Box>
        </Collapse>
      </Box>
    </ModuleContainer>
  )
}

export default ModuleStudentCollapse
