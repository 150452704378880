import React, { useEffect } from 'react'
import Button from '../components/button/Button'
import PerformanceChartView from '../components/view/PerformanceChartView'
import PerformanceStudentsView from '../components/view/PerformanceStudentsView'
import PerformanceClassroomView from '../components/view/PerformanceClassroomView'
import { Box, Grid, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { classroomsPerformanceRequest } from '../store/books/actions'
import BookIcon from '../assets/components/Books/book-icon.svg'
import AccessImage from '../assets/components/Books/access-image.svg'
import AverageImage from '../assets/components/Books/average-image.svg'
import ClassroomImage from '../assets/components/Books/classroom-image.svg'
import SchoolImage from '../assets/components/Books/schools-image.svg'

const BoxCard = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#ffffff',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  height: '200px',
  width: '247px',

  '.container': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    padding: '10px',

    '& .information': {
      display: 'flex',
      gap: '2px',

      '& span': {
        fontSize: '14px',
        letterSpacing: '0.14px',
        lineHeight: '120%',

        '&:first-of-type': {
          color: theme.palette.primary.light,
          fontWeight: 800
        },

        '&:last-of-type': {
          color: theme.palette.text.primary,
          fontWeight: 500
        }
      }
    }
  }
}))

const BooksReportContainer = () => {
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { classrooms_performance: { data } } = useAppSelector(state => state.books)

  const handleBackPage = () => {
    navigate('/books')
  }

  useEffect(() => {
    id && dispatch(classroomsPerformanceRequest({ id: Number(id) }))
  }, [])

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='button'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </Box>

      <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
        <img src={BookIcon} alt='ícone de livro' />
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 600
          }}
        >
          {data?.name}
        </Typography>
      </Box>

      <Box
        sx={{
          border: `1px dashed ${theme.palette.text.secondary}`,
          borderRadius: '16px',
          padding: '24px 36px 32px',
          marginTop: '32px'
        }}
      >
        <Grid container sx={{ marginInline: 'auto', maxWidth: '1096px' }}>
          <Grid item xs={3}>
            <BoxCard>
              <Box className='container'>
                <img src={AccessImage} alt='ícone de nº de acessos' />
                <Box className='information'>
                  <Box component='span'>
                    000
                  </Box>
                  <Box component='span'>
                    Acessos
                  </Box>
                </Box>
              </Box>
            </BoxCard>
          </Grid>

          <Grid item xs={3}>
            <BoxCard>
              <Box className='container'>
                <img src={AverageImage} />
                <Box className='information'>
                  <Box component='span'>
                    {data?.total_average && data?.total_average?.toFixed(1)}%
                  </Box>
                  <Box component='span'>
                    Média de desempenho
                  </Box>
                </Box>
              </Box>
            </BoxCard>
          </Grid>

          <Grid item xs={3}>
            <BoxCard>
              <Box className='container'>
                <img src={ClassroomImage} />
                <Box className='information'>
                  <Box component='span'>
                    {data?.school_classrooms ? data?.school_classrooms.length : '000'}
                  </Box>
                  <Box component='span'>
                    Turmas
                  </Box>
                </Box>
              </Box>
            </BoxCard>
          </Grid>

          <Grid item xs={3}>
            <BoxCard>
              <Box className='container'>
                <img src={SchoolImage} />
                <Box className='information'>
                  <Box component='span'>
                    000
                  </Box>
                  <Box component='span'>
                    Escolas
                  </Box>
                </Box>
              </Box>
            </BoxCard>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          background: '#ffffff',
          borderRadius: '16px',
          marginTop: '32px',
          padding: '48px 24px'
        }}
      >
        <PerformanceChartView />
      </Box>

      <Box
        sx={{
          background: '#ffffff',
          borderRadius: '16px',
          marginTop: '16px',
          padding: '48px 24px'
        }}
      >
        <PerformanceStudentsView bookId={Number(id)} />
      </Box>

      <Box
        sx={{
          background: '#ffffff',
          borderRadius: '16px',
          marginTop: '16px',
          padding: '32px 24px'
        }}
      >
        <PerformanceClassroomView />
      </Box>
    </Box>
  )
}

export default BooksReportContainer
