import React, { useState } from 'react'
import { Box, Collapse, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Chapter } from '../../../containers/data.mockup'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChapterIcon from '../../../assets/components/Books/book-icon.svg'
import CheckIcon from '../../../assets/components/Books/check-icon.svg'
import ErrorIcon from '../../../assets/components/Books/error-icon.svg'
import { formatTime } from '../../../utils/functions'

const ChapterName = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',

  '& span': {
    fontSize: '16px',
    lineHeight: '150%'
  },

  '& .chapter_number': {
    color: theme.palette.primary.main,
    fontWeight: 700
  },

  '& .chapter_name': {
    color: theme.palette.text.primary,
    fontWeight: 500
  }
}))

const ModuleName = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',

  '& span': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '150%'
  },

  '& .module_number': {
    fontWeight: 700
  },

  '& .module_name': {
    fontWeight: 500
  }
}))

const TableHeader = styled(Grid)(({ theme }) => ({
  paddingRight: '24px',
  '& .table-header_cell': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%',
  },

  '& .table-header_cell:not(.table-header_cell:first-of-type)': {
    textAlign: 'center'
  },

  '& .module': {
    fontSize: '18px',
    letterSpacing: '0.18px'
  }
}))

const Table = styled(Grid)(() => ({
  alignItems: 'center',
  background: '#ffffff',
  borderRadius: '8px',
  padding: '18px 24px',

  '& .table_cell': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%'
  },

  '& .table_cell:not(.table_cell:first-of-type)': {
    textAlign: 'center',
  }
}))

const BoxDifficulty = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  gap: '4px',
  'span': {
    backgroundColor: '#d1d1d1',
    height: '16px',
    width: '6px',
    display: 'block',
    borderRadius: '4px',
    ':nth-of-type(1)': {
      height: '8px',
    },
    ':nth-of-type(2)': {
      height: '10px',
    },
    ':nth-of-type(3)': {
      height: '12px',
    },
    ':nth-of-type(4)': {
      height: '14px',
    },
  },
  '&.very_easy': {
    'span': {
      ':nth-of-type(1)': {
        backgroundColor: '#412A88',
      },
    },
  },
  '&.easy': {
    'span': {
      ':nth-of-type(1), :nth-of-type(2)': {
        backgroundColor: '#04AEC9',
      },
    },
  },
  '&.moderate': {
    'span': {
      ':nth-of-type(1), :nth-of-type(2), :nth-of-type(3)': {
        backgroundColor: '#BC9BFA',
      },
    },
  },
  '&.difficult': {
    'span': {
      ':nth-of-type(1), :nth-of-type(2), :nth-of-type(3), :nth-of-type(4)': {
        backgroundColor: '#60DFC8',
      },
    },
  },
  '&.very_difficulty': {
    'span': {
      ':nth-of-type(1), :nth-of-type(2), :nth-of-type(3), :nth-of-type(4), , :nth-of-type(5)': {
        backgroundColor: '#F69E9E',
      },
    },
  },
}))

const Difficulty = ({ level }: any) => {
  let difficulty= ''
  switch (level) {
    case 'very_easy':
      difficulty = 'Muito fácil'
      break
    case 'easy':
      difficulty = 'Fácil'
      break
    case 'moderate':
      difficulty = 'Moderado'
      break
    case 'difficult':
      difficulty = 'Difícil'
      break
    case 'very_difficulty':
      difficulty = 'Muito difícil'
      break
    default:
      difficulty = ''
      break
  }

  return (
    <BoxDifficulty className={level} title={difficulty}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </BoxDifficulty>
  )
}

interface IChapterReportCollapseProps {
  chapter: Chapter
  numberOfChapter: number
}

const ChapterReportCollapse: React.FC<IChapterReportCollapseProps> = ({ chapter, numberOfChapter }) => {
  const [open, setOpen] = useState(false)

  return (
    <Box
      sx={{
        background: '#f9f9f9',
        borderRadius: '8px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px'
        }}
        onClick={() => setOpen(prevState => !prevState)}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '8px'
          }}
        >
          <img src={ChapterIcon} />
          <ChapterName>
            <Box
              component='span'
              className='chapter_number'
            >
              Capítulo {numberOfChapter} -
            </Box>

            <Box
              component='span'
              className='chapter_name'
            >
              {chapter.name}
            </Box>
          </ChapterName>
        </Box>

        <Box sx={{ textAlign: 'right' }}>
          <KeyboardArrowDownIcon
            fontSize='small'
            className='arrow_icon'
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </Box>
      </Box>

      <Collapse in={open}>
        <Box sx={{ padding: '0px 24px 32px' }}>
          <TableHeader container columnSpacing={1}>
            <Grid item xs={8} className='table-header_cell module'>Módulos</Grid>
            <Grid item xs={1} className='table-header_cell'>Status</Grid>
            <Grid item xs={1} className='table-header_cell'>Capítulo</Grid>
            <Grid item xs={1} className='table-header_cell'>Dificuldade</Grid>
            <Grid item xs={1} className='table-header_cell'>Tempo</Grid>
          </TableHeader>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              marginTop: '16px'
            }}
          >
            {chapter.modules.map((module, index) => {
              return (
                <Table container key={module.id} columnSpacing={1}>
                  <Grid item xs={8} className='table_cell'>
                    <ModuleName>
                      <Box component='span' className='module_number'>
                        Módulo {index + 1} -
                      </Box>
                      <Box component='span' className='module_name'>
                        {module.name}
                      </Box>
                    </ModuleName>
                  </Grid>

                  <Grid item xs={1} className='table_cell'>
                    {module.status === 'finished'
                      ? <img src={CheckIcon} title='Finalizada' />
                      : <img src={ErrorIcon} title='Não Finalizada' />
                    }
                  </Grid>

                  <Grid item xs={1} className='table_cell'>
                    {numberOfChapter}
                  </Grid>

                  <Grid item xs={1} className='table_cell'>
                    <Difficulty level={module.difficulty_level} />
                  </Grid>

                  <Grid item xs={1} className='table_cell'>
                    {formatTime(module.time)}
                  </Grid>
                </Table>
              )
            })}
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default ChapterReportCollapse
