import React from 'react'
import { Box, CircularProgress, Grid, Popover, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ReactComponent as ClassroomIcon } from '../../assets/components/HomePage/classroom-icon.svg'
import { ReactComponent as PeopleIcon } from '../../assets/components/HomePage/people.svg'
import { ReactComponent as ActivityIcon } from '../../assets/components/HomePage/activity-icon.svg'
import { ReactComponent as InfoIcon } from '../../assets/components/HomePage/info-icon.svg'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'

export interface ClassRoomType {
  id: number
  name: string
  shift: string
  grade: ClassroomGradeType
  term: ClassroomTermType
  users: User[]
  number_of_activities: number
}

export interface ClassroomGradeType {
  id: number
  name: string
}
export interface ClassroomTermType {
  id: number
  name: string
}
export interface User {
  id: number
  name: string
  email: string
  active: boolean
  access_type: number
  genre: string | null
  birthday: string
  photo_url: string | null
  token: string | null
  fractal_id: number
  facebook_uuid: string | null
}

interface ClassroomListProps {
  isFetching?: boolean
  data?: ClassRoomType[]
}

const IconBtn = styled('button')({
  background: 'none',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background 0.3s ease-in-out',
  '& path': {
    fill: '#9F9F9F'
  },
  '&:hover': {
    background: '#0000000a'
  }
})

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxHeight: '290px',
  paddingRight: '3px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: '#ccc #f9f9f9',

  '&::-webkit-scrollbar': {
    width: '3px'
  },

  '&::-webkit-scrollbar-track': {
    background: '#f9f9f9'
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#BC9BFA',
    borderRadius: '20px',
  },
})

const ClassroomList = ({ data, isFetching }: ClassroomListProps) => {
  const match = useMediaQuery('(max-width:1200px)')
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const openMore = Boolean(anchorEl)

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMore = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ marginTop: '32px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '8px' }}>
        <ClassroomIcon width={20} />
        <Typography component='h3' sx={{ fontSize: '18px', fontWeight: 600, color: '#666666', letterSpacing: '0.18px' }}>Minhas Turmas</Typography>
      </Box>

      <Container>

        {isFetching && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
            <CircularProgress />
          </Box>
        )}

        {!isFetching && data && data?.length === 0 && (
          <Typography
            sx={{ paddingLeft: '16px', color: '#666666', fontSize: '14px', fontWeight: 500, lineHeight: '120%' }}
          >
            Você não possui turmas nessa escola
          </Typography>
        )}

        {!isFetching && !match && data && data.map((classroom: ClassRoomType) => (
          <Grid container key={classroom.id} sx={{ padding: '10px 18px', background: '#fff', borderRadius: '16px' }}>
            <Grid item xs={3}>
              <Box sx={{ padding: '5px 18px', background: '#BC9BFA', borderRadius: '8px', width: 'fit-content' }}>
                <Typography sx={{ fontSize: '14px', color: '#f9f9f9' }}>{classroom.name}</Typography>
              </Box>
            </Grid>

            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ fontSize: '14px', fontWeight: 500, color: '#666666', textAlign: 'center' }}>
                {classroom.grade.name}
              </Box>
            </Grid>

            <Grid item xs={3} sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
              <PeopleIcon width={15} />
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#666666' }}>
                {classroom?.users?.length +
                  (classroom?.users?.length > 1 ? ' alunos' : ' aluno')}
              </Typography>
            </Grid>

            <Grid item xs={3} sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
              <ActivityIcon width={10} />
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#666666' }}>
                {classroom.number_of_activities +
                  (classroom.number_of_activities > 1 ? ' atividades' : ' atividade')}
              </Typography>
            </Grid>
          </Grid>
        ))}

        {!isFetching && match && data && data.map((classroom: ClassRoomType) => (
          <Box key={classroom.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 18px', background: '#fff', borderRadius: '16px' }}>
            <Box sx={{ padding: '5px 18px', background: '#BC9BFA', borderRadius: '8px' }}>
              <Typography sx={{ fontSize: '14px', color: '#f9f9f9' }}>{classroom.name}</Typography>
            </Box>

            <IconBtn
              id={`moreActionsButton[${classroom.id}]`}
              aria-describedby={`infoPopover[${classroom.id}]`}
              aria-controls={openMore ? `moreActionsmenu[${classroom.id}]` : undefined}
              aria-haspopup='true'
              aria-expanded={openMore ? 'true' : undefined}
              onClick={handleOpenMore}
            >
              <InfoIcon />
            </IconBtn>
            <Popover
              id={`infoPopover[${classroom.id}]`}
              open={anchorEl?.getAttribute('aria-describedby') === `infoPopover[${classroom.id}]`}
              anchorEl={anchorEl}
              onClose={handleCloseMore}
              sx={{
                '& .MuiPopover-paper': {
                  border: '1px solid #D9D9D9',
                  borderRadius: '8px',
                  width: 200,
                  padding: '20px'
                }
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', fontSize: '14px', fontWeight: 500, color: '#666666', marginBottom: '8px' }}>
                <SchoolOutlinedIcon sx={{ color: '#D9D9D9', fontSize: 18 }} />
                {classroom.grade.name}
              </Box>

              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', marginBottom: '8px' }}>
                <PeopleIcon width={15} />
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#666666' }}>
                  {classroom?.users?.length +
                    (classroom?.users?.length > 1 ? ' alunos' : ' aluno')}
                </Typography>
              </Box>

              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                <ActivityIcon width={10} />
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#666666' }}>
                  {classroom.number_of_activities +
                    (classroom.number_of_activities > 1 ? ' atividades' : ' atividade')}
                </Typography>
              </Box>
            </Popover>
          </Box>
        ))}
      </Container>
    </Box>
  )
}

export default ClassroomList
