import React, { useCallback, useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { debounce, isEmpty } from 'lodash'
import { format } from 'date-fns'
import Box from '@mui/system/Box'
import { FormGroup, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch } from '../../store/hooks'
import { FormValues } from '../Activities/form/ActivitiesSettingsForm'
import SearchInput from '../input/SearchInput'
import AsyncSelectInput from '../select/AsyncSelectInput'
import DatePickerInput from '../input/DatePickerInput'
import Button from '../button/Button'
import Label from '../label/Label'
import CheckboxInput from '../input/CheckboxInput'
import FiltersSelect from '../select/FiltersSelect'
import ModalDelete from '../Modal/ModalDelete'
import ModalSaveFilter from '../Modal/ModalSaveFilter'
import FiltersContainer, { ButtonContainer, SearchContainer } from '../Activities/FiltersContainer'
import {
  fetchExamsRequest,
  examsResetFilters
} from '../../store/exams/actions'
import {
  saveFiltersRequest,
  updateFiltersRequest,
  getSavedFiltersRequest,
  deleteFiltersRequest
} from '../../store/filters'
import { showAlertMessage } from '../../store/alert'

type Props = {
  userId?: any
  isTeacher?: any
  values?: FormValues
  change?: any
  filters?: any
  perPage?: number
  setPage?: any
  showYourFilters?: boolean
  chosenFilter?: string
  setChosenFilter?: any
  invalid?: boolean
}

const ExamsFilter = ({ isTeacher, userId, values, change, filters, perPage, setPage, showYourFilters, chosenFilter, setChosenFilter, invalid }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [openFilterNameModal, setOpenFilterNameModal] = useState(false)
  const [openDeleteFilterModal, setOpenDeleteFilterModal] = useState<boolean>(false)
  const [filterSelectedToDelete, setFilterSelectedToDelete] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useAppDispatch()
  const examsFilters = filters.items?.exams

  useEffect(() => {
    dispatch(getSavedFiltersRequest({ id: userId }))
    return () => {
      change('examsFilters', undefined)
      if (typeof setChosenFilter === 'function') {
        setChosenFilter('')
      }
      dispatch(examsResetFilters())
    }
  }, [])

  useEffect(() => {
    if (isChecked && values && values?.examsFilters?.terms?.length > 0) {
      setSearchTerm(values?.examsFilters?.terms ?? '')
    } else {
      setSearchTerm(values?.examsFilters?.terms ?? '')
    }
  }, [isChecked, values?.examsFilters])

  const handleOpenConfirmModal = () => {
    (!isEmpty(values?.examsFilters) || searchTerm) && setOpenFilterNameModal(true)
  }

  const handleCloseConfirmModal = () => {
    setOpenFilterNameModal(false)
    change('filterName', null)
  }

  const handleFiltersChange = (value: any, filterName: string) => {
    let queryValue
    if (Array.isArray(value)) {
      queryValue = value.map(data => data.value || data.id).join()
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      queryValue = value.value || value.id
    } else {
      queryValue = value
    }
    setPage(1)
    if (queryValue) {
      const params = { [filterName]: queryValue, page: 1 }
      dispatch(fetchExamsRequest(params))
    } else {
      const params = { [filterName]: undefined, page: 1 }
      dispatch(fetchExamsRequest(params))
    }
  }

  useEffect(() => {
    if (values?.examsFilters) {
      Object.entries(values.examsFilters).forEach(([key, value]) => {
        if (key && value !== null && value !== undefined) {
          key === 'years_of_admission' && value !== null
            ? handleFiltersChange(format(value, 'yyyy'), key)
            : handleFiltersChange(value, key)
        }
      })
    }
  }, [values?.examsFilters])

  useEffect(() => {
    filters?.isFilterSaved && handleCloseConfirmModal()
  }, [filters?.isFilterSaved])

  const changeFilterForm = useCallback(debounce((filter, value) => {
    change(filter, value)
  }, 500), [])

  const handleSearchByTerms: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    setSearchTerm(value)
    setPage(1)
    changeFilterForm('examsFilters.terms', value)
  }

  const cleanExamsFilters = () => {
    change('examsFilters', undefined)
    setSearchTerm('')
    setPage(1)
    dispatch(examsResetFilters())
    dispatch(fetchExamsRequest({ per: perPage, page: 1 }))
    if (typeof setChosenFilter === 'function') {
      setChosenFilter('')
    }
  }

  const handleSaveFilters = () => {
    if (!values?.filterName) {
      dispatch(showAlertMessage({
        message: 'Digite um nome para o seu filtro',
        severity: 'warning'
      }))
      return
    }

    if (examsFilters && Object.keys(examsFilters).find(item => item === values?.filterName)) {
      dispatch(showAlertMessage({
        message: 'Nome já em uso.',
        severity: 'warning'
      }))
      return
    }

    const params = {
      controller_name: 'exams',
      filter_name: values?.filterName,
      current_filters: { ...values?.examsFilters }
    }

    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key] === undefined || params[key].length === 0) {
        delete params[key]
      }
    })

    if (!filters.items?.exams) {
      dispatch(saveFiltersRequest({ id: userId, params }))
    } else {
      dispatch(updateFiltersRequest({ id: userId, params }))
    }
    cleanExamsFilters()
  }

  const handleCheckboxExamFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    cleanExamsFilters()
    setIsChecked(checked)
    if (checked && typeof setChosenFilter === 'function') {
      setChosenFilter(event.target.name)
    } else {
      typeof setChosenFilter === 'function' && setChosenFilter('')
    }
  }

  const handleOpenDeleteFilterModal = (filterName: string) => {
    setOpenDeleteFilterModal(true)
    setFilterSelectedToDelete(filterName)
  }

  const handleCloseDeleteFilterModal = () => {
    setOpenDeleteFilterModal(false)
  }

  const handleDeleteFilters = (name: string) => {
    const params = {
      controller_name: 'exams',
      filter_name: name,
    }
    dispatch(deleteFiltersRequest({ id: userId, params: { ...params } }))
    cleanExamsFilters()
    handleCloseDeleteFilterModal()
  }

  const checkboxExamsFilter = (
    <FormGroup key='exams_checkbox' sx={{ display: 'flex', flexDirection: matches ? 'row' : 'column', gap: 1, flexWrap: 'wrap' }}>
      {examsFilters && Object.keys(examsFilters).map((item, index) => (
        <Box key={`${item}-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckboxInput
            key={item}
            value={item}
            name={item}
            onChange={handleCheckboxExamFilters}
            label={item}
            labelPlacement='end'
            checked={isChecked && chosenFilter === item}
          />
          <IconButton
            color='error'
            sx={{ width: '16px', height: '16px' }}
            onClick={() => handleOpenDeleteFilterModal(item)}
            title='Excluir filtro'
          >
            <CancelIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
      ))}
    </FormGroup>
  )

  return (
    <>
      <FiltersContainer>
        <SearchContainer>
          <Label>Filtros de pesquisa</Label>
          <SearchInput
            value={searchTerm}
            name='examsFilters.terms'
            onChange={handleSearchByTerms}
            placeholder='Termos inclusos...'
            width={matches ? '280px' : '227px'}
            backgroundColor='#F0F3F4'
            className='termsFilter'
          />
        </SearchContainer>

        <Grid container columnSpacing={2} rowSpacing={2} sx={{ maxWidth: matches ? '100%' : '240px' }}>
          <Grid item xs={4} md={12}>
            <Field
              name='examsFilters.concourse_ids'
              component={AsyncSelectInput}
              placeholder='Selecione...'
              label='Processo seletivo'
              request={{
                path: '/concourses',
                params: {
                  paginate: true
                }
              }}
            />
          </Grid>

          <Grid item xs={4} md={12}>
            <Field
              name='examsFilters.years_of_admission'
              component={DatePickerInput}
              placeholder='Selecione o ano...'
              label='Ano admissão'
              showYearPicker
              maxDate={new Date()}
              dateFormat='yyyy'
              className='datePicker'
            />
          </Grid>

          <Grid item xs={4} md={12}>
            <Field
              component={AsyncSelectInput}
              placeholder='Selecione a fase...'
              label='Fase'
              name='examsFilters.phase_ids'
              hasBorder
              isMulti
              searchParam='name'
              request={{
                path: '/concourse_exam_phases',
                params: {
                  paginate: true
                }
              }}
            />
          </Grid>

          <Grid item xs={4} md={12}>
            <Field
              component={FiltersSelect}
              placeholder='Selecione a temporada'
              label='Temporada'
              name='examsFilters.season_ids'
              hasBorder
              isMulti
              request={{
                path: '/seasons'
              }}
            />
          </Grid>

          <Grid item xs={4} md={12}>
            <Field
              name='examsFilters.subject_ids'
              component={AsyncSelectInput}
              placeholder='Selecione a disciplina...'
              label='Disciplina'
              searchParam='name'
              request={{
                path: '/subjects',
                params: {
                  basic: true,
                  teacher_user_id: isTeacher ? userId : null
                }
              }}
            />
          </Grid>

          <Grid item xs={4} md={12}>
            <Field
              component={FiltersSelect}
              placeholder='Nível'
              label='Nível'
              name='examsFilters.level_ids'
              hasBorder
              isMulti
              request={{
                path: '/concourse_exam_levels',
                params: {
                  paginate: true,
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              component={FiltersSelect}
              placeholder='Selecione a área'
              label='Área de conhecimento'
              name='examsFilters.area_ids'
              hasBorder
              isMulti
              request={{
                path: '/concourse_exam_areas',
                params: {
                  paginate: true,
                }
              }}
            />
          </Grid>
        </Grid>

        {showYourFilters && examsFilters && Object.keys(examsFilters).length > 0 && (
          <>
            <Box sx={{ display: 'flex', mt: matches ? 3 : 2 }}>
              <Typography
                sx={{
                  color: '#9F9F9F',
                  mr: 2,
                  fontSize: 14,
                  fontWeight: 700
                }}
                variant='body2'
                component='span'
              >
                Filtros:
              </Typography>
              {checkboxExamsFilter}
            </Box>
          </>
        )}
      </FiltersContainer>

      <ButtonContainer>
        <Button
          sx={{ mt: 2 }}
          variant='outlined'
          onClick={cleanExamsFilters}
          disabled={!values?.examsFilters}
        >
          Limpar Filtro
        </Button>

        <Button
          sx={{ mt: 2 }}
          onClick={handleOpenConfirmModal}
          disabled={!values?.examsFilters}
        >
          Salvar Filtro
        </Button>
      </ButtonContainer>

      <ModalSaveFilter
        isOpen={openFilterNameModal}
        closeModal={handleCloseConfirmModal}
        action={handleSaveFilters}
        disableAction={invalid}
      />

      <ModalDelete
        isOpen={openDeleteFilterModal}
        closeModal={handleCloseDeleteFilterModal}
        action={() => handleDeleteFilters(filterSelectedToDelete)}
        type='filter'
      />
    </>
  )
}

export default ExamsFilter
