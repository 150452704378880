import React, { useEffect, useRef, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FieldRenderProps } from 'react-final-form'
import CancelIcon from '@mui/icons-material/Cancel'
import { ReactComponent as Image } from '../../assets/components/Books/image-icon.svg'

const ButtonContainer = styled(Box)(() => ({
  alignItems: 'center',
  border: '1px dashed #d9d9d9',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '8px',
  height: '100%',
  justifyContent: 'center',
  transition: 'background 0.3s ease-in-out',

  '&:hover': {
    background: '#f9f9f9',
  },

  '& .information': {
    paddingInline: '30px',

    '& p': {
      color: '#9f9f9f',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '150%',
      maxWidth: '182px',
      marginInline: 'auto',
      textAlign: 'center',
    }
  }
}))

type ImputImageProps = FieldRenderProps<string, any>

const InputImage: React.FC<ImputImageProps> = ({
  label,
  input,
  photoObject,
  change
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const buttonInputRef = useRef<HTMLDivElement>(null)
  const [typePhoto, setTypePhoto] = useState('')

  useEffect(() => {
    switch (typeof photoObject === 'string') {
      case photoObject?.includes('png'):
        setTypePhoto('.png')
        break
      case photoObject?.includes('jpeg'):
        setTypePhoto('.jpeg')
        break
      case photoObject?.includes('jpg'):
        setTypePhoto('.jpg')
        break
      default:
        setTypePhoto('')
    }
  }, [])

  const handleFileClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    input.onChange(file)
  }

  const handleClearFile = () => {
    change(input.name, '')
  }

  const ButtonUpload = () => {
    return (
      <ButtonContainer
        onClick={(e) => {
          e.preventDefault()
          handleFileClick()
        }}
        ref={buttonInputRef}
      >
        <Box className='icon'>
          <Image />
        </Box>
        <Box className='information'>
          <Typography>
            Envie arquivos até 10MB: jpg, jpeg, png. Recomendado: 228x358px.
          </Typography>
        </Box>
      </ButtonContainer>
    )
  }

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      {label &&
        <Typography
          sx={{
            color: '#9F9F9F',
            mb: 1,
            fontSize: 14,
            fontWeight: 500
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <input
          type='file'
          accept='image/png, image/jpeg, image/jpg '
          name={input.name}
          onChange={handleSendFile}
          ref={inputRef}
          style={{ display: 'none' }}
        />
        <ButtonUpload />
        {photoObject
          ? (
            <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
              <img
                src={
                  typeof photoObject === 'string'
                    ? photoObject
                    : URL.createObjectURL(photoObject)
                }
                alt='preview da capa do livro'
                width='24px'
              />
              <Typography
                sx={{
                  color: '#9f9f9f',
                  fontSize: '14px',
                  maxWidth: '180px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                title={photoObject?.name ?? `capa${typePhoto}`}
              >
                {photoObject?.name ?? `capa${typePhoto}`}
              </Typography>

              <IconButton
                color='error'
                onClick={handleClearFile}
                title='Excluir capa'
              >
                <CancelIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Box>
          )
          : ''
        }
      </Box>
    </Box>
  )
}

export default InputImage
