import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material'
import ChapterReportCollapse from './ChapterReportCollapse'
import { styled, useTheme } from '@mui/material/styles'
import { Student } from '../../../containers/data.mockup'
import { formatTime, stringAvatar } from '../../../utils/functions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ProgressIcon from '../../../assets/components/Books/progress-dark-icon.svg'
import ClockIcon from '../../../assets/components/Books/clock-icon.svg'
import ChartIcon from '../../../assets/components/Books/chart-icon.svg'

const Value = styled(Typography)<any>(({ theme, textAlign }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '120%',
  textAlign: textAlign ?? 'center'
}))

const ProgressBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: '0 1 320px',
  gap: '6px',

  '& .label, & .value': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '120%'
  },

  '& .label': {
    fontWeight: 600
  },

  '& .progress': {
    fontWeight: 500
  }
}))

const TimeBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& .label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%'
  },

  '& .value': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#fff',
    display: 'block',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '5.4px 18px',
    width: 'fit-content'
  }
}))

const PerformanceBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '6px',

  '& .label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%'
  },

  '& .value': {
    background: theme.palette.secondary.main,
    borderRadius: '8px',
    color: '#ffffff',
    display: 'block',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '5.4px 18px',
    width: 'fit-content'
  }
}))

interface IStudentReportCollapseProps {
  studentData: Student
}

const StudentReportCollapse: React.FC<IStudentReportCollapseProps> = ({ studentData }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const totalModules = studentData.chapters.reduce((total, item) => {
    const numberOfModules = item.modules.length
    total += numberOfModules
    return total
  }, 0)

  return (
    <Box
      sx={{
        background: '#ffffff',
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
          padding: '8px 24px'
        }}
        onClick={() => setOpen(prevState => !prevState)}
      >
        <Grid container alignItems='center'>
          <Grid item xs={4}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '12px'
              }}
            >
              <Avatar
                alt={studentData.name}
                variant='rounded'
                {...stringAvatar(studentData.name, 48, 48, 14)}
              />

              <Box>
                <Typography
                  sx={{
                    color: theme.palette.text.primary,
                    fontSize: '18px',
                    fontWeight: 700,
                    letterSpacing: '0.18px'
                  }}
                >
                  {studentData.name}
                </Typography>

                <Typography
                  sx={{
                    color: '#9f9f9f',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '0.14px',
                    lineHeight: '100%'
                  }}
                >
                  {studentData.school.grade.name} / {studentData.school.name}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Value>
              {studentData.start_date}
            </Value>
          </Grid>

          <Grid item xs={2}>
            <Value>
              {studentData.end_date}
            </Value>
          </Grid>

          <Grid item xs={2}>
            <Value>
              {studentData.activities_finished} / {totalModules}
            </Value>
          </Grid>

          <Grid item xs={1}>
            <Value>
              {formatTime(studentData.total_time)}
            </Value>
          </Grid>

          <Grid item xs={1}>
            <Box sx={{ textAlign: 'right' }}>
              <KeyboardArrowDownIcon
                fontSize='small'
                className='arrow_icon'
                sx={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Collapse in={open}>
        <Box sx={{ marginTop: '24px', padding: '0 24px 24px' }}>
          <Box
            sx={{
              border: `1px dashed ${theme.palette.text.secondary}`,
              marginBottom: '32px'
            }}
          />

          <Typography
            component='h4'
            sx={{
              color: theme.palette.text.primary,
              fontSize: '18px',
              fontWeight: 600,
              letterSpacing: '0.18px'
            }}
          >
            Resultado
          </Typography>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '6px',
              justifyContent: 'space-between',
              marginTop: '22px'
            }}
          >
            <ProgressBox>
              <img
                src={ProgressIcon}
                className='icon'
              />

              <Box
                component='span'
                className='label'
              >
                Progresso
              </Box>

              <LinearProgress
                sx={{
                  backgroundColor: '#e1e1e1',
                  maxWidth: '170px',
                  width: '100%'
                }}
                color='secondary'
                value={studentData.progress}
                variant='determinate'
              />

              <Box
                component='span'
                className='progress'
                sx={{
                  color: '#666666',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '120%'
                }}
              >
                {studentData.progress}%
              </Box>
            </ProgressBox>

            <TimeBox>
              <img src={ClockIcon} />
              <Box
                component='span'
                className='label'
              >
                Tempo de leitura
              </Box>
              <Box
                component='span'
                className='value'
              >
                {formatTime(studentData.total_time)}
              </Box>
            </TimeBox>

            <PerformanceBox>
              <img src={ChartIcon} />
              <Box
                component='span'
                className='label'
              >
                Performance
              </Box>
              <Box
                component='span'
                className='value'
              >
                {studentData.performance}%
              </Box>
            </PerformanceBox>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              marginTop: '32px'
            }}
          >
            {studentData.chapters.map((chapter, index) => {
              return (
                <ChapterReportCollapse
                  key={chapter.id}
                  chapter={chapter}
                  numberOfChapter={index + 1}
                />
              )
            })}
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default StudentReportCollapse
