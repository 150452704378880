import React from 'react'
import BooksReportStudentsPerformanceContainer from '../containers/BooksReportStudentsPerformanceContainer'

const BooksReportStudentsPerformancePage = () => {
  return (
    <div>
      <BooksReportStudentsPerformanceContainer />
    </div>
  )
}

export default BooksReportStudentsPerformancePage
