import React, { useCallback, useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { debounce, isEmpty } from 'lodash'
import { format } from 'date-fns'
import {
  styled,
  useTheme
} from '@mui/material/styles'
import Box from '@mui/system/Box'
import {
  FormGroup,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import SearchInput from '../input/SearchInput'
import AsyncSelectInput from '../select/AsyncSelectInput'
import CheckboxInput from '../input/CheckboxInput'
import Button from '../button/Button'
import Label from '../label/Label'
import ModalSaveFilter from '../Modal/ModalSaveFilter'
import ModalDelete from '../Modal/ModalDelete'
import FiltersSelect from '../select/FiltersSelect'
import SelectSubjectCombo from '../select/SelectSubjectCombo'
import FiltersContainer, { ButtonContainer, SearchContainer } from '../Activities/FiltersContainer'
import { FormValues } from '../Activities/form/ActivitiesSettingsForm'
import {
  questionItemInActivityFetch,
  schoolQuestionItemsFetchRequest,
  setQuestionCheckboxes,
  questionResetFilter
} from '../../store/questions/actions'
import { highSchoolSegmentId } from '../../utils/constants'
import {
  getSavedFiltersRequest,
  saveFiltersRequest,
  updateFiltersRequest,
  deleteFiltersRequest
} from '../../store/filters'
import { segmentsFetchRequest } from '../../store/education'
import { showAlertMessage } from '../../store/alert'

const questionFiltersPedagogicFields = [
  'theme_ids',
  'question_types',
  'difficulty_levels',
  'use_universe_ids',
  'segment_ids',
  'questions_types_advanced',
  'format_ids'
]

const questionsFiltersAdvancedFields = [
  'years_of_admission',
  'season_ids',
  'exam_model_ids',
  'phase_ids',
  'concourse_segment_ids',
  'grade_ids',
  'region_ids',
  'state_ids',
  'years',
  'author_ids',
  'with_resolution',
  'area_ids',
  'level_ids',
]

const LabelStyled = styled('label')({
  alignItems: 'center',
  color: '#9f9f9f',
  display: 'flex',
  fontSize: '14px',
  fontWeight: 500,
  gap: '8px',
})

type Props = {
  userId?: any
  isTeacher?: any
  values?: FormValues
  showAllFilters?: boolean
  showYourFilters?: boolean
  isSchoolQuestions?: boolean
  isCustomQuestion?: boolean
  change?: any
  filters?: any
  unitId?: number // eslint-disable-line
  perPage?: number
  setPage?: any
  chosenFilter?: string
  setChosenFilter?: any
  invalid?: boolean
}

const QuestionsFilter = ({ isTeacher, userId, values, showAllFilters, showYourFilters, isSchoolQuestions, isCustomQuestion, change, filters, perPage, setPage, chosenFilter, setChosenFilter, invalid }: Props) => {
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useAppDispatch()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchId, setSearchId] = useState<string>('')
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [openFilterNameModal, setOpenFilterNameModal] = useState(false)
  const [openDeleteFilterModal, setOpenDeleteFilterModal] = useState<boolean>(false)
  const [filterSelectedToDelete, setFilterSelectedToDelete] = useState<string>('')
  const { segments: { items } } = useAppSelector(state => state.education)

  const segmentsFiltered = items?.filter((item) => item.id !== 1)
  const segmentOptions = segmentsFiltered?.map((item) => ({
    id: item.id,
    name: item.name
  }))

  const handleOpenConfirmModal = () => {
    (!isEmpty(values?.questionsFilters) || searchTerm || searchId) && setOpenFilterNameModal(true)
    isCustomQuestion && (!isEmpty(values?.customQuestionsFilters) || searchTerm || searchId) && setOpenFilterNameModal(true)
  }

  const handleCloseConfirmModal = () => {
    setOpenFilterNameModal(false)
    change('filterName', null)
  }
  const [checkboxFilters, setCheckboxFilters] = useState<any>({
    'pedagogic': false,
    'advanced': false
  })

  useEffect(() => {
    dispatch(segmentsFetchRequest())
    dispatch(getSavedFiltersRequest({ id: userId }))
    return () => {
      change('customQuestionsFilters', undefined)
      change('questionsFilters', undefined)
      if (typeof setChosenFilter === 'function') {
        setChosenFilter('')
      }
      dispatch(questionResetFilter())
    }
  }, [])

  useEffect(() => {
    if (isChecked && values
      && (values?.questionsFilters?.terms?.length > 0 || values?.questionsFilters?.ids?.length > 0)) {
      setSearchTerm(values?.questionsFilters?.terms ?? '')
      setSearchId(values?.questionsFilters?.ids ?? '')
    } else if (isChecked && values
      && (values?.customQuestionsFilters?.terms?.length > 0 || values?.customQuestionsFilters?.ids?.length > 0)) {
      setSearchTerm(values?.customQuestionsFilters?.terms ?? '')
      setSearchId(values?.customQuestionsFilters?.ids ?? '')
    } else if (isCustomQuestion) {
      setSearchTerm(values?.customQuestionsFilters?.terms ?? '')
      setSearchId(values?.customQuestionsFilters?.ids ?? '')
    } else {
      setSearchTerm(values?.questionsFilters?.terms ?? '')
      setSearchId(values?.questionsFilters?.ids ?? '')
    }
  }, [isChecked, values?.questionsFilters, values?.customQuestionsFilters])

  const handleFiltersChange = (value: any, filterName: string) => {
    let queryValue
    if (Array.isArray(value)) {
      queryValue = value.map(data => data.value || data.id).join()
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      queryValue = value.value || value.id
    } else {
      queryValue = value
    }
    setPage(1)

    if (queryValue) {
      const params = { [filterName]: queryValue, page: 1 }
      isSchoolQuestions
        ? dispatch(schoolQuestionItemsFetchRequest(params))
        : dispatch(questionItemInActivityFetch(params))
    } else {
      const params = { [filterName]: undefined, page: 1 }
      isSchoolQuestions
        ? dispatch(schoolQuestionItemsFetchRequest(params))
        : dispatch(questionItemInActivityFetch(params))
    }
  }

  useEffect(() => {
    if (!isCustomQuestion && values?.questionsFilters) {
      Object.entries(values.questionsFilters).forEach(([key, value]) => {
        if (key && value !== null && value !== undefined) {
          if (key === 'subject_ids' && value.every((subject: any) => 'topic_ids' in subject)) {
            const topics: { id: string, name: string }[] = value.flatMap((subject: any) => subject.topic_ids)
            handleFiltersChange(topics, 'topic_ids')
          }
          key === 'years_of_application' && value !== null
            ? handleFiltersChange(format(value, 'yyyy'), key)
            : handleFiltersChange(value, key)
        }
      })
    } else if (isCustomQuestion && values?.customQuestionsFilters) {
      Object.entries(values.customQuestionsFilters).forEach(([key, value]) => {
        if (key && value !== null && value !== undefined) {
          handleFiltersChange(value, key)
          if (key === 'subject_ids' && value.every((subject: any) => 'topic_ids' in subject)) {
            const topics: { id: string, name: string }[] = value.flatMap((subject: any) => subject.topic_ids)
            handleFiltersChange(topics, 'topic_ids')
          }
        }
      })
    }
  }, [values?.questionsFilters, values?.customQuestionsFilters])

  useEffect(() => {
    filters?.isFilterSaved && handleCloseConfirmModal()
  }, [filters?.isFilterSaved])

  const changeFilterForm = useCallback(debounce((filter, value) => {
    change(filter, value)
  }, 500), [])

  const handleSearchByTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchTerm(value)
    setPage(1)
    isCustomQuestion
      ? changeFilterForm('customQuestionsFilters.terms', value)
      : changeFilterForm('questionsFilters.terms', value)
  }

  const handleSearchById = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchId(value)
    isCustomQuestion
      ? changeFilterForm('customQuestionsFilters.ids', value)
      : changeFilterForm('questionsFilters.ids', value)
  }

  const cleanQuestionsFilters = () => {
    values?.questionsFilters && Object.entries(values.questionsFilters).forEach(([key,]) => {
      change(`questionsFilters.${key}`, undefined)
    })
    isCustomQuestion && values?.customQuestionsFilters && Object.entries(values.customQuestionsFilters).forEach(([key,]) => {
      change(`customQuestionsFilters.${key}`, undefined)
    })
    if (checkboxFilters.pedagogic || checkboxFilters.advanced) {
      questionsFiltersAdvancedFields.forEach(field => change(field, null))
      questionFiltersPedagogicFields.forEach(field => change(field, null))

      setCheckboxFilters({
        'pedagogic': false,
        'advanced': false
      })
    }

    setSearchTerm('')
    setSearchId('')
    if (typeof setChosenFilter === 'function') {
      setChosenFilter('')
    }
    setPage(1)
    setIsChecked(false)
    dispatch(questionResetFilter())
    dispatch(setQuestionCheckboxes([]))
    isSchoolQuestions
      ? dispatch(schoolQuestionItemsFetchRequest({ per: perPage, page: 1 }))
      : dispatch(questionItemInActivityFetch({ per: perPage, page: 1 }))
  }

  const handleCheckboxQuestionFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    cleanQuestionsFilters()
    setIsChecked(checked)
    if (checked && typeof setChosenFilter === 'function') {
      setChosenFilter(event.target.name)
    } else {
      typeof setChosenFilter === 'function' && setChosenFilter('')
    }
  }

  const handleSaveFilters = () => {
    if (!values?.filterName) {
      dispatch(showAlertMessage({
        message: 'Digite um nome para o seu filtro',
        severity: 'warning'
      }))
      return
    }
    if (isCustomQuestion && filters.items?.custom_questions && Object.keys(filters.items?.custom_questions).find(item => item === values?.filterName)) {
      dispatch(showAlertMessage({
        message: 'Nome já em uso.',
        severity: 'warning'
      }))
      return
    }
    if (!isCustomQuestion && filters.items?.questions && Object.keys(filters.items?.questions).find(item => item === values?.filterName)) {
      dispatch(showAlertMessage({
        message: 'Nome já em uso.',
        severity: 'warning'
      }))
      return
    }

    let params: any
    if (isCustomQuestion) {
      params = {
        controller_name: 'custom_questions',
        filter_name: values?.filterName,
        current_filters: { ...values?.customQuestionsFilters }
      }
    } else {
      params = {
        controller_name: 'questions',
        filter_name: values?.filterName,
        current_filters: { ...values?.questionsFilters }
      }
    }

    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key].length === 0) {
        delete params[key]
      }
    })
    Object.keys(checkboxFilters).forEach(key => {
      if (checkboxFilters[key] === false) {
        delete checkboxFilters[key]
      }
    })
    if (!isCustomQuestion && !filters.items?.questions) {
      dispatch(saveFiltersRequest({ id: userId, params: { ...params, ...checkboxFilters } }))
    } else if (isCustomQuestion && !filters.items?.custom_questions) {
      dispatch(saveFiltersRequest({ id: userId, params: { ...params, ...checkboxFilters } }))
    } else {
      dispatch(updateFiltersRequest({ id: userId, params: { ...params, ...checkboxFilters } }))
    }
    cleanQuestionsFilters()
    setIsChecked(false)
  }

  // open the delete filter modal
  const handleOpenDeleteFilterModal = (filterName: string) => {
    setOpenDeleteFilterModal(true)
    setFilterSelectedToDelete(filterName)
  }

  // close the delete filter modal
  const handleCloseDeleteFilterModal = () => {
    setOpenDeleteFilterModal(false)
  }

  // delete the filter
  const handleDeleteFilters = (name: string) => {
    const params = {
      controller_name: isCustomQuestion ? 'custom_questions' : 'questions',
      filter_name: name,
    }
    dispatch(deleteFiltersRequest({ id: userId, params: { ...params } }))
    cleanQuestionsFilters()
    handleCloseDeleteFilterModal()
  }

  const questionsFilters = filters?.items?.questions
  const checkboxFiltersFields = (
    <FormGroup key='questions_checkbox' sx={{ display: 'flex', flexDirection: match ? 'row' : 'column', gap: 1, flexWrap: 'wrap' }}>
      {questionsFilters && Object.keys(questionsFilters).map((item, index) => (
        <Box key={`${item}-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckboxInput
            key={`${item}-${index}`}
            value={item}
            name={item}
            onChange={handleCheckboxQuestionFilters}
            label={item}
            labelPlacement='end'
            checked={isChecked && chosenFilter === item}
          />
          <IconButton
            color='error'
            sx={{ width: '16px', height: '16px' }}
            onClick={() => handleOpenDeleteFilterModal(item)}
            title='Excluir filtro'
          >
            <CancelIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
      ))}
    </FormGroup>
  )

  const customQuestionsFilters = filters?.items?.custom_questions
  const checkboxQuestionsFilter = (
    <FormGroup key='custom_questions_checkbox' sx={{ display: 'flex', flexDirection: match ? 'row' : 'column', gap: 1, flexWrap: 'wrap' }}>
      {customQuestionsFilters && Object.keys(customQuestionsFilters).map((item, index) => (
        <Box key={`${item}-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckboxInput
            key={`${item}-${index}`}
            value={item}
            name={item}
            onChange={handleCheckboxQuestionFilters}
            label={item}
            labelPlacement='end'
            checked={isChecked && chosenFilter === item}
          />
          <IconButton
            color='error'
            sx={{ width: '16px', height: '16px' }}
            onClick={() => handleOpenDeleteFilterModal(item)}
            title='Excluir filtro'
          >
            <CancelIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
      ))}
    </FormGroup>
  )

  return (
    <>
      <FiltersContainer>
        <SearchContainer>
          <Label>Filtros de pesquisa</Label>
          <SearchInput
            name={isCustomQuestion ? 'customQuestionsFilters.terms' : 'questionsFilters.terms'}
            onChange={handleSearchByTerms}
            placeholder='Termos inclusos na questão...'
            width={match ? '280px' : '227px'}
            value={searchTerm}
            backgroundColor='#F0F3F4'
            className='termsFilter'
          />
        </SearchContainer>

        <SearchInput
          border='1px solid #D9D9D9'
          borderRadius='8px'
          value={searchId}
          label='Código da questão'
          name={isCustomQuestion ? 'customQuestionsFilters.ids' : 'questionsFilters.ids'}
          onChange={handleSearchById}
          placeholder='Pesquisar por código'
          width={match ? '100%' : '227px'}
          inputType='number'
          isKeyDown
        />

        <Box sx={{ maxWidth: match ? '100%' : '230px', marginBlock: match ? '24px' : '16px' }}>
          <FieldArray name={isCustomQuestion ? 'customQuestionsFilters.subject_ids' : 'questionsFilters.subject_ids'}>
            {({ fields }) => (
              <SelectSubjectCombo
                name={isCustomQuestion ? 'customQuestionsFilters.subject_ids' : 'questionsFilters.subject_ids'}
                nameTopics='topic_ids'
                fields={fields}
                subjects={isCustomQuestion ? values?.customQuestionsFilters?.subject_ids : values?.questionsFilters?.subject_ids}
                segment={{ id: 4, name: 'Ensino Médio' }}
                isTeacher={isTeacher}
                userId={userId}
                highSchoolSegmentId={highSchoolSegmentId}
              />
            )}
          </FieldArray>
        </Box>

        <Box sx={{ maxWidth: match ? '100%' : '230px', marginBottom: match ? '24px' : '16px' }}>
          <Field
            component={FiltersSelect}
            placeholder='Segmentos...'
            label='Segmentos'
            name={isCustomQuestion ? 'customQuestionsFilters.segment_ids' : 'questionsFilters.segment_ids'}
            hasBorder
            options={segmentOptions ?? []}
          />
        </Box>

        {showAllFilters &&
          <Box>
            <Box sx={{ maxWidth: match ? '100%' : '230px', display: 'flex', flexDirection: match ? 'row' : 'column', gap: 2 }}>
              <Grid container columnSpacing={2} rowSpacing={match ? 3 : 2}>
                <Grid item xs={4} md={12}>
                  <Field
                    name='questionsFilters.concourse_ids'
                    component={AsyncSelectInput}
                    placeholder='Selecione...'
                    label='Processo seletivo'
                    request={{
                      path: '/concourses',
                      params: {
                        paginate: true
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={4} md={12}>
                  <Field
                    component={FiltersSelect}
                    placeholder='Tipo de questões'
                    label='Tipo de questões'
                    name='questionsFilters.question_types'
                    hasBorder
                    isMulti
                    options={[
                      // { id: 1, name: 'Discursiva', value: 'discursive' },
                      { id: 2, name: 'Objetiva', value: 'only_choice' }
                    ]}
                  />
                </Grid>

                <Grid item xs={4} md={12}>
                  <Field
                    component={FiltersSelect}
                    placeholder='Nível de dificuldade'
                    label='Nível de dificuldade'
                    name='questionsFilters.difficulty_levels'
                    hasBorder
                    isMulti
                    options={[
                      { value: 'very_easy', name: 'Muito Fácil' },
                      { value: 'easy', name: 'Fácil' },
                      { value: 'moderate', name: 'Moderado' },
                      { value: 'difficult', name: 'Difícil' },
                      { value: 'very_difficult', name: 'Muito difícil' }
                    ]}
                  />
                </Grid>

                <Grid item xs={6} md={12}>
                  <LabelStyled>
                    <Field
                      name='questionsFilters.enem_type'
                      component='input'
                      type='radio'
                      value='true'
                    />{' '}
                    <span>Questão estilo ENEM</span>
                  </LabelStyled>
                </Grid>

                <Grid item xs={6} md={12}>
                  <LabelStyled>
                    <Field
                      name='questionsFilters.with_resolution'
                      component='input'
                      type='radio'
                      value='true'
                    />{' '}
                    <span>Questões com resolução</span>
                  </LabelStyled>
                </Grid>
              </Grid>
            </Box>
          </Box>}

        {!isCustomQuestion && questionsFilters && Object.keys(questionsFilters).length > 0 && (
          <>
            <Box sx={{ display: 'flex', mt: match ? 3 : 2 }}>
              <Typography
                sx={{
                  color: '#9F9F9F',
                  mr: 2,
                  fontSize: 14,
                  fontWeight: 700
                }}
                variant='body2'
                component='span'
              >
                Filtros:
              </Typography>
              {checkboxFiltersFields}
            </Box>
          </>
        )}

        {showYourFilters && customQuestionsFilters && Object.keys(customQuestionsFilters).length > 0 && (
          <>
            <Box sx={{ display: 'flex', mt: match ? 3 : 2 }}>
              <Typography
                sx={{
                  color: '#9F9F9F',
                  mr: 2,
                  fontSize: 14,
                  fontWeight: 700
                }}
                variant='body2'
                component='span'
              >
                Filtros:
              </Typography>
              {checkboxQuestionsFilter}
            </Box>
          </>
        )}
      </FiltersContainer>

      <ButtonContainer>
        <Button
          sx={{ mt: 2, width: 227 }}
          variant='outlined'
          onClick={cleanQuestionsFilters}
          disabled={!values?.customQuestionsFilters && !values?.questionsFilters}
        >
          Limpar Filtro
        </Button>

        <Button
          sx={{ mt: 2, width: 227 }}
          onClick={handleOpenConfirmModal}
          disabled={!values?.customQuestionsFilters && !values?.questionsFilters}
        >
          Salvar Filtro
        </Button>
      </ButtonContainer>

      <ModalSaveFilter
        isOpen={openFilterNameModal}
        closeModal={handleCloseConfirmModal}
        action={handleSaveFilters}
        disableAction={invalid}
      />

      <ModalDelete
        isOpen={openDeleteFilterModal}
        closeModal={handleCloseDeleteFilterModal}
        action={() => handleDeleteFilters(filterSelectedToDelete)}
        type='filter'
      />
    </>
  )
}

export default QuestionsFilter
