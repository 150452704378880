import React from 'react'
import DialogModal from './DialogModal'
import { Box, Typography } from '@mui/material'
import Button from '../button/Button'
import ModalPermissionImage from '../../assets/components/Books/modal-permission-image.svg'

interface IModalPermissionsProps {
  open: boolean
  closeModal: () => void
  actionType: 'edit' | 'delete' | 'archive'
}

const ModalPermissions: React.FC<IModalPermissionsProps> = ({ open, closeModal, actionType }) => {
  let message: string
  switch (actionType) {
    case 'edit':
      message = 'Apenas o autor do livro tem autorização para editar.'
      break
    case 'delete':
      message = 'Apenas o autor do livro tem autorização para excluir.'
      break
    case 'archive':
      message = 'Apenas o autor da atividade tem autorização para arquivar.'
      break
    default:
      message = ''
  }

  const permissionsActions = (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '30px', width: '100%' }}>
      <Button
        type='button'
        onClick={closeModal}
      >
        Ok
      </Button>
    </Box>
  )

  return (
    <DialogModal
      open={open}
      handleClose={closeModal}
      actions={permissionsActions}
      width='585px'
      height='100%'
    >
      <Box sx={{ overflow: 'hidden !important' }}>
        <Box
          sx={{
            height: '206px',
            marginBottom: '28px',
            marginInline: 'auto',
            width: '205px'
          }}
        >
          <img
            src={ModalPermissionImage}
            alt='permissão negada'
            height='100%'
            width='100%'
          />
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Typography
            component='h4'
            sx={{
              color: '#666666',
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '120%',
              marginBottom: '16px'
            }}
          >
            Permissão Negada!
          </Typography>
          <Typography
            sx={{
              color: '#9f9f9f',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '150%'
            }}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    </DialogModal>
  )
}

export default ModalPermissions
