import React from 'react'
import LineChart from '../charts/LineChart'
import Select from 'react-select'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import SchoolIcon from '../../assets/components/Books/school-icon.svg'
import BagIcon from '../../assets/components/Books/bag-icon.svg'
import BookIcon from '../../assets/components/Books/book-icon.svg'


const BoxTitle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& img': {
    width: '12px'
  },

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%'
  }
}))

const BoxFilter = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',

}))

const SelectBox = styled(Select)(({ theme }) => ({
  fontSize: 14,
  width: '200px',
  '.select__control': {
    background: 'inherit',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    '&:hover': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    },

    '.select__value-container': {
      padding: '2px 6px',

      '.select__placeholder': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%'
      }
    }
  },

  '& .select__control--is-focused': {
    border: 'none',
    borderColor: 'transparent'
  },

  '.select__menu': {
    width: '200px',
  }
}))

const PerformanceChartView = () => {
  return (
    <>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '120px', marginBottom: '18px' }}>
        <BoxTitle>
          <img src={BookIcon} />
          <Typography className='title'>
            Comparativo de desempenho
          </Typography>
        </BoxTitle>

        <BoxFilter>
          <img src={SchoolIcon} />
          <SelectBox
            className='school-select'
            classNamePrefix='select'
            name='school'
            placeholder='Selecione...'
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Não encontrado'}
            options={[]}
          />
        </BoxFilter>

        <BoxFilter>
          <img src={BagIcon} />
          <SelectBox
            className='classroom-select'
            classNamePrefix='select'
            name='classroom'
            placeholder='Selecione...'
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Não encontrado'}
            options={[]}
          />
        </BoxFilter>
      </Box>
      <LineChart />
    </>
  )
}

export default PerformanceChartView
