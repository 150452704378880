import { IUnit } from './IUnit'

export interface IUser {
  id: number
  name: string
  email: string
  genre: string
  phone: string
  birthdate: string
  document_type: string
  document_number: string
  nationality: string
  birthplace: string
  marital_status: string
  fractal_id: number
  photo_url: string
  additional_data: {
    social_name: string
  },
  avatar_attachment_id: number
  avatar_url: string
  race_ids: number[]
  ethnicity_ids: number[]
  roles: object[]
  units?: Array<IUnit>
}

export const UserValue = {
  id: 0,
  name: '',
  email: '',
  gender: '',
  phone: '',
  birthdate: '',
  document_type: '',
  document_number: '',
  nationality: '',
  birthplace: '',
  marital_status: '',
  fractal_id: 0,
  photo_url: '',
  additional_data: {
    social_name: '',
  },
  avatar_attachment_id: 0,
  avatar_url: '',
  race_ids: [0],
  ethnicity_ids: [0],
  roles: [{}],
}
