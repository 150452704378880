/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material'
import DialogModal from './DialogModal'
import Button from '../button/Button'
import { CancelButton } from '../Books/button/StyledButtons'
import LoadingModalCoauthor from './LoadingModalCoauthor'

// Styles
import {
  styled,
  useTheme
} from '@mui/material/styles'

// Utils
import { Teacher, teachersMockupData } from '../../utils/mockup'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { addCoauthorRequest, getTeachersWithSubjectsRequest } from '../../store/books/actions'
import { Books, Subject } from '../../models/IBooks'

interface IModalCoauthorsProps {
  isOpen: boolean
  closeModal: () => void
  schoolUnitId?: number
  book?: Books
}

const Title = styled('h3')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '120%'
}))

const SubTitle = styled('h5')(({ theme }) => ({
  color: '#9F9F9F',
  fontSize: '14px',
  fontWeight: 600,
}))

const Container = styled('div')(({ theme }) => ({
  width: '100%'
}))

const ModalCoauthors: React.FC<IModalCoauthorsProps> = ({
  isOpen,
  closeModal,
  schoolUnitId,
  book
}) => {

  const dispatch = useAppDispatch()
  const { selectedUnit } = useAppSelector((state) => state.persistable)
  const { teachers, currentItem } = useAppSelector((state) => state.books)
  const [checkedTeachers, setCheckedTeachers] = useState<any[]>([])

  useEffect(() => {
    isOpen && selectedUnit?.id && dispatch(getTeachersWithSubjectsRequest({ schoolUnitId: selectedUnit?.id }))
  }, [selectedUnit?.id, isOpen])

  useEffect(() => {
    if (book?.co_authors) {
      const coauthorIds = book.co_authors.map(coauthor => coauthor.user.id)
      setCheckedTeachers(coauthorIds)
    }
  }, [book?.co_authors])

  const handleCheckTeachers = (teacher: any) => {
    setCheckedTeachers(prevState => {
      if (prevState.includes(teacher.id)) {
        return prevState.filter(id => id !== teacher.id)
      } else {
        return [...prevState, teacher.id]
      }
    })
  }

  const handleAddCoauthor = () => {
    dispatch(addCoauthorRequest({
      bookId: book?.id,
      name: book?.name,
      description: book?.description,
      subject_ids: book?.subjects.map((subject) => String(subject?.id)),
      co_author_user_ids: checkedTeachers,
      school_unit_id: selectedUnit?.id,
      closeModal
    }))
  }

  const actionsModal = (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        marginBlock: '54px',
        paddingInline: '16px'
      }}
    >
      <CancelButton
        onClick={closeModal}
      >
        Cancelar
      </CancelButton>

      <Button
        type='button'
        onClick={handleAddCoauthor}
        startIcon={
          currentItem.isSaving && (
            <CircularProgress
              size={20}
              variant='indeterminate'
              color='inherit'
            />
          )
        }
      >
        Confirmar
      </Button>
    </Box>
  )

  return (
    <Container>
      <DialogModal
        open={isOpen}
        handleClose={closeModal}
        actions={actionsModal}
        width='930px'
        height='90%'
      >
        {
          teachers.isFetching
            ? (
              <LoadingModalCoauthor />
            ) : (<Box width={'100%'}>
              <Box>
                <Title>
                  Adicione coautores para enriquecer e coeditar este livro juntos
                </Title>
                <Grid container>
                  <Grid xs={6} item>
                    <SubTitle>Corpo Docente</SubTitle>
                  </Grid>
                  <Grid xs={6} item>
                    <SubTitle>Disciplina ministrada</SubTitle>
                  </Grid>
                </Grid>
                {
                  teachers?.items?.map((teacher: any) => {
                    const isChecked = checkedTeachers.includes(teacher?.user?.id)
                    return (
                      <Box
                        key={teacher?.user.id}
                      >
                        <Grid container>
                          <Grid xs={6} item>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: '16px'
                              }}
                            >
                              <Checkbox
                                onChange={() => handleCheckTeachers(teacher?.user)}
                                checked={isChecked}
                              />
                              <Typography>{teacher?.user?.name}</Typography>
                            </Box>
                          </Grid>
                          <Grid xs={6} item>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                paddingBottom: '32px'
                              }}
                            >
                              {teacher?.user?.subjects?.length > 3 ? (
                                <Tooltip
                                  title={teacher?.user?.subjects?.map((subject: Subject) => subject.name).join(' / ')}
                                  arrow
                                >
                                  <Box sx={{ display: 'flex' }}>
                                    {teacher?.user?.subjects.slice(0, 3).map((subject: Subject, index: any) => {
                                      return (
                                        <Box
                                          key={subject?.id}
                                        >
                                          {
                                            (subject?.id !== null && subject?.name !== null) &&
                                            <Typography
                                              sx={{ paddingTop: '7px' }}
                                            >
                                              {subject.name ?? 'Não possui'}
                                              {index < 2 && ' / '}&nbsp;
                                            </Typography>}
                                        </Box>
                                      )
                                    })}
                                    <Typography sx={{ paddingTop: '7px' }}>...</Typography>
                                  </Box>
                                </Tooltip>
                              ) : (
                                teacher?.user?.subjects?.map((subject: any, index: any) => (
                                  <Typography
                                    key={subject?.id}
                                    sx={{ paddingTop: '7px' }}
                                  >
                                    {subject?.name ?? 'Não possui'}
                                    {index < teacher?.user?.subjects?.length - 1 && ' / '}&nbsp;
                                  </Typography>
                                ))
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>)

        }
      </DialogModal>
    </Container>
  )
}

export default ModalCoauthors
