import { createAction, createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

import { JsonFormat } from '../../utils/types'
import { IQuestion } from '../../models/IQuestion'
import { withPayloadType } from '../../utils/functions'
import { IRanking } from '../../models/IRanking'

export type QuestionJson = JsonFormat<'questions', IQuestion>

export interface ReportsPayload {
  // data: FormData
  data: object
}

export interface ModuleFetchPayload {
  id: string | number
}

export interface ReportsSuccessPayload {
  data: object
}

export interface ChangePaginationPayload {
  total: number
  totalOfPages: number
  current: number
  per: number
}

export interface FetchModuleSuccessPayload {
  currentItem: IQuestion
}

export const teacherReportItemsFetchRequest = createAction('reports/TEACHER_REPORT_ITEMS_FETCH_REQUEST', withPayloadType<ReportsPayload>())
export const teacherReportItemsFetchSuccess = createAction('reports/TEACHER_REPORT_ITEMS_FETCH_SUCCESS', withPayloadType<ReportsSuccessPayload>())
export const teacherReportItemsFetchError = createAction('reports/TEACHER_REPORT_ITEMS_FETCH_ERROR')

export const financialReportItemsFetchRequest = createAction('reports/FINANCIAL_REPORT_ITEMS_FETCH_REQUEST', withPayloadType<ReportsPayload>())
export const financialReportItemsFetchSuccess = createAction('reports/FINANCIAL_REPORT_ITEMS_FETCH_SUCCESS', withPayloadType<ReportsSuccessPayload>())
export const financialReportItemsFetchError = createAction('reports/FINANCIAL_REPORT_ITEMS_FETCH_ERROR')

export const teacherReportSolvedItemsFetchRequest = createAction('reports/TEACHER_REPORT_SOLVED_ITEMS_FETCH_REQUEST', withPayloadType<ReportsPayload>())
export const teacherReportSolvedItemsFetchSuccess = createAction('reports/TEACHER_REPORT_SOLVED_ITEMS_FETCH_SUCCESS', withPayloadType<ReportsSuccessPayload>())
export const teacherReportSolvedItemsFetchError = createAction('reports/TEACHER_REPORT_SOLVED_ITEMS_FETCH_ERROR')

export const activityRankingReportFetchRequest = createAction('reports/ACTIVITY_RANKING_REPORT_FETCH_REQUEST', withPayloadType<ReportsPayload>())
export const activityRankingReportFetchSuccess = createAction('reports/ACTIVITY_RANKING_REPORT_FETCH_SUCCESS', withPayloadType<ReportsSuccessPayload>())
export const activityRankingReportFetchError = createAction('reports/ACTIVITY_RANKING_REPORT_FETCH_ERROR')
export const activityRankingReset = createAction('reports/RESET_ACTIVITY_RANKING')

export const activityRankingChangeFilter = createAction('reports/ACTIVITY_RANKING_CHANGE_FILTER')
export const activityRankingClearFilter = createAction('reports/ACTIVITY_RANKING_CLEAR_FILTER')

export const rankingChangePagination = createAction('reports/ACTIVITY_RANKING_CHANGE_PAGINATION', withPayloadType<ChangePaginationPayload>())

type Filter = {
  query: object | null
  ui: object
}

interface StateType {
  teacher: object
  curator: object
  activitiesRanking: {
    items: IRanking[],
    isFetching: boolean,
    messageError: string | null,
    filter: Filter
  },
  pagination: {
    per: number,
    total: number,
    results: number,
    current: number,
    totalOfPages: number
  }
}

const initialState: StateType = {
  teacher: {
    teacherReports: null,
    teacherReportsSolvedQuestions: null,
    isFetching: false,
    isFetchingReportsSolvedQuestions: false
  },
  curator: {
    financialReports: null,
    isFetching: false
  },
  activitiesRanking: {
    items: [],
    isFetching: false,
    messageError: null,
    filter: {
      query: null,
      ui: {
        education_grade_ids: []
      }
    }
  },
  pagination: {
    per: 1,
    total: 0,
    results: 10,
    current: 1,
    totalOfPages: 0
  }
}

const reportsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(teacherReportItemsFetchRequest, (state: StateType): StateType => ({
      ...state,
      teacher: {
        ...state.teacher,
        isFetching: true
      }
    }))
    .addCase(teacherReportItemsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      teacher: {
        ...state.teacher,
        teacherReports: action.payload,
        isFetching: false
      }
    }))
    .addCase(teacherReportItemsFetchError, (state: StateType): StateType => ({
      ...state,
      teacher: {
        ...state.teacher,
        isFetching: false
      }
    }))
    .addCase(financialReportItemsFetchRequest, (state: StateType): StateType => ({
      ...state,
      curator: {
        ...state.curator,
        isFetching: true
      }
    }))
    .addCase(financialReportItemsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      curator: {
        ...state.curator,
        financialReports: action.payload,
        isFetching: false
      }
    }))
    .addCase(financialReportItemsFetchError, (state: StateType): StateType => ({
      ...state,
      curator: {
        ...state.curator,
        isFetching: false
      }
    }))
    .addCase(teacherReportSolvedItemsFetchRequest, (state: StateType): StateType => ({
      ...state,
      teacher: {
        ...state.teacher,
        isFetchingReportsSolvedQuestions: true
      }
    }))
    .addCase(teacherReportSolvedItemsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      teacher: {
        ...state.teacher,
        teacherReportsSolvedQuestions: action.payload,
        isFetchingReportsSolvedQuestions: false
      }
    }))
    .addCase(teacherReportSolvedItemsFetchError, (state: StateType): StateType => ({
      ...state,
      teacher: {
        ...state.teacher,
        isFetchingReportsSolvedQuestions: false
      }
    }))
    .addCase(activityRankingReportFetchRequest, (state: StateType): StateType => ({
      ...state,
      activitiesRanking: {
        ...state.activitiesRanking,
        isFetching: true
      }
    }))
    .addCase(activityRankingReportFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      activitiesRanking: {
        ...state.activitiesRanking,
        items: action.payload,
        isFetching: false,
        messageError: null
      }
    }))
    .addCase(activityRankingChangeFilter, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      activitiesRanking: {
        ...state.activitiesRanking,
        isFetching: false,
        messageError: action.payload
      }
    }))
    .addCase(activityRankingReset, (state: StateType): StateType => ({
      ...state,
      activitiesRanking: {
        ...state.activitiesRanking,
        items: initialState.activitiesRanking.items,
        isFetching: initialState.activitiesRanking.isFetching,
        messageError: initialState.activitiesRanking.messageError,
        filter: initialState.activitiesRanking.filter
      }
    }))
    .addCase(activityRankingReportFetchError, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      activitiesRanking: {
        ...state.activitiesRanking,
        filter: {
          ...state.activitiesRanking.filter,
          query: {
            ...state.activitiesRanking.filter.query,
            ...action.payload.query
          },
          ui: {
            ...state.activitiesRanking.filter.ui,
            ...action.payload.ui
          }
        }
      }
    }))
    .addCase(activityRankingClearFilter, (state: StateType): StateType => ({
      ...state,
      activitiesRanking: {
        ...state.activitiesRanking,
        filter: initialState.activitiesRanking.filter
      }
    }))
    .addCase(rankingChangePagination, (state: StateType, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: action.payload.total,
        totalOfPages: action.payload.totalOfPages,
        current: action.payload.current,
        per: action.payload.per
      }
    }))
})

export default reportsReducer
