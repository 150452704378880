import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import { JsonFormat } from '../../utils/types'
import { withPayloadType } from '../../utils/functions'
import { IActivities } from '../../models/IActivities'

export type ActivitiesJson = JsonFormat<'activities', IActivities>
export interface CreateActivitiesPayload {
  data: object
  afterEffect(res:AxiosResponse): void
  handleErrorEffect(): void
}

export interface FetchActivitiesItemsPayload {
  page?: number
  start_from?: string | null
  start_until?: string | null
  title?: string | null
  statuses?: string | null
  order?: string
}
export interface FetchActivitiesItemsSuccessPayload {
  items: object[]
  pagination: object
}

export interface SaveActivityPayload {
  activity?: IActivities,
  navigate?: any
}

export interface UpdateActivityPayload extends SaveActivityPayload {
  id?: number | string
}

export interface FetchActivityResponse {
  item:object
  participantStatus:object
}

export interface  activitiesOverviewItemsPayload {
  data: object
}

export interface activitiesOverviewSuccessPayload {
  items: object
}

export interface  activityParticipantsDetailsSuccess {
  details: object
}

export interface ReopenActivityPayload {
  id?: number
}

export interface FetchActivityReportPayload {
  activityId?: string | number
}
export interface FetchActivityReportSuccessPayload {
  activityReport?: object
}

interface IChangePaginationPayload {
  current: number
}

export const activityReportFetchRequest = createAction('activities/ACTIVITY_REPORT_FETCH_REQUEST', withPayloadType<FetchActivityReportPayload>())
export const activityReportFetchSuccess = createAction('activities/ACTIVITY_REPORT_FETCH_SUCCESS', withPayloadType<FetchActivityReportSuccessPayload>())
export const activityReportFetchFailure = createAction('activities/ACTIVITY_REPORT_FETCH_ERROR')

export const activitiesItemsFetchRequest = createAction('activities/ACTIVITIES_ITEMS_FETCH_REQUEST', withPayloadType<FetchActivitiesItemsPayload>())
export const activitiesItemsFetchSuccess = createAction('activities/ACTIVITIES_ITEMS_FETCH_SUCCESS', withPayloadType<FetchActivitiesItemsSuccessPayload>())
export const activitiesItemsFetchFailure = createAction('activities/ACTIVITIES_ITEMS_FETCH_ERROR')

export const activitySaveRequest = createAction('activities/ACTIVITY_SAVE_REQUEST', withPayloadType<SaveActivityPayload>())
export const activitySaveSuccess = createAction('activities/ACTIVITY_SAVE_SUCCESS')
export const activitySaveFailure = createAction('activities/ACTIVITY_SAVE_ERROR')

export const activityUpdateRequest = createAction('activities/ACTIVITY_UPDATE_REQUEST', withPayloadType<UpdateActivityPayload>())
export const activityUpdateSuccess = createAction('activities/ACTIVITY/UPDATE_SUCCESS')
export const activityUpdateFailure = createAction('activities/ACTIVITY_UPDATE_ERROR', withPayloadType<string>())

export const activityItemByIdFetchRequest = createAction('activities/ACTIVITY_ITEM_BY_ID_FETCH_REQUEST', withPayloadType<any>())
export const activityItemByIdFetchSuccess = createAction('activities/ACTIVITY_ITEM_BY_ID_FETCH_SUCCESS', withPayloadType<FetchActivityResponse>())
export const activityItemByIdFetchFailure = createAction('activities/ACTIVITY_ITEM_BY_ID_FETCH_ERROR')

export const archiveActivityRequest = createAction('activities:ARCHIVE_ACTIVITY_REQUEST', withPayloadType<number | string | undefined>())
export const archiveActivitySuccess = createAction('activities:ARCHIVE_ACTIVITY_SUCCESS')
export const archiveActivityFailure = createAction('activities:ARCHIVE_ACTIVITY_FAILURE', withPayloadType<string>())

export const clearActivityItem = createAction('activities/CLEAR_ACTIVITY_ITEM')
export const activitiesResetFilters = createAction('actitivites/ACTIVITIES_RESET_FILTERS')

export const activitiesOverviewFetchRequest = createAction('activities:ACTIVITIES_OVERVIEW_FETCH_REQUEST',withPayloadType< activitiesOverviewItemsPayload>())
export const activitiesOverviewFetchSuccess = createAction('activities:ACTIVITIES_OVERVIEW_FETCH_SUCCESS',withPayloadType<activitiesOverviewSuccessPayload>())
export const activitiesOverviewFetchFailure = createAction('activities:ACTIVITIES_OVERVIEW_FETCH_FAILURE')

export const activityParticipantsDetailsRequest = createAction('activities/ACTIVITIES_PARTICIPANTS_DETAILS_REQUEST',withPayloadType<number | undefined>())
export const activityParticipantsDetailsSuccess = createAction('activities/ACTIVITIES_PARTICIPANTS_DETAILS_SUCCESS',withPayloadType<activityParticipantsDetailsSuccess>())
export const activityParticipantsDetailsFailure = createAction('activities/ACTIVITIES_PARTICIPANTS_DETAILS_FAILURE', withPayloadType<string>())

export const activityReopenRequest = createAction('activities/ACTIVITY_REOPEN_REQUEST', withPayloadType<ReopenActivityPayload>())
export const activityReopenSuccess = createAction('activities/ACTIVITY_REOPEN_SUCCESS')
export const activityReopenFailure = createAction('activities/ACTIVITY_REOPEN_ERROR', withPayloadType<string>())

export const activityDownloadFileRequest = createAction('activities/ACTIVITY_DOWNLOAD_FILE_REQUEST', withPayloadType<object>())
export const activityDownloadFileRequestSuccess = createAction('activities/ACTIVITY_DOWNLOAD_FILE_SUCCESS')
export const activityDownloadFileRequestFailure = createAction('activities/ACTIVITY_DOWNLOAD_FILE_FAILURE')

export const changePagination = createAction('activities/CHANGE_PAGINATION', withPayloadType<IChangePaginationPayload>())
